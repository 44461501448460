import {
  ArrowBackIos,
  ArrowForwardIos,
  CheckCircle,
} from "@mui/icons-material"
import { Box } from "@mui/material"
import React, {
  JSX,
  useMemo,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import "./style.sass"
import { AttachmentFragment } from "../../graphql"

const VISIBLE_CAROUSEL_COUNT = 5

function renderLeftCarouselArrow(
  activePage: number,
  setActivePage: (idx: number) => void,
  ariaLabel: string,
): JSX.Element | null {
  // If active page is not 0
  if (activePage === 0) return null
  return (
    <button
      className="carousel-arrow left-carousel-arrow"
      onClick={ () => setActivePage(activePage - 1) }
      type="button"
      aria-label={ ariaLabel }
    >
      <ArrowBackIos />
    </button>
  )
}

function renderRightCarouselArrow(
  mediaLength: number,
  activePage: number,
  setActivePage: (idx: number) => void,
  windowEnd: number,
  ariaLabel: string,
): JSX.Element | null {
  if (mediaLength - 1 < windowEnd) return null
  return (
    <button
      className="carousel-arrow right-carousel-arrow"
      onClick={ () => {
        setActivePage(activePage + 1)
      } }
      type="button"
      aria-label={ ariaLabel }
    >
      <ArrowForwardIos />
    </button>
  )
}

type Props = {
  selectedMedia: AttachmentFragment[]
  addSelectedMedia: (media: AttachmentFragment) => void
  removeSelectedMedia: (media: AttachmentFragment) => void
  media: Array<AttachmentFragment>
}

export default function SelectionCarousel({
  addSelectedMedia, removeSelectedMedia, selectedMedia, media,
}: Props): React.JSX.Element {
  const [ activePage, setActivePage ] = useState<number>(0)
  const { t: translate } = useTranslation([], { keyPrefix: "component.SelctionCarousel" })

  const [ windowStart, windowEnd ] = useMemo(() => {
    const startingIndex = activePage * VISIBLE_CAROUSEL_COUNT
    const startPreviewOffset = startingIndex === 0 ? 0 : -1
    const endPreivewOffset = 1
    return [
      startingIndex + startPreviewOffset,
      startingIndex + VISIBLE_CAROUSEL_COUNT + endPreivewOffset,
    ]
  }, [ activePage ])

  let containerClassName = "cp_component_selection-carousel"
  if (activePage === 0) containerClassName += " no-start-preivew"

  const handleMediaClick = (element: AttachmentFragment) => {
    if (selectedMedia.find((m) => m.id === element.id)) removeSelectedMedia(element)
    else addSelectedMedia(element)
  }

  return (
    <section
      id="cp_component_selection-carousel"
      className={ containerClassName }
    >
      { renderLeftCarouselArrow(activePage, setActivePage, translate("Left carousel arrow")) }
      { media.slice(windowStart, windowEnd).map((attachment, idx) => {
        const mediaSelected = selectedMedia.find((m) => attachment.id === m.id) !== undefined
        let className = "carousel-option"
        if (mediaSelected) className += " option-selected"
        let isPreview = false
        if (activePage === 0) {
          // If the page is the first page,
          // - don't render the first option as a preview
          // - render the last option as a preview if it exists
          if (idx === 5) {
            className += " end-preview"
            isPreview = true
          }
        } else {
          // If the page is not the first page
          // - render the first option as a preview
          // - render the last option as a preview if it exists
          if (idx === 0) {
            className += " start-preview"
            isPreview = true
          }
          if (idx === 6) {
            className += " end-preview"
            isPreview = true
          }
        }
        return (
          <Box
            className={ className }
            onClick={ () => {
              if (!isPreview) handleMediaClick(attachment)
            } }
          >
            <img src={ attachment.thumbnail?.url } alt={ attachment.name } />
            { mediaSelected && (
            <div className="option-selected-icon">
              <CheckCircle />
            </div>
            ) }
          </Box>
        )
      }) }
      { renderRightCarouselArrow(media.length, activePage, setActivePage, windowEnd, translate("Right carousel arrow")) }
    </section>
  )
}
