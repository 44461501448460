import * as React from "react"
import { useTranslation } from "react-i18next"

import "./list-management-toolbar.sass"

import { useDispatch } from "../../state/hooks"
import Button from "../Button"
import { toggleCreateCategoryModal } from "../../state/listSlice"
import { toggleShowAcountSearch } from "../../state/listAddAccount"
import ModalCreateCategory from "../ModalCreateCategory"

export default function ListManagementToolbar({ displayConfigureTab }: {displayConfigureTab: boolean}) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListManagementToolbar" })
  const dispatch = useDispatch()

  return (
    <div className="cp_list-management-toolbar_component">
      <div className="cp_list-management-toolbar_component-header">
        <p className="cp_list-management-toolbar_component-header-title">
          { displayConfigureTab ? translate("Account Management") : translate("List Management") }
        </p>
        <div className="cp_list-management-toolbar_component-header-controls">
          <Button
            label={ translate("ADD ACCOUNT") }
            isEnabled={ true }
            isPrimary={ true }
            onClick={ () => dispatch(toggleShowAcountSearch()) }
          />
          <Button
            label={ translate("ADD CATEGORY") }
            isEnabled={ true }
            isPrimary={ true }
            onClick={ () => dispatch(toggleCreateCategoryModal()) }
          />
        </div>
      </div>
      <ModalCreateCategory />
    </div>
  )
}
