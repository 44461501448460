import { SetURLSearchParams } from "react-router-dom"
import { GridSortDirection, GridSortModel } from "@mui/x-data-grid-pro"

import * as GraphQL from "../graphql"

// TYPES
export type SearchInput = {
  audienceParams: GraphQL.GodSearchAudienceInput,
  contentParams: GraphQL.GodSearchContentInput,
  influencerParams: GraphQL.GodSearchInfluencerInput,
  limit: number,
  page: number,
  resultType: GraphQL.GodSearchType,
  searchId: string | null,
  socialAccountIds: string[],
  socialNetworks: GraphQL.Network[],
  sortBy: string,
  sortDirection: GraphQL.SortDirection
  startsWith: string | null,
  target: GraphQL.GodSearchTargetType | null,
}

export type AgeRange
  = "13-19"
  | "20-29"
  | "30-39"
  | "40-49"
  | "50+"

export type AgeRangeValue = {
  minAge: number | null,
  maxAge: number | null,
}

export type LocationWithMatch = {
  location: GraphQL.AudienceLocation,
  match: number,
}

// NOTE: This type will dictate where the search feature will be mounted, and
// which state to leverage as a result. In time, it would be nice to unmount
// search from places other than the search page so that there is only a single
// flow within the entire app for actions like adding accounts to lists or
// campaigns. Until then, this will help sort the state flow.
export type MountContext = "search" | "list" | "campaign"

// CONSTANTS
export const LIMIT_DEFAULT = 50

export const NETWORKS_AVAILABLE = [
  GraphQL.Network.Facebook,
  GraphQL.Network.Instagram,
  GraphQL.Network.Snapchat,
  GraphQL.Network.Tiktok,
  GraphQL.Network.Youtube,
]

export const AGE_RANGES: AgeRange[] = [
  "13-19",
  "20-29",
  "30-39",
  "40-49",
  "50+",
]

export const INCOME_BRACKETS: GraphQL.IncomeBrackets[] = [
  GraphQL.IncomeBrackets.Under_10000,
  GraphQL.IncomeBrackets["10000_19999"],
  GraphQL.IncomeBrackets["20000_29999"],
  GraphQL.IncomeBrackets["30000_39999"],
  GraphQL.IncomeBrackets["40000_49999"],
  GraphQL.IncomeBrackets["50000_74999"],
  GraphQL.IncomeBrackets["75000_100000"],
  GraphQL.IncomeBrackets["100000Above"],
]

// eslint-disable-next-line no-shadow
export enum SearchFieldString {
  // These are strings that the backend uses to recognize search fields.
  // For instance, these strings are used as the `sortBy` value in the
  // search input.

  // NOTE: Being able to sort by Engagement Rate is currently not supported
  // on the backend. Once that becomes a possibility, Engagement Rate may be
  // re-added to the switch case below.

  AdCouncilScore = "ad-council-score", // Ad Council and IScore are the same value
  AudienceQualityScore = "aud-quality-score",
  DemographicScore = "demographic-score",
  EngagementScore = "engagement-rate-score",
  Followers = "followers",
  IScore = "i-score",
  InDemoPercentage = "in-demo",
  Matches = "matches",
  EngagementRate = "engagement-rate",
  OrganicEngagementRate = "organic-engagement-rate",
  PostDate = "date-posted",
  SponsoredEngagementRate = "sponsored-engagement-rate",
}

// eslint-disable-next-line no-shadow
export enum SearchColumn {
  Account = "account",
  Score = "score",
  AdCouncilScore = "adCouncilScore",
  AudienceQualityScore = "audienceQualityScore",
  Biography = "biography",
  DemographicScore = "demographicScore",
  EllipsisMenu = "ellipsisMenu",
  EngagementRate = "engagementRate",
  EngagementScore = "engagementScore",
  Followers = "followers",
  IScore = "iScore",
  InDemoPercentage = "inDemoPercentage",
  Matches = "matches",
  Media = "media",
  OrganicEngagementRate = "organicEngagementRate",
  PostDate = "postDate",
  PostDetails = "postDetails",
  PostType = "postType",
  SponsoredEngagementRate = "sponsoredEngagementRate",
}

// TYPE HELPERS
export function isGodSearchTargetType(s: string): s is GraphQL.GodSearchTargetType {
  switch (s) {
    case GraphQL.GodSearchTargetType.Customer:
      return true

    case GraphQL.GodSearchTargetType.CustomerMinusOwn:
      return true

    case GraphQL.GodSearchTargetType.Influential:
      return true

    case GraphQL.GodSearchTargetType.InfluentialMinusOwn:
      return true

    case GraphQL.GodSearchTargetType.Own:
      return true

    default:
      return false
  }
}

export function mapStringToAgeRangeValues(displayStr: string): AgeRangeValue {
  switch (displayStr) {
    case "13-19":
      return { minAge: 13, maxAge: 19 }

    case "20-29":
      return { minAge: 20, maxAge: 29 }

    case "30-39":
      return { minAge: 30, maxAge: 39 }

    case "40-49":
      return { minAge: 40, maxAge: 49 }

    case "50+":
      return { minAge: 50, maxAge: null }

    default:
      return { minAge: null, maxAge: null }
  }
}

export function mapAgeRangeValuesToString(
  minAge: number | null | undefined,
  maxAge: number | null | undefined,
): AgeRange | null {
  if (minAge === 13 && maxAge === 19) return "13-19"
  if (minAge === 20 && maxAge === 29) return "20-29"
  if (minAge === 30 && maxAge === 39) return "30-39"
  if (minAge === 40 && maxAge === 49) return "40-49"
  if (minAge === 50 && maxAge == null) return "50+"
  return null
}

// INITIAL STATES
export function initialGradeMatch(): GraphQL.AudienceGradeMatch {
  return {
    age: null,
    ethnicityAfricanAmerican: null,
    ethnicityAsianPacificIslander: null,
    ethnicityHispanicLatino: null,
    ethnicityWhiteCaucasian: null,
    familyMarried: null,
    familyParents: null,
    familySingle: null,
    genderFemale: null,
    genderMale: null,
    location: [],
  }
}

export function initialMinimumMatch(): GraphQL.AudienceMinimumMatch {
  return {
    age: null,
    ethnicityAfricanAmerican: null,
    ethnicityAsianPacificIslander: null,
    ethnicityHispanicLatino: null,
    ethnicityWhiteCaucasian: null,
    familyMarried: null,
    familyParents: null,
    familySingle: null,
    genderFemale: null,
    genderMale: null,
    location: [],
  }
}

export function initialAudienceInput(): GraphQL.GodSearchAudienceInput {
  return {
    affinities: [],
    ethnicities: [],
    family: [],
    genders: [],
    gradeMatch: initialGradeMatch(),
    income: [],
    locations: [],
    maxAge: null,
    minAge: null,
    minimumMatch: initialMinimumMatch(),
    occupations: [],
    religion: [],
  }
}

export function initialContentInput(): GraphQL.GodSearchContentInput {
  return {
    brandLogoTags: [],
    containsMedia: true,
    contextualRelevancy: null,
    excludeKeywords: [],
    excludeNsfwContent: true,
    excludedBrandLogoTags: [],
    excludedImageTags: [],
    geoTags: [],
    imageTags: [],
    keywords: [],
    minPostEngagement: null,
    optionalImageTags: [],
    postTypes: [],
    postedDates: [],
    postedRanges: [],
    publishLocations: [],
    sponsored: null,
  }
}

export function initialInfluencerInput(): GraphQL.GodSearchInfluencerInput {
  return {
    activeAccountsOnly: null,
    bioKeywords: [],
    brand: null,
    contactProvided: null,
    ethnicities: [],
    excludeFlaggedAccounts: null,
    genders: [],
    hideBlacklistedAccounts: null,
    languages: [],
    locations: [],
    maxAge: null,
    maxFollowers: null,
    maxPrice: null,
    minAge: null,
    minEngagement: null,
    minFollowers: "10000",
    minPrice: null,
    oauthAccountsOnly: null,
    personalityTraits: [],
    usedInCampaign: null,
    verticals: [],
    vipAccountsOnly: null,
  }
}

export function initialSearchState(): SearchInput {
  return {
    audienceParams: initialAudienceInput(),
    contentParams: initialContentInput(),
    influencerParams: initialInfluencerInput(),
    limit: LIMIT_DEFAULT,
    page: 1,
    resultType: GraphQL.GodSearchType.Social,
    searchId: null,
    socialAccountIds: [],
    socialNetworks: [
      GraphQL.Network.Facebook,
      GraphQL.Network.Instagram,
      GraphQL.Network.Snapchat,
      GraphQL.Network.Tiktok,
      GraphQL.Network.Youtube,
    ],
    sortBy: SearchFieldString.EngagementScore,
    sortDirection: GraphQL.SortDirection.Desc,
    startsWith: null,
    target: GraphQL.GodSearchTargetType.Influential,
  }
}

// QUERY PARAMETER HELPERS
export const QUERY_PARAM_Q = "q"
export const QUERY_PARAM_INFLUENCER = "influencer"
export const QUERY_PARAM_AUDIENCE = "audience"
export const QUERY_PARAM_CONTENT = "content"
export const QUERY_PARAM_LOCATION = "location"

export function baseQueryToSearchInput(q: string): Partial<SearchInput> {
  const queryValues = JSON.parse(q)
  const validQueryValues: Partial<SearchInput> = {}
  const excludedSearchInputFields = [
    "audienceParams",
    "contentParams",
    "influencerParams",
  ]

  const searchInputFields = Object
    .keys(initialSearchState())
    .filter((k) => !excludedSearchInputFields.includes(k))

  Object
    .keys(queryValues)
    .filter((k) => searchInputFields.includes(k))
    .forEach((k) => {
      validQueryValues[k as keyof SearchInput] = queryValues[k]
    })

  return validQueryValues
}

export function baseQueryToInfluencerInput(
  influencer: string,
): Partial<GraphQL.GodSearchInfluencerInput> {
  const influencerValues = JSON.parse(influencer)
  const validInfluencerValues: Partial<GraphQL.GodSearchInfluencerInput> = {}
  const influencerInputFields = Object.keys(initialInfluencerInput())

  Object
    .keys(influencerValues)
    .filter((k) => influencerInputFields.includes(k))
    .forEach((k) => {
      validInfluencerValues[
        k as keyof GraphQL.GodSearchInfluencerInput
      ] = influencerValues[k]
    })

  return validInfluencerValues
}

export function baseQueryToAudienceInput(
  audience: string,
): Partial<GraphQL.GodSearchAudienceInput> {
  const audienceValues = JSON.parse(audience)
  const validAudienceValues: Partial<GraphQL.GodSearchAudienceInput> = {}
  const audienceInputFields = Object.keys(initialAudienceInput())

  Object
    .keys(audienceValues)
    .filter((k) => audienceInputFields.includes(k))
    .forEach((k) => {
      validAudienceValues[
        k as keyof GraphQL.GodSearchAudienceInput
      ] = audienceValues[k]
    })

  return validAudienceValues
}

export function baseQueryToContentInput(
  content: string,
): Partial<GraphQL.GodSearchContentInput> {
  const contentValues = JSON.parse(content)
  const validContentValues: Partial<GraphQL.GodSearchContentInput> = {}
  const contentInputFields = Object.keys(initialContentInput())

  Object
    .keys(contentValues)
    .filter((k) => contentInputFields.includes(k))
    .forEach((k) => {
      validContentValues[
        k as keyof GraphQL.GodSearchContentInput
      ] = contentValues[k]
    })

  return validContentValues
}

export function setSearchInputQueryParams(
  input: SearchInput,
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams,
): void {
  const init = initialSearchState()
  const q: Partial<SearchInput> = {}

  // ONLY include values as query string parameters if they vary from the
  // initial search input state.
  if (input.resultType !== init.resultType) {
    q.resultType = input.resultType
  }

  if (!init.socialNetworks.every((s) => input.socialNetworks.includes(s))) {
    q.socialNetworks = input.socialNetworks
  }

  if (input.sortBy !== init.sortBy) {
    q.sortBy = input.sortBy
  }

  if (input.sortDirection !== init.sortDirection) {
    q.sortDirection = input.sortDirection
  }

  if (input.startsWith !== null && typeof input.startsWith === "string") {
    q.startsWith = input.startsWith
  }

  if (input.target !== init.target) {
    q.target = input.target
  }

  // Set `q` query param in URL
  if (JSON.stringify(q) === "{}") {
    searchParams.delete(QUERY_PARAM_Q)
    setSearchParams(searchParams)
    return
  }

  searchParams.set(QUERY_PARAM_Q, JSON.stringify(q))
  setSearchParams(searchParams)
}

// Extracted logic from setSearchInputQueryParams
export function generateSearchInputQueryParams(
  input: SearchInput,
) {
  const init = initialSearchState()
  const q: Partial<SearchInput> = {}

  // ONLY include values as query string parameters if they vary from the
  // initial search input state.
  if (input.resultType !== init.resultType) {
    q.resultType = input.resultType
  }

  if (!init.socialNetworks.every((s) => input.socialNetworks.includes(s))) {
    q.socialNetworks = input.socialNetworks
  }

  if (input.sortBy !== init.sortBy) {
    q.sortBy = input.sortBy
  }

  if (input.sortDirection !== init.sortDirection) {
    q.sortDirection = input.sortDirection
  }

  if (input.startsWith !== null && typeof input.startsWith === "string") {
    q.startsWith = input.startsWith
  }

  if (input.target !== init.target) {
    q.target = input.target
  }

  // Set `q` query param in URL
  if (JSON.stringify(q) === "{}") {
    return ""
  }

  return JSON.stringify(q)
}

export function setInfluencerInputQueryParams(
  input: GraphQL.GodSearchInfluencerInput,
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams,
): void {
  const init = initialInfluencerInput()
  const influencer: Partial<GraphQL.GodSearchInfluencerInput> = {}

  if (input.activeAccountsOnly !== init.activeAccountsOnly) {
    influencer.activeAccountsOnly = input.activeAccountsOnly
  }

  if (Array.isArray(input.bioKeywords) && input.bioKeywords.length > 0) {
    influencer.bioKeywords = input.bioKeywords
  }

  if (input.brand !== init.brand) {
    influencer.brand = input.brand
  }

  if (input.contactProvided !== init.contactProvided) {
    influencer.contactProvided = input.contactProvided
  }

  if (Array.isArray(input.ethnicities) && input.ethnicities.length > 0) {
    influencer.ethnicities = input.ethnicities
  }

  if (input.excludeFlaggedAccounts !== init.excludeFlaggedAccounts) {
    influencer.excludeFlaggedAccounts = input.excludeFlaggedAccounts
  }

  if (Array.isArray(input.genders) && input.genders.length > 0) {
    influencer.genders = input.genders
  }

  if (input.hideBlacklistedAccounts !== init.hideBlacklistedAccounts) {
    influencer.hideBlacklistedAccounts = input.hideBlacklistedAccounts
  }

  if (Array.isArray(input.languages) && input.languages.length > 0) {
    influencer.languages = input.languages
  }

  if (Array.isArray(input.locations) && input.locations.length > 0) {
    influencer.locations = input.locations
  }

  if (input.maxAge !== init.maxAge) {
    influencer.maxAge = input.maxAge
  }

  if (input.maxFollowers !== init.maxFollowers) {
    influencer.maxFollowers = input.maxFollowers
  }

  if (input.maxPrice !== init.maxPrice) {
    influencer.maxPrice = input.maxPrice
  }

  if (input.minAge !== init.minAge) {
    influencer.minAge = input.minAge
  }

  if (input.minEngagement !== init.minEngagement) {
    influencer.minEngagement = input.minEngagement
  }

  if (input.minFollowers !== init.minFollowers) {
    influencer.minFollowers = input.minFollowers
  }

  if (input.minPrice !== init.minPrice) {
    influencer.minPrice = input.minPrice
  }

  if (input.oauthAccountsOnly !== init.oauthAccountsOnly) {
    influencer.oauthAccountsOnly = input.oauthAccountsOnly
  }

  if (Array.isArray(input.personalityTraits) && input.personalityTraits.length > 0) {
    influencer.personalityTraits = input.personalityTraits
  }

  if (input.usedInCampaign !== init.usedInCampaign) {
    influencer.usedInCampaign = input.usedInCampaign
  }

  if (Array.isArray(input.verticals) && input.verticals.length > 0) {
    influencer.verticals = input.verticals
  }

  if (input.vipAccountsOnly !== init.vipAccountsOnly) {
    influencer.vipAccountsOnly = input.vipAccountsOnly
  }

  // Set `influencer` query param in URL
  if (JSON.stringify(influencer) === "{}") {
    searchParams.delete(QUERY_PARAM_INFLUENCER)
    setSearchParams(searchParams)
    return
  }

  searchParams.set(QUERY_PARAM_INFLUENCER, JSON.stringify(influencer))
  setSearchParams(searchParams)
}

function getGradeMatchQueryParams(
  input: GraphQL.AudienceGradeMatch | null,
  init: GraphQL.AudienceGradeMatch | null,
): Partial<GraphQL.AudienceGradeMatch> {
  if (input == null || init == null) return {}

  const gradeMatch: Partial<GraphQL.AudienceGradeMatch> = {}

  if (input.age !== init.age) {
    gradeMatch.age = input.age
  }

  if (input.ethnicityAfricanAmerican !== init.ethnicityAfricanAmerican) {
    gradeMatch.ethnicityAfricanAmerican = input.ethnicityAfricanAmerican
  }

  if (input.ethnicityAsianPacificIslander !== init.ethnicityAsianPacificIslander) {
    gradeMatch.ethnicityAsianPacificIslander = input.ethnicityAsianPacificIslander
  }

  if (input.ethnicityHispanicLatino !== init.ethnicityHispanicLatino) {
    gradeMatch.ethnicityHispanicLatino = input.ethnicityHispanicLatino
  }

  if (input.ethnicityWhiteCaucasian !== init.ethnicityWhiteCaucasian) {
    gradeMatch.ethnicityWhiteCaucasian = input.ethnicityWhiteCaucasian
  }

  if (input.familyMarried !== init.familyMarried) {
    gradeMatch.familyMarried = input.familyMarried
  }

  if (input.familyParents !== init.familyParents) {
    gradeMatch.familyParents = input.familyParents
  }

  if (input.familySingle !== init.familySingle) {
    gradeMatch.familySingle = input.familySingle
  }

  if (input.genderFemale !== init.genderFemale) {
    gradeMatch.genderFemale = input.genderFemale
  }

  if (input.genderMale !== init.genderMale) {
    gradeMatch.genderMale = input.genderMale
  }

  if (Array.isArray(input.location) && input.location.length > 0) {
    gradeMatch.location = input.location
  }

  return gradeMatch
}

function getMinimumMatchQueryParams(
  input: GraphQL.AudienceMinimumMatch | null,
  init: GraphQL.AudienceMinimumMatch | null,
): Partial<GraphQL.AudienceMinimumMatch> {
  if (input == null || init == null) return {}

  const minimumMatch: Partial<GraphQL.AudienceMinimumMatch> = {}

  if (input.age !== init.age) {
    minimumMatch.age = input.age
  }

  if (input.ethnicityAfricanAmerican !== init.ethnicityAfricanAmerican) {
    minimumMatch.ethnicityAfricanAmerican = input.ethnicityAfricanAmerican
  }

  if (input.ethnicityAsianPacificIslander !== init.ethnicityAsianPacificIslander) {
    minimumMatch.ethnicityAsianPacificIslander = input.ethnicityAsianPacificIslander
  }

  if (input.ethnicityHispanicLatino !== init.ethnicityHispanicLatino) {
    minimumMatch.ethnicityHispanicLatino = input.ethnicityHispanicLatino
  }

  if (input.ethnicityWhiteCaucasian !== init.ethnicityWhiteCaucasian) {
    minimumMatch.ethnicityWhiteCaucasian = input.ethnicityWhiteCaucasian
  }

  if (input.familyMarried !== init.familyMarried) {
    minimumMatch.familyMarried = input.familyMarried
  }

  if (input.familyParents !== init.familyParents) {
    minimumMatch.familyParents = input.familyParents
  }

  if (input.familySingle !== init.familySingle) {
    minimumMatch.familySingle = input.familySingle
  }

  if (input.genderFemale !== init.genderFemale) {
    minimumMatch.genderFemale = input.genderFemale
  }

  if (input.genderMale !== init.genderMale) {
    minimumMatch.genderMale = input.genderMale
  }

  if (Array.isArray(input.location) && input.location.length > 0) {
    minimumMatch.location = input.location
  }

  return minimumMatch
}

interface PartialAudienceInput extends Partial<
  Omit<GraphQL.GodSearchAudienceInput, "gradeMatch" | "minimumMatch">
> {
  gradeMatch?: Partial<GraphQL.AudienceGradeMatch>,
  minimumMatch?: Partial<GraphQL.AudienceMinimumMatch>,
}

export function setAudienceInputQueryParams(
  input: GraphQL.GodSearchAudienceInput,
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams,
): void {
  const init = initialAudienceInput()
  const audience: PartialAudienceInput = {}

  if (Array.isArray(input.affinities) && input.affinities.length > 0) {
    audience.affinities = input.affinities
  }

  if (Array.isArray(input.ethnicities) && input.ethnicities.length > 0) {
    audience.ethnicities = input.ethnicities
  }

  if (Array.isArray(input.family) && input.family.length > 0) {
    audience.family = input.family
  }

  if (Array.isArray(input.genders) && input.genders.length > 0) {
    audience.genders = input.genders
  }

  const gradeMatchQueryParams = getGradeMatchQueryParams(
    input.gradeMatch || null,
    init.gradeMatch || null,
  )

  if (JSON.stringify(gradeMatchQueryParams) !== "{}") {
    audience.gradeMatch = gradeMatchQueryParams
  }

  if (Array.isArray(input.income) && input.income.length > 0) {
    audience.income = input.income
  }

  if (Array.isArray(input.locations) && input.locations.length > 0) {
    audience.locations = input.locations
  }

  if (input.maxAge !== init.maxAge) {
    audience.maxAge = input.maxAge
  }

  if (input.minAge !== init.minAge) {
    audience.minAge = input.minAge
  }

  const minimumMatchQueryParams = getMinimumMatchQueryParams(
    input.minimumMatch || null,
    init.minimumMatch || null,
  )

  if (JSON.stringify(minimumMatchQueryParams) !== "{}") {
    audience.minimumMatch = minimumMatchQueryParams
  }

  if (Array.isArray(input.occupations) && input.occupations.length > 0) {
    audience.occupations = input.occupations
  }

  if (Array.isArray(input.religion) && input.religion.length > 0) {
    audience.religion = input.religion
  }

  // Set `audience` query param in URL
  if (JSON.stringify(audience) === "{}") {
    searchParams.delete(QUERY_PARAM_AUDIENCE)
    setSearchParams(searchParams)
    return
  }

  searchParams.set(QUERY_PARAM_AUDIENCE, JSON.stringify(audience))
  setSearchParams(searchParams)
}

export function setLocationQueryParams(
  locations: LocationWithMatch[],
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams,
): void {
  if (locations.length === 0) {
    searchParams.delete(QUERY_PARAM_LOCATION)
    setSearchParams(searchParams)
    return
  }

  searchParams.set(QUERY_PARAM_LOCATION, JSON.stringify(locations))
  setSearchParams(searchParams)
}

// Extracted logic from setContentInputQueryParams
export function generateContentInputQueryParams(
  input: GraphQL.GodSearchContentInput,
) {
  const init = initialContentInput()
  const content: Partial<GraphQL.GodSearchContentInput> = {}

  if (Array.isArray(input.brandLogoTags) && input.brandLogoTags.length > 0) {
    content.brandLogoTags = input.brandLogoTags
  }

  if (input.containsMedia !== init.containsMedia) {
    content.containsMedia = input.containsMedia
  }

  if (input.contextualRelevancy !== init.contextualRelevancy) {
    content.contextualRelevancy = input.contextualRelevancy
  }

  if (Array.isArray(input.excludeKeywords) && input.excludeKeywords.length > 0) {
    content.excludeKeywords = input.excludeKeywords
  }

  if (input.excludeNsfwContent !== init.excludeNsfwContent) {
    content.excludeNsfwContent = input.excludeNsfwContent
  }

  if (Array.isArray(input.excludedBrandLogoTags) && input.excludedBrandLogoTags.length > 0) {
    content.excludedBrandLogoTags = input.excludedBrandLogoTags
  }

  if (Array.isArray(input.excludedImageTags) && input.excludedImageTags.length > 0) {
    content.excludedImageTags = input.excludedImageTags
  }

  if (Array.isArray(input.geoTags) && input.geoTags.length > 0) {
    content.geoTags = input.geoTags
  }

  if (Array.isArray(input.imageTags) && input.imageTags.length > 0) {
    content.imageTags = input.imageTags
  }

  if (Array.isArray(input.keywords) && input.keywords.length > 0) {
    content.keywords = input.keywords
  }

  if (input.minPostEngagement !== init.minPostEngagement) {
    content.minPostEngagement = input.minPostEngagement
  }

  if (Array.isArray(input.optionalImageTags) && input.optionalImageTags.length > 0) {
    content.optionalImageTags = input.optionalImageTags
  }

  if (Array.isArray(input.postTypes) && input.postTypes.length > 0) {
    content.postTypes = input.postTypes
  }

  if (Array.isArray(input.postedDates) && input.postedDates.length > 0) {
    content.postedDates = input.postedDates
  }

  if (Array.isArray(input.postedRanges) && input.postedRanges.length > 0) {
    content.postedRanges = input.postedRanges
  }

  if (Array.isArray(input.publishLocations) && input.publishLocations.length > 0) {
    content.publishLocations = input.publishLocations
  }

  if (input.sponsored !== init.sponsored) {
    content.sponsored = input.sponsored
  }

  // Set `content` query param in URL
  if (JSON.stringify(content) === "{}") {
    return ""
  }
  return JSON.stringify(content)
}

export function setContentInputQueryParams(
  input: GraphQL.GodSearchContentInput,
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams,
): void {
  const init = initialContentInput()
  const content: Partial<GraphQL.GodSearchContentInput> = {}

  if (Array.isArray(input.brandLogoTags) && input.brandLogoTags.length > 0) {
    content.brandLogoTags = input.brandLogoTags
  }

  if (input.containsMedia !== init.containsMedia) {
    content.containsMedia = input.containsMedia
  }

  if (input.contextualRelevancy !== init.contextualRelevancy) {
    content.contextualRelevancy = input.contextualRelevancy
  }

  if (Array.isArray(input.excludeKeywords) && input.excludeKeywords.length > 0) {
    content.excludeKeywords = input.excludeKeywords
  }

  if (input.excludeNsfwContent !== init.excludeNsfwContent) {
    content.excludeNsfwContent = input.excludeNsfwContent
  }

  if (Array.isArray(input.excludedBrandLogoTags) && input.excludedBrandLogoTags.length > 0) {
    content.excludedBrandLogoTags = input.excludedBrandLogoTags
  }

  if (Array.isArray(input.excludedImageTags) && input.excludedImageTags.length > 0) {
    content.excludedImageTags = input.excludedImageTags
  }

  if (Array.isArray(input.geoTags) && input.geoTags.length > 0) {
    content.geoTags = input.geoTags
  }

  if (Array.isArray(input.imageTags) && input.imageTags.length > 0) {
    content.imageTags = input.imageTags
  }

  if (Array.isArray(input.keywords) && input.keywords.length > 0) {
    content.keywords = input.keywords
  }

  if (input.minPostEngagement !== init.minPostEngagement) {
    content.minPostEngagement = input.minPostEngagement
  }

  if (Array.isArray(input.optionalImageTags) && input.optionalImageTags.length > 0) {
    content.optionalImageTags = input.optionalImageTags
  }

  if (Array.isArray(input.postTypes) && input.postTypes.length > 0) {
    content.postTypes = input.postTypes
  }

  if (Array.isArray(input.postedDates) && input.postedDates.length > 0) {
    content.postedDates = input.postedDates
  }

  if (Array.isArray(input.postedRanges) && input.postedRanges.length > 0) {
    content.postedRanges = input.postedRanges
  }

  if (Array.isArray(input.publishLocations) && input.publishLocations.length > 0) {
    content.publishLocations = input.publishLocations
  }

  if (input.sponsored !== init.sponsored) {
    content.sponsored = input.sponsored
  }

  // Set `content` query param in URL
  if (JSON.stringify(content) === "{}") {
    searchParams.delete(QUERY_PARAM_CONTENT)
    setSearchParams(searchParams)
    return
  }
  searchParams.set(QUERY_PARAM_CONTENT, JSON.stringify(content))
  setSearchParams(searchParams)
}

// SEARCH HELPER MISCELLANEOUS FUNCTIONS
export function brandValueToString(brand: boolean | null | undefined): string {
  if (brand === true) return "brand"
  if (brand === false) return "influencer"
  return "both"
}

export function cloneSearchInput(input: SearchInput): SearchInput {
  return JSON.parse(JSON.stringify(input))
}

export function cloneMinimumMatch(
  minimumMatch: GraphQL.AudienceMinimumMatch,
): GraphQL.AudienceMinimumMatch {
  return JSON.parse(JSON.stringify(minimumMatch))
}

// SORT MODEL HELPER FUNCTIONS
function searchColumnToSortableColumnString(
  column: string,
): SearchFieldString | null {
  // NOTE: Being able to sort by Engagement Rate is currently not supported
  // on the backend. Once that becomes a possibility, Engagement Rate may be
  // re-added to the switch case below.
  switch (column) {
    case SearchColumn.AdCouncilScore:
      return SearchFieldString.AdCouncilScore

    case SearchColumn.AudienceQualityScore:
      return SearchFieldString.AudienceQualityScore

    case SearchColumn.DemographicScore:
      return SearchFieldString.DemographicScore

    case SearchColumn.EngagementRate:
      return SearchFieldString.EngagementRate

    case SearchColumn.EngagementScore:
      return SearchFieldString.EngagementScore

    case SearchColumn.Followers:
      return SearchFieldString.Followers

    case SearchColumn.IScore:
      return SearchFieldString.IScore

    case SearchColumn.InDemoPercentage:
      return SearchFieldString.InDemoPercentage

    case SearchColumn.Matches:
      return SearchFieldString.Matches

    case SearchColumn.OrganicEngagementRate:
      return SearchFieldString.OrganicEngagementRate

    case SearchColumn.PostDate:
      return SearchFieldString.PostDate

    case SearchColumn.SponsoredEngagementRate:
      return SearchFieldString.SponsoredEngagementRate

    default:
      return null
  }
}

function sortableColumnStringToSearchColumn(
  column: string,
): SearchColumn | null {
  // NOTE: Being able to sort by Engagement Rate is currently not supported
  // on the backend. Once that becomes a possibility, Engagement Rate may be
  // re-added to the switch case below.
  switch (column) {
    case SearchFieldString.AdCouncilScore:
      return SearchColumn.AdCouncilScore

    case SearchFieldString.AudienceQualityScore:
      return SearchColumn.AudienceQualityScore

    case SearchFieldString.DemographicScore:
      return SearchColumn.DemographicScore

    case SearchFieldString.EngagementRate:
      return SearchColumn.EngagementRate

    case SearchFieldString.EngagementScore:
      return SearchColumn.EngagementScore

    case SearchFieldString.Followers:
      return SearchColumn.Followers

    case SearchFieldString.IScore:
      return SearchColumn.IScore

    case SearchFieldString.InDemoPercentage:
      return SearchColumn.InDemoPercentage

    case SearchFieldString.Matches:
      return SearchColumn.Matches

    case SearchFieldString.OrganicEngagementRate:
      return SearchColumn.OrganicEngagementRate

    case SearchFieldString.PostDate:
      return SearchColumn.PostDate

    case SearchFieldString.SponsoredEngagementRate:
      return SearchColumn.SponsoredEngagementRate

    default:
      return null
  }
}

export function searchStateToSortModel(state: SearchInput): GridSortModel {
  const sortBy = sortableColumnStringToSearchColumn(state.sortBy)
  const sortDirection: GridSortDirection = state.sortDirection === GraphQL
    .SortDirection.Asc ? "asc" : "desc"

  // No matching field strings, return empty sort model
  if (sortBy == null) return []

  return [ {
    field: sortBy,
    sort: sortDirection,
  } ]
}

export function sortModelToSearchState(
  model: GridSortModel,
  currentInput: SearchInput,
): SearchInput {
  const newInput = cloneSearchInput(currentInput)

  // No sort model was passed... set defaults
  // This happens with MUI DataGrid when a column that's already being sorted
  // by Desc is clicked again
  // (Engagement Score is the default for Account search)
  // (Date Posted is the default for Content search)
  if (model.length === 0) {
    if (
      (currentInput.resultType === GraphQL.GodSearchType.Social
      && currentInput.sortBy === SearchFieldString.EngagementScore)
      || (currentInput.resultType === GraphQL.GodSearchType.Post
      && currentInput.sortBy === SearchFieldString.PostDate)
    ) {
      newInput.sortDirection = GraphQL.SortDirection.Asc
    } else if (
      currentInput.resultType === GraphQL.GodSearchType.Social
    ) {
      newInput.sortDirection = GraphQL.SortDirection.Desc
      newInput.sortBy = SearchFieldString.EngagementScore
    } else if (
      currentInput.resultType === GraphQL.GodSearchType.Post
    ) {
      newInput.sortDirection = GraphQL.SortDirection.Desc
      newInput.sortBy = SearchFieldString.PostDate
    }
    return newInput
  }

  const item = model[0]
  const sortBy = searchColumnToSortableColumnString(item.field)
  const sortDirection = item.sort === "asc"
    ? GraphQL.SortDirection.Asc
    : GraphQL.SortDirection.Desc

  // Column wasn't recognized... set current input
  if (sortBy == null) return currentInput

  newInput.sortBy = sortBy
  newInput.sortDirection = sortDirection
  return newInput
}
