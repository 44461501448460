import React, {
  JSX,
  useMemo,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import {
  Stack,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  Container,
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import PermMediaIcon from "@mui/icons-material/PermMedia"
import { useDispatch, useSelector } from "../../state/hooks"
import { isNumeric } from "../../util/miscHelper"
import * as SearchHelper from "../../state/searchAISlice/helper"
import Input from "../Input"
import Pill from "../Pill"
import FilterPanel from "./FilterPanel"
import { setSearchInput } from "../../state/searchAISlice"

import "./content-filter.sass"

export default function ContentFilters(): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.SearchAIFilterTabs" })
  const dispatch = useDispatch()
  const {
    searchAIInput: searchInput,
    // brands: brandLogoOptions,
    // imageTags: imageTagOptions,
  } = useSelector(({ searchAI }) => searchAI)

  // const [ activeBrandLogo, setActiveBrandLogo ] = useState<GraphQL.BrandLogo | null>(null)
  // const [ activeImageTag, setActiveImageTag ] = useState<string | null>(null)
  // const [ dateRangeToggle, setDateRangeToggle ] = useState<boolean>(true)
  // const [ excludeInputValue, setExcludeInputValue ] = useState("")
  const [ isDecimal, setIsDecimal ] = useState(false)
  const [ keywordInputValue, setKeywordInputValue ] = useState("")
  // const [ postedOnDate, setPostedOnDate ] = useState<Dayjs | null>(null)
  // const [ rangeEndDate, setRangeEndDate ] = useState<Dayjs | null>(null)
  // const [ rangeStartDate, setRangeStartDate ] = useState<Dayjs | null>(null)
  // const [ selectedBrands, setSelectedBrands ] = useState<GraphQL.BrandLogo[]>([])

  // List Helpers
  function handleEnterKey(
    event: React.KeyboardEvent,
    callback: () => void,
  ): void {
    if (event.key === "Enter") {
      event.preventDefault()
      callback()
    }
  }

  function addKeywordsBasic(): void {
    if (keywordInputValue == null || keywordInputValue === "") return
    const newInput = SearchHelper.cloneSearchInput(searchInput)
    const currentKeywords = searchInput.contentParams.keywords

    const newKeywords = keywordInputValue.split(",")
      .map((keyword) => keyword.trim())
      .filter((keyword) => keyword && !currentKeywords.includes(keyword))

    const combinedKeywords: string[] = currentKeywords.concat(newKeywords)
    newInput.contentParams.keywords = combinedKeywords
    dispatch(setSearchInput(newInput))
    setKeywordInputValue("")
  }

  function removeKeyword(keyword: string): void {
    const newInput = SearchHelper.cloneSearchInput(searchInput)
    const filteredKeywords = searchInput.contentParams.keywords
      .filter((_keyword) => _keyword !== keyword)

    newInput.contentParams.keywords = filteredKeywords
    dispatch(setSearchInput(newInput))
  }

  // Excludes Helpers
  // function addExcludesBasic(): void {
  //   if (excludeInputValue == null || excludeInputValue === "") return
  //   const newInput = SearchHelper.cloneSearchInput(searchInput)
  //   const currentExcludes = [ ...searchInput.contentParams.excludeKeywords ]

  //   const newExcludes = excludeInputValue.split(",")
  //     .map((keyword) => keyword.trim())
  //     .filter((keyword) => keyword && !currentExcludes.includes(keyword))

  //   const combinedExcludes: string[] = currentExcludes.concat(newExcludes)
  //   newInput.contentParams.excludeKeywords = combinedExcludes
  //   dispatch(setSearchInput(newInput))
  //   setExcludeInputValue("")
  // }

  // function removeExclude(keyword: string): void {
  //   const newInput = SearchHelper.cloneSearchInput(searchInput)
  //   const filteredExcludes = [ ...searchInput.contentParams.excludeKeywords ]
  //     .filter((k) => keyword !== k)

  //   newInput.contentParams.excludeKeywords = filteredExcludes
  //   dispatch(setSearchInput(newInput))
  // }

  // Image Tags Helpers
  // function addImageTagBasic(imageTag: string | string[] | null): void {
  //   if (imageTag == null || Array.isArray(imageTag)) return
  //   const currentImageTags = searchInput.contentParams.imageTags

  //   // Tag already exists. Reset form state and do nothing.
  //   if (currentImageTags.includes(imageTag)) {
  //     setActiveImageTag(null)
  //     return
  //   }

  //  // Add unique tag to state
  //   const newInput = SearchHelper.cloneSearchInput(searchInput)
  //   const finalImageTags = [ ...currentImageTags, imageTag ]

  //   // Save image tag and reset image tag form state
  //   newInput.contentParams.imageTags = finalImageTags
  //   dispatch(setSearchInput(newInput))
  //   setActiveImageTag(null)
  //   dispatch(setImageTags("init"))
  // }

  // function fetchImageTagsByStartsWithValue(startsWith: string): void {
  //   if (startsWith === "") {
  //     setActiveImageTag(null)
  //     dispatch(setImageTags("init"))
  //     return
  //   }

  //   dispatch(fetchImageTags(startsWith))
  // }

  // function getImageTagOptions(): string[] {
  //   if (
  //     imageTagOptions === "init"
  //     || imageTagOptions === "loading"
  //     || API.isError(imageTagOptions)
  //   ) {
  //     return []
  //   }

  //   return imageTagOptions.payload.searchImageTags.rows
  // }

  // function removeImageTag(imageTag: string): void {
  //   const newInput = SearchHelper.cloneSearchInput(searchInput)
  //   const filteredImageTags = searchInput.contentParams.imageTags
  //     .filter((_imageTag) => _imageTag === imageTag)

  //   newInput.contentParams.imageTags = filteredImageTags
  //   dispatch(setSearchInput(newInput))
  // }

  // function renderImageTagInput(params: AutocompleteRenderInputParams): JSX.Element {
  //   return (
  //     <Input
  //       { ...params }
  //       InputLabelProps={ {} }
  //       label=""
  //       placeholder={ translate("Image tags placeholder") }
  //     />
  //   )
  // }

  // // Brand logos Helpers
  // function addBrandLogoBasic(
  //   brandLogo: string
  //   | GraphQL.BrandLogo
  //   | (string | GraphQL.BrandLogo)[]
  //   | null,
  // ): void {
  //   if (
  //     brandLogo == null
  //     || typeof brandLogo === "string"
  //     || Array.isArray(brandLogo)
  //   ) {
  //     return
  //   }

  //   const currentBrandLogos = [ ...searchInput.contentParams.brandLogoTags ]

  //   // Tag already exists. Reset form state and do nothing.
  //   if (currentBrandLogos.some((logo) => logo.code === brandLogo.code)) {
  //     setActiveBrandLogo(null)
  //     return
  //   }

  //   // Set selected brand logos so pills can render names properly...
  //   const newSelectedBrands = [ ...selectedBrands ]
  //     .filter(({ code }) => code !== brandLogo.code)
  //     .concat([ brandLogo ])

  //   // Add unique tag to state
  //   const newInput = SearchHelper.cloneSearchInput(searchInput)

  //   // Save brand logos and reset brand logo form state
  //   newInput.contentParams.brandLogoTags = newSelectedBrands
  //   dispatch(setSearchInput(newInput))
  //   setActiveBrandLogo(null)
  //   setSelectedBrands(newSelectedBrands)
  //   dispatch(setBrands("init"))
  // }

  // function fetchBrandLogosByStartsWithValue(startsWith: string): void {
  //   if (startsWith === "") {
  //     setActiveBrandLogo(null)
  //     dispatch(setBrands("init"))
  //     return
  //   }

  //   dispatch(fetchBrands(startsWith))
  // }

  // function getBrandLogoOptionLabel(
  //   option: string | GraphQL.BrandLogo,
  // ): string {
  //   if (typeof option === "string") return option
  //   return option.name
  // }

  // function getBrandLogosOptions(): GraphQL.BrandLogo[] {
  //   if (
  //     brandLogoOptions === "init"
  //     || brandLogoOptions === "loading"
  //     || API.isError(brandLogoOptions)
  //   ) {
  //     return []
  //   }

  //   return brandLogoOptions.payload.searchBrandLogos.rows
  // }

  // function removeBrandLogo(brandLogo: GraphQL.BrandLogo): void {
  //   const newInput = SearchHelper.cloneSearchInput(searchInput)
  //   const filteredBrandLogos = searchInput.contentParams.brandLogoTags
  //     .filter((_brandLogo) => _brandLogo.code === brandLogo.code)

  //   const newSelectedBrands = [ ...selectedBrands ]
  //     .filter(({ code }) => code !== brandLogo.code)

  //   newInput.contentParams.brandLogoTags = filteredBrandLogos
  //   setSelectedBrands(newSelectedBrands)
  //   dispatch(setSearchInput(newInput))
  // }

  // function renderBrandLogosInput(params: AutocompleteRenderInputParams): JSX.Element {
  //   return (
  //     <Input
  //       { ...params }
  //       InputLabelProps={ {} }
  //       label=""
  //       placeholder={ translate("Select Brand Logo") }
  //     />
  //   )
  // }

  // // Post Date/Post Range Helpers
  // function clearDateForms(): void {
  //   setRangeStartDate(null)
  //   setRangeEndDate(null)
  //   setPostedOnDate(null)
  // }

  // function setDateState(
  //   d: Dayjs | string | null,
  //   callback: (dateToSet: Dayjs) => void,
  // ): void {
  //   if (d == null) return

  //   const date = typeof d === "string" ? dayjs(d) : d
  //   if (date.isValid()) {
  //     callback(date)
  //   }
  // }

  // function addDatePill(): void {
  //   if (postedOnDate == null) return
  //   const newInput = SearchHelper.cloneSearchInput(searchInput)
  //   const newDate = (postedOnDate.unix() * Constant.THOUSAND).toString()
  //   const newPostedDates = [ ...searchInput.contentParams.postedDates ]
  //     .filter((d) => d !== newDate)
  //     .concat([ newDate ])

  //   newInput.contentParams.postedDates = newPostedDates
  //   dispatch(setSearchInput(newInput))
  //   clearDateForms()
  // }

  // function addRangePill(): void {
  //   if (rangeStartDate == null || rangeEndDate == null) return
  //   if (rangeEndDate.isAfter(rangeStartDate)) {
  //     const newInput = SearchHelper.cloneSearchInput(searchInput)
  //     const newStart: string = (rangeStartDate.unix() * Constant.THOUSAND).toString()
  //     const newEnd: string = (rangeEndDate.unix() * Constant.THOUSAND).toString()
  //     const newPostedRanges = searchInput.contentParams.postedRanges
  //       .filter(({ start, end }) => !(start === newStart && end === newEnd))
  //       .concat([ { start: newStart, end: newEnd } ])

  //     newInput.contentParams.postedRanges = newPostedRanges
  //     dispatch(setSearchInput(newInput))
  //     clearDateForms()
  //   }
  // }

  // function handleRemoveDate(
  //   date: string | { start: string, end: string },
  // ): void {
  //   const newInput = SearchHelper.cloneSearchInput(searchInput)

  //   if (typeof date === "string") {
  //     const newPostedDates = [ ...searchInput.contentParams.postedDates ]
  //       .filter((p) => p !== date)

  //     newInput.contentParams.postedDates = newPostedDates
  //     dispatch(setSearchInput(newInput))
  //     return
  //   }

  //   const newPostedRanges = [ ...searchInput.contentParams.postedRanges ]
  //     .filter(({ start, end }) => !(date.start === start && date.end === end))

  //   newInput.contentParams.postedRanges = newPostedRanges
  //   dispatch(setSearchInput(newInput))
  // }

  // function renderDatePill(
  //   date: string | { start: string, end: string },
  // ): JSX.Element {
  //   let dateString

  //   if (typeof date === "string") {
  //     dateString = dayjs.unix(
  //       parseInt(date, 10) / Constant.THOUSAND,
  //     ).format(Constant.LONGFORM_DATE)
  //   } else {
  //     const dateStringStart = dayjs.unix(
  //       parseInt(date.start, 10) / Constant.THOUSAND,
  //     ).format(Constant.LONGFORM_DATE)

  //     const dateStringEnd = dayjs.unix(
  //       parseInt(date.end, 10) / Constant.THOUSAND,
  //     ).format(Constant.LONGFORM_DATE)

  //     dateString = `${ dateStringStart } - ${ dateStringEnd }`
  //   }

  //   return (
  //     <Pill
  //       key={ dateString }
  //       label={ dateString }
  //       onDelete={ () => handleRemoveDate(date) }
  //     />
  //   )
  // }

  // useEffect(() => {
  //   addDatePill()
  // }, [ postedOnDate ])

  // useEffect(() => {
  //   addRangePill()
  // }, [ rangeStartDate, rangeEndDate ])

  // // Check for full range dates
  // useEffect(() => {
  //   if (rangeStartDate && rangeEndDate) {
  //     addRangePill()
  //   } else if (postedOnDate) {
  //     addDatePill()
  //   }
  // }, [ rangeStartDate, rangeEndDate, postedOnDate ])

  // Engagement Rate Helpers
  function getMinEngagementRateValue(
    minEngagement: number | null | undefined,
  ): string {
    if (minEngagement == null) return ""
    const percentValue = (minEngagement * 100).toLocaleString()
    if (isDecimal) return `${ percentValue }.`.slice(0, 5)
    return percentValue
  }

  const handleEngmntFieldChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value
    const newInput = SearchHelper.cloneSearchInput(searchInput)

    if (newValue === "") {
      newInput.contentParams.minPostEngagement = null
      dispatch(setSearchInput(newInput))
      return
    }

    if (isNumeric(newValue) && parseFloat(newValue) <= 100) {
      newInput.contentParams.minPostEngagement = parseFloat(newValue) * 0.01
      dispatch(setSearchInput(newInput))
    }

    if (newValue.slice(-1) === ".") {
      setIsDecimal(true)
      return
    }

    setIsDecimal(false)
  }

  // Publish Location Helpers
  // function togglePublishLocation(pl: GraphQL.PublishLocation): void {
  //   const newInput = SearchHelper.cloneSearchInput(searchInput)
  //   const currentPLs = [ ...searchInput.contentParams.publishLocations ]
  //   const checked = currentPLs.some((l) => l === pl)

  //   if (checked) {
  //     const newPLs = currentPLs.filter((l) => l !== pl)
  //     newInput.contentParams.publishLocations = newPLs
  //     dispatch(setSearchInput(newInput))
  //     return
  //   }

  //   const newPLs = currentPLs.concat([ pl ])
  //   newInput.contentParams.publishLocations = newPLs
  //   dispatch(setSearchInput(newInput))
  // }

  const filterCount = useMemo(() => {
    let count = searchInput.contentParams.keywords.length
    if (searchInput.contentParams.minPostEngagement) count += 1
    return count
  }, [ searchInput.contentParams ])

  return (
    <FilterPanel title={ translate("Content") } panelIcon={ PermMediaIcon } filterCount={ filterCount }>
      <Container className="cp_component_search-ai-content cp_component_search-form-container">
        <Stack padding={ 2 } gap={ 1 }>
          <Stack direction="row" justifyContent="space-between">
            <form>
              { /* Keywords section */ }
              <FormControl
                className="cp_component_search-ai-content-keywords"
                component="fieldset"
                fullWidth={ true }
              >
                <div className="cp_component_search-ai-content-keywords_header has-advanced-link">
                  <FormLabel component="legend">
                    { translate("KEYWORDS") }
                  </FormLabel>
                </div>
                <Input
                  label=""
                  value={ keywordInputValue }
                  placeholder={ translate("Keywords placehoder") }
                  onChange={ (e) => setKeywordInputValue(e.target.value) }
                  onKeyDown={ (e) => handleEnterKey(e, addKeywordsBasic) }
                  InputProps={ {
                    endAdornment: (
                      <IconButton onClick={ () => addKeywordsBasic() }>
                        <AddIcon />
                      </IconButton>
                    ),
                  } }
                />
                <div className="cp_component_search-ai-content-keywords-pills">
                  { searchInput.contentParams.keywords.map((keyword) => (
                    <Pill
                      key={ keyword }
                      label={ keyword }
                      onDelete={ () => removeKeyword(keyword) }
                    />
                  )) }
                </div>
              </FormControl>
              { /* Excludes words section */ }
              { /* <FormControl
                className="cp_component_search-ai-content-excludes"
                component="fieldset"
                fullWidth={ true }
              >
                <FormLabel component="legend">
                  { translate("EXCLUDES") }
                </FormLabel>
                <Input
                  label=""
                  value={ excludeInputValue }
                  placeholder={ translate("Keywords placehoder") }
                  onChange={ (e) => setExcludeInputValue(e.target.value) }
                  onKeyDown={ (e) => handleEnterKey(e, addExcludesBasic) }
                  InputProps={ {
                    endAdornment: (
                      <IconButton onClick={ () => addExcludesBasic() }>
                        <AddIcon />
                      </IconButton>
                    ),
                  } }
                />
                <div className="cp_component_search-ai-content-excludes-pills">
                  { searchInput.contentParams.excludeKeywords.map((keyword) => (
                    <Pill
                      key={ keyword }
                      label={ keyword }
                      onDelete={ () => removeExclude(keyword) }
                    />
                  )) }
                </div>
              </FormControl> */ }
              { /* Image tag section */ }
              { /* <FormControl
                className="cp_component_search-ai-content-imagetags"
                component="fieldset"
                fullWidth={ true }
              >
                <div className="cp_component_search-ai-content-imagetags_header has-advanced-link">
                  <FormLabel component="legend">
                    { translate("IMAGE TAGS") }
                  </FormLabel>
                </div>
                <Autocomplete
                  disablePortal={ true }
                  filterOptions={ (x) => x }
                  filterSelectedOptions={ true }
                  getOptionLabel={ (o) => o || "" }
                  isOptionEqualToValue={ (tag) => tag === activeImageTag }
                  loading={ imageTagOptions === "loading" }
                  loadingText={ translate("Loading image tags...") }
                  noOptionsText={ translate("Search for an image tag") }
                  onInputChange={ debounce((_, newValue) => {
                    fetchImageTagsByStartsWithValue(newValue)
                  }, Constant.DEFAULT_DEBOUNCE_WAIT) }
                  onChange={ (_, newValue) => addImageTagBasic(newValue) }
                  options={ getImageTagOptions() }
                  renderInput={ (params) => renderImageTagInput(params) }
                  value={ activeImageTag }
                />
                <div className="cp_component_search-ai-content-imagetags-pills">
                  { searchInput.contentParams.imageTags.map((imageTag) => (
                    <Pill
                      key={ imageTag }
                      label={ imageTag }
                      onDelete={ () => removeImageTag(imageTag) }
                    />
                  )) }
                </div>
              </FormControl> */ }

              { /* Brand logos section */ }
              { /* { scopes.includes(Scope.FEATURE_SEARCH_BRAND_LOGO) && (
              <FormControl
                className="cp_component_search-ai-content-brandlogos"
                component="fieldset"
                fullWidth={ true }
              >
                <div className="cp_component_search-ai-content-brandlogos_header has-advanced-link">
                  <FormLabel component="legend">
                    { translate("BRAND LOGOS") }
                  </FormLabel>
                </div>
                <Autocomplete
                  disablePortal={ true }
                  filterOptions={ (x) => x }
                  filterSelectedOptions={ true }
                  getOptionLabel={ (o) => getBrandLogoOptionLabel(o) }
                  isOptionEqualToValue={ ({ code }) => code === activeBrandLogo?.code }
                  loading={ brandLogoOptions === "loading" }
                  loadingText={ translate("Loading brand logos...") }
                  noOptionsText={ translate("Search for a brand logo") }
                  onInputChange={ debounce((_, newValue) => {
                    fetchBrandLogosByStartsWithValue(newValue)
                  }, Constant.DEFAULT_DEBOUNCE_WAIT) }
                  onChange={ (_, newValue) => addBrandLogoBasic(newValue) }
                  options={ getBrandLogosOptions() }
                  renderInput={ (params) => renderBrandLogosInput(params) }
                  value={ activeBrandLogo }
                />
                <div className="cp_component_search-ai-content-brandlogos-pills">
                  { selectedBrands.map((b) => (
                    <Pill
                      key={ b.code }
                      label={ b.name }
                      onDelete={ () => removeBrandLogo(b) }
                    />
                  )) }
                </div>
              </FormControl>
              ) } */ }

              { /* Dates section */ }
              { /* <FormControl
                className="cp_component_search-ai-content-postedon"
                component="fieldset"
                fullWidth={ true }
              >
                <div className="cp_component_search-ai-content-postedon-header">
                  <ButtonGroup size="small">
                    <Button
                      className={ `postedon-button ${ dateRangeToggle ? "posted-button-active" : "" }` }
                      label={ translate("DATE") }
                      isEnabled={ true }
                      isPrimary={ true }
                      size="small"
                      onClick={ () => { setDateRangeToggle(true) } }
                    />
                    <Button
                      className={ `postedon-button ${ !dateRangeToggle ? "posted-button-active" : "" }` }
                      label={ translate("RANGE") }
                      isEnabled={ true }
                      isPrimary={ true }
                      size="small"
                      onClick={ () => { setDateRangeToggle(false) } }
                    />
                  </ButtonGroup>
                </div> */ }
              { /* Post on date */ }
              { /* { dateRangeToggle && (
                <div className="cp_component_search-ai-content-postedon-date">
                  <div className="date">
                    <FormLabel component="legend" id="postedonMuiLegend">
                      { translate("POSTED ON") }
                    </FormLabel>
                    <DatePicker
                      value={ postedOnDate }
                      onDateChange={ (d) => setDateState(d, setPostedOnDate) }
                      onAccept={ (d) => setDateState(d, setPostedOnDate) }
                    />
                  </div>
                </div>
                ) }
                */ }
              { /* Post on date */ }
              { /* { !dateRangeToggle && (
                <div className="cp_component_search-ai-content-postedon-range">
                  <div className="range-posted-start">
                    <FormLabel component="legend" id="postedonMuiLegend">
                      { translate("POSTED START") }
                    </FormLabel>
                    <DatePicker
                      value={ rangeStartDate }
                      onDateChange={ (d) => setDateState(d, setRangeStartDate) }
                      onAccept={ (d) => setDateState(d, setRangeStartDate) }
                    />
                  </div>
                  <div className="range-posted-end">
                    <FormLabel component="legend" id="postedonMuiLegend">
                      { translate("END") }
                    </FormLabel>
                    <DatePicker
                      value={ rangeEndDate }
                      onDateChange={ (d) => setDateState(d, setRangeEndDate) }
                      onAccept={ (d) => setDateState(d, setRangeEndDate) }
                    />
                  </div>
                </div>
                ) }
                <div className="cp_component_search-ai-content-postedon-pills">
                  { [ ...searchInput.contentParams.postedDates,
                    ...searchInput.contentParams.postedRanges,
                  ].map(renderDatePill) }
                </div>
              </FormControl> */ }
              { /* Minimum Post Engagement Rate section */ }
              <FormControl
                className="cp_component_search-ai-content-engagement"
                component="fieldset"
                fullWidth={ true }
              >
                <FormLabel component="legend">
                  { translate("MINIMUM POST ENGAGEMENT RATE") }
                </FormLabel>
                <Input
                  label=""
                  placeholder={ translate("Enter engagement rate") }
                  value={ getMinEngagementRateValue(
                    searchInput.contentParams.minPostEngagement,
                  ) }
                  onChange={ handleEngmntFieldChange }
                  type="text"
                  InputProps={ {
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    inputProps: {
                      min: 0,
                      max: 100,
                      step: "0.01",
                      maxLength: 5,
                    },
                  } }
                />
              </FormControl>
              { /* Post Types section */ }
              { /* <FormControl
                className="cp_component_search-ai-content-post_type"
                component="fieldset"
                fullWidth={ true }
              >
                <FormLabel component="legend">
                  { translate("POST TYPE") }
                  <Tooltip arrow={ true } title={ translate("PostType Tooltip") }>
                    <HelpOutlineIcon fontSize="small" />
                  </Tooltip>
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={ (
                      <Checkbox
                        checked={ searchInput.contentParams.sponsored === true }
                        onChange={ () => {
                          const newInput = SearchHelper.cloneSearchInput(searchInput)
                          const checked = searchInput.contentParams.sponsored

                          if (checked == null) {
                            newInput.contentParams.sponsored = true
                            dispatch(setSearchInput(newInput))
                            return
                          }

                          if (checked) {
                            newInput.contentParams.sponsored = null
                            dispatch(setSearchInput(newInput))
                            return
                          }

                          newInput.contentParams.sponsored = true
                          dispatch(setSearchInput(newInput))
                        } }
                        name={ translate("SPONSORED") }
                      />
              ) }
                    label={ translate("SPONSORED") }
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={ (
                      <Checkbox
                        checked={ searchInput.contentParams.sponsored === false }
                        onChange={ () => {
                          const newInput = SearchHelper.cloneSearchInput(searchInput)
                          const checked = searchInput.contentParams.sponsored

                          if (checked == null) {
                            newInput.contentParams.sponsored = false
                            dispatch(setSearchInput(newInput))
                            return
                          }

                          if (checked) {
                            newInput.contentParams.sponsored = false
                            dispatch(setSearchInput(newInput))
                            return
                          }

                          newInput.contentParams.sponsored = null
                          dispatch(setSearchInput(newInput))
                        } }
                        name={ translate("ORGANIC") }
                      />
              ) }
                    label={ translate("ORGANIC") }
                  />
                </FormGroup>
              </FormControl> */ }
              { /* Content Types section */ }
              { /* <FormControl
                className="cp_component_search-ai-content-content_type"
                component="fieldset"
                fullWidth={ true }
              >
                <FormLabel component="legend">
                  { translate("CONTENT TYPE") }
                  <Tooltip arrow={ true } title={ translate("ContentType Tooltip") }>
                    <HelpOutlineIcon fontSize="small" />
                  </Tooltip>
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={ (
                      <Checkbox
                        checked={
                    searchInput.contentParams.publishLocations
                      .some((l) => l === GraphQL.PublishLocation.Feed)
                  }
                        onChange={ () => togglePublishLocation(GraphQL.PublishLocation.Feed) }
                        name={ translate("IN-FEED") }
                      />
              ) }
                    label={ translate("IN-FEED") }
                  />
                  <FormControlLabel
                    control={ (
                      <Checkbox
                        checked={
                    searchInput.contentParams.publishLocations
                      .some((l) => l === GraphQL.PublishLocation.Short)
                  }
                        onChange={ () => togglePublishLocation(GraphQL.PublishLocation.Short) }
                        name={ translate("SHORT-FORM") }
                      />
              ) }
                    label={ translate("SHORT-FORM") }
                  />
                  <FormControlLabel
                    control={ (
                      <Checkbox
                        checked={
                    searchInput.contentParams.publishLocations
                      .some((l) => l === GraphQL.PublishLocation.Story)
                  }
                        onChange={ () => togglePublishLocation(GraphQL.PublishLocation.Story) }
                        name={ translate("STORY") }
                      />
              ) }
                    label={ translate("STORY") }
                  />
                  <FormControlLabel
                    control={ (
                      <Checkbox
                        checked={ searchInput.contentParams.containsMedia || false }
                        onChange={ () => {
                          const newInput = SearchHelper.cloneSearchInput(searchInput)
                          const checked = searchInput.contentParams.containsMedia || false
                          newInput.contentParams.containsMedia = !checked
                          dispatch(setSearchInput(newInput))
                        } }
                        name={ translate("CONTENT MUST CONTAIN MEDIA") }
                      />
              ) }
                    label={ translate("CONTENT MUST CONTAIN MEDIA") }
                  />
                  { scopes.includes(Scope.NSFW_FLAG) && (
                  <FormControlLabel
                    control={ (
                      <Checkbox
                        checked={ searchInput.contentParams.excludeNsfwContent || false }
                        onChange={ () => {
                          const newInput = SearchHelper.cloneSearchInput(searchInput)
                          const checked = searchInput.contentParams.excludeNsfwContent || false
                          newInput.contentParams.excludeNsfwContent = !checked
                          dispatch(setSearchInput(newInput))
                        } }
                        name={ translate("HIDE NOT SAFE FOR WORK CONTENT") }
                      />
              ) }
                    label={ translate("HIDE NOT SAFE FOR WORK CONTENT") }
                  />
                  ) }
                </FormGroup>
              </FormControl> */ }
            </form>
          </Stack>
        </Stack>
      </Container>
    </FilterPanel>
  )
}
