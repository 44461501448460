/* eslint-disable max-len */
import React, {
  useEffect,
  useMemo,
  useState,
} from "react"
import CloseIcon from "@mui/icons-material/Close"
import { Autocomplete, debounce } from "@mui/material"
import { useTranslation } from "react-i18next"
import * as API from "../../util/apiClient"
import Input from "../Input"
import LoadingIndicator from "../LoadingIndicator"
import { DEFAULT_DEBOUNCE_WAIT } from "../../util/constant"
import { fetchCommEmails } from "../../state/modalCreateMessageSlice"
import { useDispatch, useSelector } from "../../state/hooks"
import "./commGroupEmail-autocomplete.sass"

export type NetworkInfo = {
  accountName: string
  email: string
  socialAccountId: string
}

interface Props {
  communicationGroupId: string,
  setSelectedEmails: (emails: NetworkInfo[]) => void
  selectedEmails: NetworkInfo[] | null,
  setShowCommCC: (() => void) | null
}

function CommGroupEmailsAutocomplete({
  communicationGroupId,
  setSelectedEmails,
  selectedEmails,
  setShowCommCC,
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalCreateMessage" })
  const dispatch = useDispatch()
  const [ emailsLoading, setEmailsLoading ] = useState(false)
  const { commGroupEmails } = useSelector(({ ModalCreateMessageSlice }) => ModalCreateMessageSlice)
  const showCCTriggers = !!(setShowCommCC)

  // Fetch emails when communicationGroupId changes
  useEffect(() => {
    if (communicationGroupId) {
      dispatch(fetchCommEmails(communicationGroupId, ""))
    }
  }, [ communicationGroupId, dispatch ])

  // Debounced search for emails
  const handleSearchChange = debounce(async (_e: React.SyntheticEvent, inputValue: string) => {
    setEmailsLoading(true)
    await dispatch(fetchCommEmails(communicationGroupId, inputValue))
    setEmailsLoading(false)
  }, DEFAULT_DEBOUNCE_WAIT)

  // Sorted emails
  const sortedEmails = useMemo(() => {
    if (!API.isSuccess(commGroupEmails)) return []

    const { rows } = commGroupEmails.payload.searchCommunicationGroupNetworkAccount
    const result: NetworkInfo[] = []

    // Helper function to handle pushing emails to result array
    const addEmails = (emails: { address: string }[], accountName: string, socialAccountId: string) => {
      emails.forEach((email) => {
        result.push({
          accountName,
          email: email.address,
          socialAccountId,
        })
      })
    }

    rows.forEach((row) => {
      const { socialAccount } = row

      if (socialAccount.emails.length > 0) {
        addEmails(socialAccount.emails, socialAccount.userName, socialAccount.id)
      }

      if (socialAccount.emailsSourcedFromTeam.length > 0) {
        addEmails(socialAccount.emailsSourcedFromTeam, socialAccount.userName, socialAccount.id)
      }
    })

    return result
  }, [ commGroupEmails ])

  const onAutoCompleteChange = (_e: React.SyntheticEvent, emailSelections: NetworkInfo[]) => {
    if (Array.isArray(emailSelections)) {
      // If an email has been removed, just set the state with the new emailSelections
      if (emailSelections.length < (selectedEmails?.length || 0)) {
        setSelectedEmails(emailSelections)
      } else {
        // Otherwise, add unique emails to the selectedEmails
        const newEmails = selectedEmails
          ? [
            ...selectedEmails,
            ...emailSelections.filter(
              (selection) => !selectedEmails.some((recipient) => recipient.email === selection.email),
            ),
          ]
          : [ ...emailSelections ]

        setSelectedEmails(newEmails)
      }
    }
  }

  const handleCommClick = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent> | React.KeyboardEvent<HTMLSpanElement>,
    action: () => void,
  ) => {
    e.stopPropagation()
    action()
  }

  return (
    <Autocomplete
      multiple={ true }
      disableClearable={ false }
      options={ sortedEmails }
      onInputChange={ handleSearchChange }
      openOnFocus={ true }
      loading={ emailsLoading }
      value={ selectedEmails || [] }
      onChange={ onAutoCompleteChange }
      isOptionEqualToValue={ (option, value) => option.email === value.email } // Custom equality check instead of the default strict equality
      getOptionLabel={ (option) => option.email || "" }
      loadingText={ <LoadingIndicator size={ 20 } /> }
      noOptionsText={ translate("No matching accounts found in this group") }
      filterOptions={ (options) => options.filter(
        (option) => selectedEmails
          ? !selectedEmails.some((selected) => selected.email === option.email)
          : true,
      ) }
      renderOption={ (props, option) => (
        <li { ...props }>
          <div className="cp-comm-group-autocomplete-option">
            <p className="userName">{ option.accountName }</p>
            <p className="email">{ option.email }</p>
          </div>
        </li>
      ) }
      renderInput={ (params) => (
        <Input
          { ...params }
          className="cp_autocomplete_comm-group-email_input"
          InputProps={ {
            ...params.InputProps,
            endAdornment: showCCTriggers ? (
              <div className="cc-labels">
                <span
                  role="button"
                  tabIndex={ 0 }
                  onClick={ (e) => handleCommClick(e, setShowCommCC) }
                  className="cc-text"
                  onKeyDown={ (e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      handleCommClick(e, setShowCommCC)
                    }
                  } }
                >
                  CC
                </span>
              </div>
            ) : (null),
          } }
        />
      ) }
      ChipProps={ {
        deleteIcon: <CloseIcon />,
        classes: { deleteIcon: "custom-delete-icon" },
      } }
    />
  )
}

export default CommGroupEmailsAutocomplete
