import React from "react"
import { useTranslation } from "react-i18next"
import "./style.sass"
import ModalSelectAccount from "../ModalSelectAccount"
import {
  getComms,
  getCommsForAccount,
  getCommsForProfile,
  setAddToModalPhase,
  setSelectedCommIDs,
  setSelectedSocialAccountID,
  setSelectedSocialAccountNetwork,
  submitSocialAccountsToComms,
} from "../../../state/socialProfileSlice"
import * as API from "../../../util/apiClient"
import { pushToast } from "../../../state/toastSlice"
import LoadingIndicator from "../../LoadingIndicator"
import { useDispatch, useSelector } from "../../../state/hooks"
import * as GraphQL from "../../../graphql"
import CommunicationsTabContent from "./CommunicationsTabContent"
import ModalAddToCommunications from "./ModalAddToCommunications"
import { ALL_NETWORKS } from "../../../util/types"

export default function Comms() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ProfileTabs" })

  const {
    addToModalPhase: modalPhase,
    profile,
    selectedCommIDs,
    selectedSocialAccountID,
  } = useSelector((state) => state.socialProfile)

  const dispatch = useDispatch()

  if (profile === "init" || profile === "loading") {
    return <LoadingIndicator flexWrapperEnabled={ true } size={ 50 } />
  }

  if (API.isError(profile)) {
    return <p>There was an error!</p>
  }

  const resetFields = () => {
    dispatch(setSelectedCommIDs([]))
    dispatch(setSelectedSocialAccountID(""))
    dispatch(setSelectedSocialAccountNetwork(null))
  }

  const openModalFlow = () => {
    resetFields()
    const socialAccounts = profile.payload.socialAccount.personality?.socialAccounts
    if (socialAccounts && socialAccounts.length > 1) {
      dispatch(setAddToModalPhase(1))
    } else {
      dispatch(setSelectedSocialAccountID(profile.payload.socialAccount.id))
      dispatch(setSelectedSocialAccountNetwork(profile.payload.socialAccount.network))
      dispatch(setAddToModalPhase(2))
      dispatch(getComms(""))
      dispatch(getCommsForAccount(profile.payload.socialAccount.id, [ profile.payload.socialAccount.network ]))
    }
  }

  const onAccountRowClick = (socialAccount: GraphQL.SocialAccountRowFragment) => {
    dispatch(setSelectedSocialAccountID(socialAccount.id))
    dispatch(setSelectedSocialAccountNetwork(socialAccount.network))
    dispatch(getComms(""))
    dispatch(setAddToModalPhase(2))
    dispatch(getCommsForAccount(socialAccount.id, [ socialAccount.network ]))
  }

  const onSubmit = async () => {
    // TODO: This logic for multiple account submission is temporary until backend allows multiple
    dispatch(setAddToModalPhase(null))
    const results = await submitSocialAccountsToComms(
      selectedSocialAccountID,
      selectedCommIDs,
    )
    resetFields()
    if (results.some((result) => API.isError(result))) {
      dispatch(pushToast({
        message: translate("There was an error. Please try again."),
        type: "error",
      }))
    } else {
      dispatch(pushToast({
        message: translate("Account has successfully been added!"),
        type: "success",
      }))
      const accountIds = profile.payload.socialAccount.personality?.socialAccounts.map((a) => a.id)
      || [ profile.payload.socialAccount.id ]
      dispatch(getCommsForProfile(
        accountIds,
        ALL_NETWORKS,
      ))
    }
  }

  return (
    <div className="cp_component_profile-tabs_communications">
      <CommunicationsTabContent
        onButtonClick={ openModalFlow }
      />
      <ModalSelectAccount
        socialProfile={ API.isSuccess(profile) ? profile.payload.socialAccount : null }
        open={ modalPhase === 1 }
        closeAction={ () => dispatch(setAddToModalPhase(null)) }
        onRowClick={ onAccountRowClick }
        title={ translate("Select An Account") }
        subtitle={ translate("Select an account to add to a communication group below.") }
      />
      <ModalAddToCommunications
        onSubmit={ onSubmit }
      />
    </div>
  )
}
