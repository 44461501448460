import React, { JSX } from "react"
import { useTranslation } from "react-i18next"
import { InputAdornment } from "@mui/material"
import { Search as SearchIcon } from "@mui/icons-material"

import * as SearchHelper from "../../state/searchAISlice/helper"
import Input from "../Input"
import { setSearchInput } from "../../state/searchAISlice"
import { useDispatch, useSelector } from "../../state/hooks"

export default function StartsWithInput(): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.SearchAIResults" })
  const dispatch = useDispatch()
  const searchInput = useSelector(({ searchAI }) => searchAI.searchAIInput)

  return (
    <Input
      className="cp_component_search-ai-results-search-input"
      placeholder={ translate("Search Results Table") }
      value={ searchInput.username || "" }
      onChange={ (e) => {
        const newInput = SearchHelper.cloneSearchInput(searchInput)
        newInput.username = e.target.value === "" ? undefined : e.target.value
        dispatch(setSearchInput(newInput))
      } }
      InputProps={ {
        startAdornment: (
          <InputAdornment position="end">
            <SearchIcon
              className="cp_component_search-ai-results-search-icon"
            />
          </InputAdornment>
        ),
      } }
    />
  )
}
