import * as React from "react"
import Popover from "@mui/material/Popover"
import { ButtonGroup, IconButton } from "@mui/material"
import { Editor } from "@tiptap/react"
import { Link as LinkIcon } from "@mui/icons-material"
import { useTranslation } from "react-i18next"

import Button from "../Button"
import Input from "../Input"
import Tooltip from "../Tooltip"

import "./link-input-popover.sass"

type Props = {
  editor: Editor
}

export default function LinkInputPopover({ editor }: Props) {
  const [ anchorEl, setAnchorEl ] = React.useState<HTMLButtonElement | null>(null)
  const { t: translate } = useTranslation([], { keyPrefix: "component.MenuBar" })

  const [ url, setUrl ] = React.useState("")

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUrl(editor.getAttributes("link").href)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLinkClick = () => {
    // empty
    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink()
        .run()
    }

    editor.chain().focus().extendMarkRange("link").setLink({ href: url })
      .run()

    handleClose()
  }

  const handleUnlinkClick = () => {
    editor.chain().focus().extendMarkRange("link").unsetLink()
      .run()

    handleClose()
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  return (
    <>
      <Tooltip title={ translate("Link") }>
        <IconButton onClick={ handleClick }>
          <LinkIcon />
        </IconButton>
      </Tooltip>
      <Popover
        className="cp_component_link-input-popover"
        id={ id }
        open={ open }
        anchorEl={ anchorEl }
        onClose={ handleClose }
        anchorOrigin={ {
          vertical: "bottom",
          horizontal: "left",
        } }
      >
        <div className="main-container">
          <Input
            onChange={ (e) => setUrl(e.target.value) }
            value={ url }
            placeholder={ translate("Enter a URL...") }
          />
          <ButtonGroup>
            <Button onClick={ handleLinkClick } label={ translate("Link") } />
            <Button
              disabled={ editor.getAttributes("link").href }
              onClick={ handleUnlinkClick }
              label={ translate("Unlink") }
            />
          </ButtonGroup>
        </div>
      </Popover>
    </>
  )
}
