import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import "./name-deliverable-input-step.sass"
import Input from "../Input"
import { useDispatch, useSelector } from "../../state/hooks"
import {
  pushStepCompleted,
  removeStepCompleted,
  setClientNote,
  setDeliverableTitle,
} from "../../state/ModalCreateDeliverable"

const INPUT_STEP = 1

export default function NameDeliverableInputStep() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalCreateDeliverable" })
  const {
    deliverableTitle, clientNote, stepsCompleted,
  } = useSelector(({ modalCreateDeliverable }) => modalCreateDeliverable)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!stepsCompleted.includes(INPUT_STEP) && deliverableTitle.length > 0) dispatch(pushStepCompleted(INPUT_STEP))
    if (stepsCompleted.includes(INPUT_STEP) && deliverableTitle.length === 0) dispatch(removeStepCompleted(INPUT_STEP))
  }, [ deliverableTitle ])

  return (
    <div className="cp_component_name-deliverable-input-step">
      <h4>{ translate("Name Deliverable") }</h4>

      <div className="deliverable-title-input-label">
        <p className="label_small-caps-bold">{ `${ translate("Deliverable Title") }*` }</p>
        <p className="label_small-caps-bold counter">
          { deliverableTitle.length }
          /120
        </p>
      </div>
      <Input
        id="cp_component_modal-create-deliverable-deliverable-title-input"
        className="deliverable-title-input"
        value={ deliverableTitle }
        onChange={ (e) => { if (e.target.value.length <= 120) dispatch(setDeliverableTitle(e.target.value)) } }
        placeholder={ translate("Enter Deliverable Title") }
      />

      <div className="client-note-input-label">
        <p className="label_small-caps-bold">{ `${ translate("Client Note") }*` }</p>
        <p className="optional-text">
          { translate("(Optional)") }
        </p>
      </div>
      <Input
        id="cp_component_modal-create-deliverable-client-note-input"
        className="client-note-input"
        value={ clientNote }
        multiline={ true }
        maxRows={ 2 }
        onChange={ (e) => dispatch(setClientNote(e.target.value)) }
        placeholder={ translate("Enter Client Note...") }
      />
    </div>
  )
}
