import React, { useEffect, useState } from "react"
import "./style.sass"
import { useNavigate, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { EditOutlined, Tune as TuneIcon } from "@mui/icons-material"
import GroupAccountsLabels from "../labels"
import { useDispatch, useSelector } from "../../../../state/hooks"
import { fetchSearchResults } from "../../../../state/searchSlice"
import { addSocialAccounts } from "../../../../state/communicationsPage"
import SearchBar from "../../../SearchBar"
import IconButton from "../../../IconButton"
import { Scope, Toast } from "../../../../util/types"
import Button from "../../../Button"
import SearchFilter from "../../../SearchFilter"
import SearchFilterTabs from "../../../SearchFilterTabs"
import SearchResultsTable from "../../../SearchResultsTable"
import { pushToast } from "../../../../state/toastSlice"
import { seteditCommGroupID, setCommGroupModalOpen } from "../../../../state/ModalCommGroupSlice"

/**
 * Component to render the content for group accounts for a specific campaign and/or list of accounts
 * @returns Renders the content of the group accounts
 */
export default function GroupAccountsSearch() {
  // ************************ Component variables ************************
  const navigate = useNavigate()
  const { t: translate } = useTranslation([], { keyPrefix: "component.GroupAccountsSearch" })
  const dispatch = useDispatch()
  const { commGroupID, vanity } = useParams()

  // ************************ Selectors ************************
  const { scopes } = useSelector(({ user }) => user)
  const { selectedAccounts } = useSelector(({ search }) => search)

  // ************************ Local state ************************
  const [ addToGroupDisabled, setAddToGroupDisabled ] = useState<boolean>(true)

  // ************************ React hooks ************************
  useEffect(() => {
    if (selectedAccounts.length === 0) setAddToGroupDisabled(true)
    else setAddToGroupDisabled(false)
  }, [ selectedAccounts ])

  // ************************ functions ************************
  const onEditClick = () => {
    if (commGroupID) {
      dispatch(seteditCommGroupID(commGroupID))
      dispatch(setCommGroupModalOpen(true))
    }
  }

  const onAddToGroupClick = () => {
    // If no rows were selected, navigate back to the group accounts page
    if (selectedAccounts.length === 0) {
      navigate(`/${ vanity }/communications/group/${ commGroupID }/accounts`)
      return
    }

    // Do something
    dispatch(addSocialAccounts({
      vars: {
        communicationGroupId: commGroupID || "",
        networkAccountIds: selectedAccounts,
      },
      onSuccess: () => {
        const toast: Toast = {
          type: "success",
          message: translate("The selected account(s) were added to the Communication Group!"),
        }
        dispatch(pushToast(toast))
        navigate(`/${ vanity }/communications/group/${ commGroupID }/accounts`)
      },
      onError: () => {
        const toast: Toast = {
          type: "error",
          message: translate("Failed to add the selected accounts(s) to the Communication Group!"),
        }
        dispatch(pushToast(toast))
      },
    }))
  }

  const openFilterModal = () => {
    // Do Nothing, not implemented
  }

  // ************************ Pre-Render computations ************************

  // Render the component
  return (
    <div className="cp_component_communications-group-accounts-search-container">
      <div className="header-section">
        <SearchBar className="search-bar" onChange={ () => { } } />
        <div className="details-container">
          <IconButton id="group-accounts-filter-modal-btn" variant="outlined" onClick={ openFilterModal }>
            <TuneIcon />
          </IconButton>
          { scopes.includes(Scope.COMMUNICATIONS_MANAGEMENT) && (
            <GroupAccountsLabels rowSelections={ [] } />
          ) }
          <IconButton onClick={ onEditClick }>
            <EditOutlined />
          </IconButton>
          <Button
            className="add-account-button"
            label={ translate("+ ADD TO GROUP") }
            onClick={ onAddToGroupClick }
            disabled={ addToGroupDisabled }
          />
        </div>
      </div>
      <div className="breadcrumbs-section">
        <Button
          className="back-to-group-btn"
          isTextButton={ true }
          label={ translate("< Back to group") }
          onClick={ () => navigate(`/${ vanity }/communications/group/${ commGroupID }/accounts`) }
        />
      </div>
      <div className="communications-body">
        <div className="communications-body_search-form">
          <SearchFilter />
          <SearchFilterTabs />
          <aside className="cp_page_search-button">
            <Button
              isEnabled={ true }
              isPrimary={ true }
              label={ translate("Search") }
              onClick={ () => dispatch(fetchSearchResults(true)) }
            />
          </aside>
        </div>
        <div className="communications-body_search-results">
          <SearchResultsTable
            mountContext="search"
            contentContextMenu={ {
              showAddPostButton: false,
              showViewMatchingPostsButton: true,
              showViewProfileButton: true,
            } }
          />
        </div>
      </div>
    </div>
  )
}
