import React, { useEffect } from "react"
import { useParams } from "react-router-dom"

import "./style.sass"
import { useDispatch } from "../../state/hooks"
import { fetchCampaignReportMetricDefinitions, fetchPublicCampaignReport } from "../../state/publicCampaignReport"
import PublicCampaignReport from "../../component/PublicCampaignReport"

export default function PublicCampaignReportPage() {
  const dispatch = useDispatch()
  const { reportCode } = useParams()

  useEffect(() => {
    if (!reportCode) return
    dispatch(fetchPublicCampaignReport({ code: reportCode }))
    dispatch(fetchCampaignReportMetricDefinitions())
  }, [ reportCode ])

  return (
    <div className="cp_public-campaign-report">
      <div className="cp_public-campaign-report-container">
        <PublicCampaignReport />
      </div>
    </div>
  )
}
