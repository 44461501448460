import React, { JSX } from "react"
import { LockOutlined } from "@mui/icons-material"
import { Tooltip } from "@mui/material"
import "./style.sass"

interface OauthedIconProps {
  tooltipText: string;
  oauthed?: boolean;
}

function OauthedIcon({
  tooltipText,
  oauthed,
}: OauthedIconProps): JSX.Element | null {
  if (!oauthed) return null

  return (
    <Tooltip arrow={ true } placement="top" title={ tooltipText }>
      <LockOutlined id="cp_component_adornments-oauthed-icon" />
    </Tooltip>
  )
}

export default OauthedIcon
