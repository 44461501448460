import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

// PermissionsToggle Slice Interface and Initial State
export interface PermissionsToggleState {
  permissions: {
    "finalize-feedback": boolean
    "approve-only": boolean
    "view-leave-feedback": boolean
  }
}

const initialState: PermissionsToggleState = {
  permissions: {
    "finalize-feedback": false,
    "approve-only": false,
    "view-leave-feedback": false,
  },
}

// PermissionsToggle Slice
export const PermissionsToggleSlice = createSlice({
  name: "PermissionsToggleSlice",
  initialState,
  reducers: {
    setPermissions: (
      state,
      action: PayloadAction<{
        permissions: PermissionsToggleState["permissions"]
      }>,
    ) => ({
      ...state,
      permissions: action.payload.permissions,
    }),
    togglePermission: (
      state,
      action: PayloadAction<keyof PermissionsToggleState["permissions"]>,
    ) => {
      const newPermissions = { ...state.permissions }
      const toggledPermission = action.payload

      switch (toggledPermission) {
        case "finalize-feedback":
          newPermissions["finalize-feedback"] = !newPermissions["finalize-feedback"]
          if (newPermissions["finalize-feedback"]) {
            newPermissions["approve-only"] = true
            newPermissions["view-leave-feedback"] = true
          }
          break
        case "approve-only":
          newPermissions["approve-only"] = !newPermissions["approve-only"]
          if (newPermissions["approve-only"]) {
            newPermissions["view-leave-feedback"] = true
          }
          break
        case "view-leave-feedback":
          newPermissions["view-leave-feedback"] = !newPermissions["view-leave-feedback"]
          break
        default:
          break
      }

      return {
        ...state,
        permissions: newPermissions,
      }
    },

    resetPermissions: (state) => ({
      ...state,
      permissions: initialState.permissions,
    }),
  },
})

export const {
  setPermissions,
  togglePermission,
  resetPermissions,
} = PermissionsToggleSlice.actions

export default PermissionsToggleSlice.reducer
