import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Container } from "@mui/material"
import { isNumber } from "highcharts"
import * as GraphQL from "../../../../graphql"
import {
  IsToggleEnabled,
  commifyNumber,
  formatMetricValue,
  getToggleSettings,
} from "./reportingHelper"
import NetworkIconButton from "../../../NetworkButton"
import { postDateFormatter } from "../../../../util/miscHelper"

interface Props {
  deliverable: GraphQL.DeliverableOverviewFragment
  network: string
  toggles: GraphQL.CampaignReportToggleSetting[]
}

type EngagmentNum = number | null | undefined
type EngagmentStr = string | null | undefined

interface Engagments {
  views?: EngagmentNum
  viewsOrganic?: EngagmentNum
  viewsPaid?: EngagmentNum
  engagementRate?: EngagmentNum
  viewEngagementRate?: EngagmentNum
  staticEngagementRate?: EngagmentNum
  comments?: number | null
  likes?: number | null
  shares?: EngagmentNum
  saves?: EngagmentNum
  reach?: EngagmentNum
  impressions?: EngagmentNum
  total?: number | null
}

interface Url {
  __typename?: "Url"
  address: string
}

interface Media {
  __typename?: "Media"
  url: Url
}

interface DeliverableMedia {
  __typename?: "DeliverableMedia"
  accepted: boolean
  media: Media
}

function getMediaUrl(media: DeliverableMedia[] | undefined) {
  if (!media || media.length === 0) return null
  const acceptedMedia = media.filter((del) => del.accepted === true)
  return acceptedMedia[0].media.url.address || null
}

interface RenderEngagStat {
  engagment: EngagmentNum,
  operation: string,
  info: string,
}

function RenderEngagementStat(
  {
    engagment,
    operation,
    info,
  }: RenderEngagStat,
) {
  const shouldRender = !!isNumber(engagment)
  switch (operation) {
    case "commifyNumber":
      return (
        shouldRender
          ? <p key={ info }>{ `${ commifyNumber(engagment || 0) } ${ info }` }</p>
          : null
      )
    case "formatPercentUnclamped2":
      return (
        shouldRender
          ? <p key={ info }>{ `${ formatMetricValue((engagment || 0), "formatPercentUnclamped2") } ${ info }` }</p>
          : null
      )
    default:
      return null
  }
}

interface Post {
  id?: string // deliverable id (identifier)
  username?: string
  followerCount?: EngagmentNum
  timePosted?: any // UnixTimestamp Scalar type { input: any output: any }
  lastUpdated?: any // UnixTimestamp Scalar type { input: any output: any }
  datePosted?: EngagmentStr
  network?: GraphQL.Network
  text?: EngagmentStr
  mediaurl?: EngagmentStr
  engagements?: Engagments
}

export default function PostCard({
  deliverable, network, toggles,
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignReporting" })
  const [ isHovered, setIsHovered ] = useState<boolean>(false)
  const mappedSettings = getToggleSettings(toggles)
  const isToggleEnabled = new IsToggleEnabled(mappedSettings, network)
  const firstPost = deliverable.postTracker?.posts?.[0]
  const { socialAccount } = deliverable.campaignNetworkAccount
  const { nativeInsights: insights } = deliverable
  const engagments: Engagments = {}
  if (insights) {
    engagments.views = isToggleEnabled.showViews() ? insights.views : null
    engagments.viewsOrganic = isToggleEnabled.showViewsOrganic() ? insights.viewsOrganic : null
    engagments.viewsPaid = isToggleEnabled.showViewsPaid() ? insights.viewsPaid : null
    engagments.engagementRate = isToggleEnabled.showEr() ? insights.engagementRate : null
    engagments.viewEngagementRate = isToggleEnabled.showVer() ? insights.viewEngagementRate : null
    engagments.staticEngagementRate = isToggleEnabled.showSer() ? insights.staticEngagementRate : null
    engagments.comments = isToggleEnabled.showComments() ? insights.comments : null
    engagments.likes = isToggleEnabled.showLikes() ? insights.upVotes : null
    engagments.shares = isToggleEnabled.showShares() ? insights.shares : null
    engagments.saves = isToggleEnabled.showSaves() ? insights.saves : null
    engagments.reach = isToggleEnabled.showReach() ? insights.reach : null
    engagments.impressions = isToggleEnabled.showImpressions() ? insights.impressions : null
    engagments.total = isToggleEnabled.showTotalEngagements() ? insights.totalEngagements : null
  }

  const post: Post = {}
  post.id = deliverable.id
  post.username = socialAccount.userName
  post.followerCount = isToggleEnabled.showGrossImpressions()
    ? (firstPost ? (insights?.followerCount || null) : (deliverable.metrics?.followerCount || (insights?.followerCount || null)))
    : null
  post.timePosted = insights?.postCreated
  post.datePosted = firstPost
    ? (deliverable.metrics?.postedDate || null)
    : (deliverable.metrics?.formattedPostedDate || null)
  post.lastUpdated = insights?.lastUpdated
  post.network = socialAccount.network as GraphQL.Network
  post.text = firstPost ? firstPost.text : deliverable.deliverableCaption?.text
  post.mediaurl = (firstPost && firstPost.mediaUrl) ? firstPost.mediaUrl : getMediaUrl(deliverable.deliverableMedia)
  post.engagements = engagments

  const isOrganic = post.network === GraphQL.Network.Instagram
  const organicSuffix = isOrganic ? translate("(Organic)") : ""
  const engagementStats = [
    {
      engagment: post.followerCount,
      operation: "commifyNumber",
      info: translate("Gross Imp."),
    },
    {
      engagment: post.engagements ? post.engagements.impressions : null,
      operation: "commifyNumber",
      info: `${ translate("True Imp.") } ${ organicSuffix }`,
    },
    {
      engagment: post.engagements ? post.engagements.reach : null,
      operation: "commifyNumber",
      info: `${ translate("Reach") } ${ organicSuffix }`,
    },
    {
      engagment: post.engagements ? post.engagements.views : null,
      operation: "commifyNumber",
      info: `${ translate("Views") } ${ organicSuffix }`,
    },
    {
      engagment: post.engagements ? post.engagements.viewsOrganic : null,
      operation: "commifyNumber",
      info: translate("Views (Organic)"),
    },
    {
      engagment: post.engagements ? post.engagements.viewsPaid : null,
      operation: "commifyNumber",
      info: translate("Views (Paid)"),
    },
    {
      engagment: post.engagements ? post.engagements.engagementRate : null,
      operation: "formatPercentUnclamped2",
      info: "ER",
    },
    {
      engagment: post.engagements ? post.engagements.viewEngagementRate : null,
      operation: "formatPercentUnclamped2",
      info: "VER",
    },
    {
      engagment: post.engagements ? post.engagements.staticEngagementRate : null,
      operation: "formatPercentUnclamped2",
      info: "SER",
    },
    {
      engagment: post.engagements ? post.engagements.comments : null,
      operation: "commifyNumber",
      info: `${ translate("Comments") } ${ organicSuffix }`,
    },
    {
      engagment: post.engagements ? post.engagements.likes : null,
      operation: "commifyNumber",
      info: `${ translate("Likes") } ${ organicSuffix }`,
    },
    {
      engagment: post.engagements ? post.engagements.shares : null,
      operation: "commifyNumber",
      info: `${ translate("Shares") } ${ organicSuffix }`,
    },
    {
      engagment: post.engagements ? post.engagements.saves : null,
      operation: "commifyNumber",
      info: `${ translate("Saves") } ${ organicSuffix }`,
    },
    {
      engagment: post.engagements ? post.engagements.total : null,
      operation: "commifyNumber",
      info: `${ translate("Total Eng.") } ${ organicSuffix }`,
    },

  ]

  const toggleCardHover = (state: boolean) => {
    setIsHovered(state)
  }

  const handleCardRedirect = () => {
    const { liveStatusUrl } = deliverable
    if (liveStatusUrl && liveStatusUrl.address) {
      window.open(liveStatusUrl.address, "_blank", "noopener,noreferrer")
    }
  }

  return (
    <div
      className={ `cp_campaign-report_post-card ${ isHovered ? "show-caption" : "" }` }
      data-post-id={ deliverable.id }
      onMouseEnter={ () => toggleCardHover(true) }
      onMouseLeave={ () => toggleCardHover(false) }
      onClick={ handleCardRedirect }
      tabIndex={ 0 }
      onKeyDown={ (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter" || event.key === " ") {
          handleCardRedirect()
        }
      } }
      role="button"
    >
      <div className="post-card_media">
        { post.mediaurl && (
        <img src={ post.mediaurl } alt="" className="post-card_media-img" />
        ) }
        <div className="post-card_media-captions">
          <p className="captions-date_posted">{ postDateFormatter(post.timePosted) }</p>
          <p className="caption">{ post.text }</p>
        </div>
      </div>
      <div className="post-card_content">
        <Container className="cp_component_search-filter-network-container network-icon">
          { post.network && (
            <NetworkIconButton
              key={ post.network }
              network={ post.network }
              isActive={ true }
              additionalClasses="cp_component_search-filter-network-filter-button"
              onClick={ () => null }
            />
          ) }
        </Container>
        <h3 className="post-card_content-username">{ post.username }</h3>
        <div className="post-card_content-engaments">
          <div className="engagement-stats">
            <div>
              { engagementStats && engagementStats.map((engagment) => RenderEngagementStat(engagment)) }
            </div>
          </div>
          <p className="post-card_content-date_last_updated">
            { post.lastUpdated ? `Last Updated ${ postDateFormatter(post.lastUpdated) }` : "" }
          </p>
        </div>
      </div>
    </div>
  )
}
