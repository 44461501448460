import React, {
  useCallback,
  useEffect,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { EMPTY_STATE_BOX_HEIGHT as boxHeight } from "../../util/constant"

import "./communications-messages.sass"

interface Props {
  parentIdName: string
}

export default function CommunicationMessagesEmpty({ parentIdName }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CommunicationsMessages" })
  const [ numberOfBoxes, setNumberOfBoxes ] = useState<number>(0)

  const calculateNumberOfBoxes = useCallback(() => {
    const parentContainer = document.getElementById(parentIdName)
    if (parentContainer) {
      const parentHeight = parentContainer.offsetHeight // Get dynamic height of the parent
      const boxes = Math.floor(parentHeight / boxHeight) + 1 // Calculate how many boxes fit
      setNumberOfBoxes(boxes)
    }
  }, [ boxHeight ])

  useEffect(() => {
    calculateNumberOfBoxes() // Initial calculation on mount

    // Add resize event listener to recalculate on window resize
    window.addEventListener("resize", calculateNumberOfBoxes)

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("resize", calculateNumberOfBoxes)
    }
  }, [ boxHeight ])

  return (
    <div className="cp_comm-messages-empty">
      <p>{ translate("No conversation selected") }</p>
      { Array.from({ length: numberOfBoxes }, (_, index) => (
        <div
          key={ index }
          className="cp_ghost-box"
        />
      )) }
    </div>
  )
}
