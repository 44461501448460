import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"
import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import { Status } from "../../util/types"

export interface commEmailListView {
  conversations: Status<GraphQL.SearchConversationsQuery>,
  batches: Status<GraphQL.SearchConversationsQuery>,
  page: number,
  communicationMessages: Status<GraphQL.GetConversationQuery>,
  replyConversation: Status<GraphQL.ReplyToConversationMutation | null>,
  activeConversation: string | null,
}

// Initialize state
const initialState: commEmailListView = {
  conversations: "init",
  batches: "init",
  page: 1,
  communicationMessages: "init",
  replyConversation: "init",
  activeConversation: null,
}

export const commEmailListViewSlice = createSlice({
  name: "commEmailListView",
  initialState,
  reducers: {
    setConversations: (
      state,
      action: PayloadAction<Status<GraphQL.SearchConversationsQuery>>,
    ) => ({
      ...state,
      conversations: action.payload,
    }),
    resetConversations: (state) => ({ ...state, conversations: "init" }),
    setBatches: (
      state,
      action: PayloadAction<Status<GraphQL.SearchConversationsQuery>>,
    ) => ({
      ...state,
      batches: action.payload,
    }),
    setPage: (
      state,
    ) => ({
      ...state,
      page: state.page + 1,
    }),
    setPageValue: (state, action: PayloadAction<number>) => ({
      ...state,
      page: action.payload,
    }),
    resetPage: (state) => ({ ...state, page: 1 }),
    apendConversations: (
      state,
      action: PayloadAction<Status<GraphQL.SearchConversationsQuery>>,
    ) => ({
      ...state,
      conversations: action.payload,
    }),
    setCommMessages: (
      state,
      action: PayloadAction<Status<GraphQL.GetConversationQuery>>,
    ) => ({
      ...state,
      communicationMessages: action.payload,
    }),
    resetCommMessages: (state) => ({ ...state, communicationMessages: "init" }),
    resetListViewState: () => initialState,
    setReplyToConversation: (
      state,
      action: PayloadAction<Status<GraphQL.ReplyToConversationMutation | null>>,
    ) => ({
      ...state,
      replyConversation: action.payload,
    }),
    resetReplyToConversation: (state) => ({ ...state, replyConversation: "init" }),
    setActiveConversation: (state, actions) => ({
      ...state,
      activeConversation: actions.payload,
    }),
  },
})

export const {
  setConversations,
  resetConversations,
  setPage,
  resetPage,
  setCommMessages,
  resetCommMessages,
  resetListViewState,
  setPageValue,
  setReplyToConversation,
  resetReplyToConversation,
  setActiveConversation,
  setBatches,
} = commEmailListViewSlice.actions
export default commEmailListViewSlice.reducer

// Add Accounts to Lists Modal Slice Thunks
export const fetchEmailsList = (
  params: GraphQL.SearchConversationsQueryVariables,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  if (params.filter === "batch" && params.batchId === null) dispatch(setBatches("loading"))
  else dispatch(setConversations("loading"))

  const emailListResults = await API.fetchConversations(params)

  if (params.filter === "batch" && params.batchId === null) dispatch(setBatches(emailListResults))
  else dispatch(setConversations(emailListResults))
}

export const fetchConversationThread = (
  id: string,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setCommMessages("loading"))

  const conversations = await API.getConversationMessages(id)

  dispatch(setCommMessages(conversations))
}

export const replyToConversation = (
  params: GraphQL.ReplyToConversationMutationVariables,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setReplyToConversation("loading"))

  const reply = await API.replyToConversation(params)

  dispatch(setReplyToConversation(reply))
}
