import React, { useEffect, useState } from "react"
import { Search } from "@mui/icons-material"
import {
  Autocomplete,
  createFilterOptions,
  debounce,
  FilterOptionsState,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material"
import { useTranslation } from "react-i18next"

import { useParams } from "react-router-dom"
import * as GraphQL from "../../../../graphql"
import * as API from "../../../../util/apiClient"
import { DEFAULT_DEBOUNCE_WAIT } from "../../../../util/constant"
import Input, { Props as InputProps } from "../../../Input"
import LoadingIndicator from "../../../LoadingIndicator"

import "./customer-autocomplete.sass"

type Customer = GraphQL.CustomerFragment;

interface Props {
  setSelectedCustomer: (customer: Customer | null) => void;
  selectedCustomer: Customer | null;
}

function CustomersAutocomplete({
  setSelectedCustomer,
  selectedCustomer,
}: Props) {
  const [ customers, setCustomers ] = useState<Customer[]>([])
  const [ customersLoading, setCustomersLoading ] = useState(false)
  const { campaignID } = useParams()
  const { t: translate } = useTranslation([], { keyPrefix: "component.AssignReviewersModal" })

  const searchCustomers = async () => {
    const result = await API.fetchCustomerByCampaign({
      campaignId: campaignID as string,
    })

    if (API.isSuccess(result)) {
      setCustomers(result.payload.campaign.customers)
    } else {
      setCustomers([])
    }
  }

  const debouncedSearchCustomers = debounce(async () => {
    setCustomersLoading(true)
    await searchCustomers()
    setCustomersLoading(false)
  }, DEFAULT_DEBOUNCE_WAIT)

  const filter = createFilterOptions<Customer>()

  const autoCompleteFilter = (
    options: Customer[],
    params: FilterOptionsState<Customer>,
  ) => filter(options, params)

  useEffect(() => {
    searchCustomers()
  }, [])

  return (
    <Autocomplete
      value={ selectedCustomer }
      onChange={ (event, newValue) => {
        setSelectedCustomer(newValue)
      } }
      id="customer-autocomplete"
      filterOptions={ autoCompleteFilter }
      selectOnFocus={ true }
      clearOnBlur={ true }
      handleHomeEndKeys={ true }
      options={ customers }
      getOptionLabel={ (option) => option.vanity }
      renderOption={ (props, customer) => (
        <ListItem { ...props } key={ customer.id } className="list-item-content">
          <ListItemIcon>
            { customer.company?.logo ? (
              <img
                src={ customer.company.logo.url.address }
                alt={ customer.company?.name || "" }
                className="list-item-content__icon"
              />
            ) : (
              <div className="list-item-content__icon list-item-content__icon--placeholder" />
            ) }
          </ListItemIcon>
          <ListItemText
            primary={ (
              <span className="list-item-content__text">
                { customer.vanity }
                { customer.company?.name && (
                  <span>
                    (
                    { customer.company.name }
                    )
                  </span>
                ) }
                { ` | User Count: ${ customer.userCount }` }
              </span>
            ) }
          />
        </ListItem>
      ) }
      renderInput={ (params) => (
        <Input
          { ...(params as InputProps) }
          placeholder={ translate("Select a Customer") }
          InputProps={ {
            ...params.InputProps,
            startAdornment: <Search />,
          } }
        />
      ) }
      onInputChange={ debouncedSearchCustomers }
      loading={ customersLoading }
      loadingText={ <LoadingIndicator size={ 20 } /> }
      className="cp_component_autocomplete_lists"
      ListboxProps={ {
        className: "cp_component_autocomplete_lists",
      } }
    />
  )
}

export default CustomersAutocomplete
