import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { v4 as uuidv4 } from "uuid"
import "./style.sass"
import {
  Step,
  StepLabel,
  Stepper,
} from "@mui/material"
import { Link, useParams } from "react-router-dom"
import Modal from "../Modal"
import { useDispatch, useSelector } from "../../state/hooks"
import LoadingIndicator from "../LoadingIndicator"
import {
  closeModalCreateDeliverable,
  createDeliverable,
  setActiveStep,
} from "../../state/ModalCreateDeliverable"
import NameDeliverableInputStep from "./NameDeliverableInputStep"
import Divider from "../Divider"
import SelectCaptionInputStep from "./SelectCaptionInputStep"
import SelectMediaInputStep from "./SelectMediaInputStep"
import ReviewAndCreateStep from "./ReviewAndCreateStep"
import { pushToast } from "../../state/toastSlice"

export default function ModalCreateDeliverable() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalCreateDeliverable" })
  const { t: tCommon } = useTranslation([], { keyPrefix: "common" })

  const {
    open,
    activeStep,
    deliverableTitle,
    stepsCompleted,
    caption,
    selectedMedia,
    conversation,
    clientNote,
  } = useSelector(({ modalCreateDeliverable }) => modalCreateDeliverable)
  const [ submitLoading, setSubmitLoading ] = useState(false)
  const dispatch = useDispatch()
  const { vanity } = useParams()

  const steps = useMemo(() => [
    { label: translate("Name Deliverable"), key: uuidv4() },
    { label: translate("Select Caption"), key: uuidv4() },
    { label: translate("Select Media"), key: uuidv4() },
    { label: translate("Review Deliverable"), key: uuidv4() },
  ], [ translate ])

  const handleClose = () => {
    dispatch(closeModalCreateDeliverable())
  }

  const handleSubmit = async () => {
    if (
      activeStep === 4
      && conversation
      && conversation.campaignNetworkAccount
      && selectedMedia
    ) {
      setSubmitLoading(true)

      await dispatch(createDeliverable({
        deliverableVariables: {
          name: deliverableTitle,
          note: clientNote,
          text: caption,
          threadId: conversation.id,
          campaignNetworkAccountId: conversation.campaignNetworkAccount.id,
        },
        fileVariables: {
          files: selectedMedia.map((media) => ({
            filename: media.name,
            mimeType: media.content.mimeType,
            originalUrl: media.content.url,
            thumbnailUrl: media.thumbnail?.url || "",
          })),
        },
        onSuccess: (campaignId, deliverableId) => {
          dispatch(pushToast({
            message: translate("Deliverable is successfully created!"),
            type: "success",
            additionalInfo: (
              <Link
                target="_blank"
                className="cp-toast-link"
                to={ `/${ vanity }/campaigns/${ campaignId }/deliverables?deliverableId=${ deliverableId }` }
              >
                { translate("View Deliverable") }
              </Link>),
          }))
        },
        onError: () => {
          dispatch(pushToast({
            message: tCommon("Error!"),
            type: "error",
            additionalInfo: translate("Sorry, the Deliverable couldn’t be created."),
          }))
        },
      }))

      setSubmitLoading(false)
      handleClose()
    } else {
      dispatch(setActiveStep(activeStep + 1))
    }
  }

  const getPrimaryLabel = () => {
    if (submitLoading) return <LoadingIndicator />
    if (activeStep === 4) return translate("CREATE DELIVERABLE")
    return translate("Next")
  }

  const getStepContent = () => {
    switch (activeStep) {
      case 1:
        return <NameDeliverableInputStep />
      case 2:
        return <SelectCaptionInputStep />
      case 3:
        return <SelectMediaInputStep />
      case 4:
        return <ReviewAndCreateStep />
      default:
        return null
    }
  }

  const getPrimaryButtonDisabledState = () => {
    if (submitLoading) return true
    switch (activeStep) {
      case 1:
        return deliverableTitle.length === 0
      case 2:
        return caption.length === 0
      case 3:
        return !selectedMedia
      case 4:
        return deliverableTitle.length === 0 || caption.length === 0 || !selectedMedia

      default:
        return true
    }
  }

  return (
    <Modal
      className="cp_component_modal-create-deliverable"
      title={ translate("Create Deliverable") }
      subtitle={ `${ translate("Step") } ${ activeStep } - ${ steps[activeStep - 1].label }` }
      primaryLabel={ getPrimaryLabel() }
      secondaryLabel={ tCommon("Cancel") }
      primaryAction={ handleSubmit }
      secondaryAction={ handleClose }
      closeAction={ handleClose }
      open={ open }
      disabled={ getPrimaryButtonDisabledState() }
      secondaryDisabled={ submitLoading }
      maxWidth="lg"
    >
      <Stepper
        nonLinear={ true }
        alternativeLabel={ true }
        // Stepper component 0 indexes step numbers
        activeStep={ activeStep - 1 }
      >
        { steps.map((step, idx) => {
          let className = ""
          if (stepsCompleted.includes(idx + 1)) className = "step-completed"
          else if (activeStep === idx + 1) className = "step-active"
          return (
            <Step onClick={ () => dispatch(setActiveStep(idx + 1)) } className={ className } key={ step.key }>
              <StepLabel>{ step.label }</StepLabel>
            </Step>
          )
        }) }
      </Stepper>
      <Divider />
      <div className="content-container">
        { getStepContent() }
      </div>
    </Modal>
  )
}
