import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

export interface AssignReviewersModal {
  modalOpen: boolean
}

const initialState: AssignReviewersModal = {
  modalOpen: false,
}

export const AssignReviewersModalSlice = createSlice({
  name: "AssignReviewersModalSlice",
  initialState,
  reducers: {
    setModalOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      modalOpen: action.payload,
    }),
  },
})

export const {
  setModalOpen,
} = AssignReviewersModalSlice.actions
export default AssignReviewersModalSlice.reducer
