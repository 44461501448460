import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { UploadFileRounded, Reply } from "@mui/icons-material"
import { Container } from "@mui/material"

import { useDispatch, useSelector } from "../../state/hooks"
import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import LoadingIndicatorCard from "../LoadingIndicatorCard"
import CommunicationMessagesEmpty from "./CommunicationMessagesEmpty"
import ReplyToConversation from "./ReplyToConversation"
import { CommMessage, ConversationUploads } from "./communicationMessage"
import { Scope } from "../../util/types"
import { openModalCreateDeliverable } from "../../state/ModalCreateDeliverable"
import ErrorHandler from "../ErrorHandler"
import Button from "../Button"
import "./communications-reading-panel-view.sass"
import CommunicationBatchEmpty from "./CommunicationBatchEmpty"
// eslint-disable-next-line max-len
import { openNoUserAssociatedWithCampaignModalOpen, setNoCampaignAssociatedWarningOpen } from "../../state/ModalCreateDeliverableRequirements"

interface ConversationData {
  hasMessages: boolean,
  messages: GraphQL.GetConversationQuery["getConversation"]["conversationThread"]["timeline"],
  subject: GraphQL.GetConversationQuery["getConversation"]["subject"],
  socialAccount: GraphQL.GetConversationQuery["getConversation"]["socialAccount"] | null,
}

type Props = {
  isBatch?: boolean
}

export default function CommunicationsMessages({ isBatch = false }: Props) {
  const { communicationMessages } = useSelector(({ commEmailListViewSlice }) => commEmailListViewSlice)
  const { scopes } = useSelector(({ user }) => user)
  const [ showReplay, setShowReplay ] = React.useState(false)
  const dispatch = useDispatch()
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.CommunicationsMessages" })

  const mappedConversations = useMemo((): ConversationData => {
    if (API.isSuccess(communicationMessages)) {
      return {
        hasMessages: true,
        messages: communicationMessages.payload.getConversation.conversationThread.timeline,
        subject: communicationMessages.payload.getConversation.subject,
        socialAccount: communicationMessages.payload.getConversation.socialAccount,
      }
    }
    return {
      hasMessages: false,
      messages: [],
      subject: "",
      socialAccount: null,
    }
  }, [ communicationMessages ])

  if (communicationMessages === "loading") {
    return (
      <div className="communications-email-list-view loading">
        <LoadingIndicatorCard />
      </div>
    )
  }

  if (isBatch) {
    return (
      <div id="cp_comm-messages-empty" className="communications-email-list-view">
        <CommunicationBatchEmpty parentIdName="cp_comm-messages-empty" />
      </div>
    )
  }

  if (communicationMessages === "init" || mappedConversations.hasMessages === false) {
    return (
      <div id="cp_comm-messages-empty" className="communications-email-list-view">
        <CommunicationMessagesEmpty parentIdName="cp_comm-messages-empty" />
      </div>
    )
  }

  if (communicationMessages.status === "error") return <ErrorHandler />

  const handleNewDeliverableClick = () => {
    const {
      campaignNetworkAccount,
      communicationGroup: {
        campaign,
      },
      socialAccount,
    } = communicationMessages.payload.getConversation

    // If the communication is not associated with a campaign
    if (!campaign) {
      dispatch(setNoCampaignAssociatedWarningOpen(true))
      return
    }
    // If the social account is not associated with the campaign
    if (!campaignNetworkAccount) {
      dispatch(openNoUserAssociatedWithCampaignModalOpen({
        user: {
          id: socialAccount.id,
          name: socialAccount.userName,
        },
        campaign: {
          id: campaign.id,
          name: campaign.name,
        },
      }))
      return
    }
    dispatch(openModalCreateDeliverable({ conversation: communicationMessages.payload.getConversation }))
  }

  return (
    <div className="communications-email-list-view cp_communications-thread cp_ct">
      <div className="cp_ct-thread-header">
        { scopes.includes(Scope.CAMPAIGN_MANAGEMENT) && (
          <Container
            id="cp_component_comms-messages_create-new-deliverable-button"
            onClick={ handleNewDeliverableClick }
            className="create-new-deliverable-button"
          >
            <>
              <UploadFileRounded />
              <p>{ translate("Create New Deliverable") }</p>
            </>
          </Container>
        ) }
      </div>
      <h2 className="cp_ct-subject">{ mappedConversations.subject }</h2>
      { mappedConversations.hasMessages && (
        mappedConversations.messages
          .slice()
          .reverse()
          .map((message, indx) => {
            if (message.__typename === "ConversationMessage") {
              return (
                <CommMessage
                  key={ message.id }
                  message={ message }
                  socialAccount={ mappedConversations.socialAccount }
                  indx={ indx }
                  totalMessages={ mappedConversations.messages.length }
                />
              )
            }
            return (
              <ConversationUploads
                key={ message.uuid }
                message={ message }
                socialAccount={ mappedConversations.socialAccount }
              />
            )
          })
      ) }
      <div className="cp_ct-message-replay">
        { (scopes.includes(Scope.COMMUNICATIONS_MANAGEMENT) && mappedConversations.hasMessages && !showReplay) && (
          <div className="cp_ct-message-replay-button-section">
            <Button
              id="cp_ct-message-replay-form-actions-reply"
              isPrimary={ false }
              startIcon={ <Reply /> }
              onClick={ () => setShowReplay(true) }
              label={ translate("REPLY") }
            />
          </div>
        ) }
        { showReplay && (
          <ReplyToConversation handleCancel={ setShowReplay } />
        ) }
      </div>
    </div>
  )
}
