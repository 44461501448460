import React, { JSX } from "react"
import { useTranslation } from "react-i18next"
import PlayArrow from "@mui/icons-material/PlayArrowRounded"

import { NSFWLabelWTooltip } from "../IconNSFW"
import { Props as SocialAvatarProps } from "../SocialAvatar"
import * as GraphQL from "../../graphql"

import "./mediaCellStyle.sass"

type Props = {
  media: GraphQL.PostMedia[],
  socialAvatarProps: SocialAvatarProps,
}

export default function MediaCell(props: Props): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.MediaCell" })

  if (props.media.length === 0) {
    return (<p>{ translate("dashdash") }</p>)
  }

  const displayMedia = props.media[0]

  return (
    <div className="cp_component_search-results-media-cell">
      <aside
        className={ props.socialAvatarProps.isNSFW ? "is-nsfw" : "" }
        style={ {
          backgroundImage: `url("${ displayMedia.thumbnailUrl }")`,
        } }
      >
        { displayMedia.mediaType === "image" ? null : <PlayArrow /> }
      </aside>
      <NSFWLabelWTooltip
        isNSFW={ props.socialAvatarProps.isNSFW }
        labelClass="light"
      />
    </div>
  )
}
