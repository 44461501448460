import React, { JSX, useEffect } from "react"
import { useTranslation } from "react-i18next"
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from "react-router-dom"
import { FullStory } from "@fullstory/browser"
import dayjs from "dayjs"

import * as API from "../util/apiClient"
import * as Constant from "../util/constant"
import AdminLogin from "../page/AdminLogin"
import AppLandingPage from "../page/AppLanding"
import Campaign from "../page/Campaign"
import CurrentUserLoaderGuard from "../guards/currentUserLoaderGuard"
import CustomerExistsGuard from "../guards/customerExistsGuard"
import Dashboard from "../page/Dashboard"
import EmailLogin from "../page/EmailLogin"
import ErrorPage from "../page/Error"
import ErrorPublic from "../page/ErrorPublic"
import ForgotPassword from "../page/ForgotPassword"
import GraphQLSandbox from "../page/GraphQLSandbox"
import List from "../page/List"
import ListGroup from "../page/ListGroup"
import ListSocialProfile from "../page/ListSocialProfile"
import LoadingPage from "../page/Loading"
import Login from "../page/Login"
import Logout from "../page/Logout"
import PostLogin from "../page/PostLogin"
import PublicCampaignReport from "../page/PublicCampaignReport"
import PublicList from "../page/PublicList"
import PublicListGroup from "../page/PublicListGroup"
import Search from "../page/Search"
import SearchAI from "../page/SearchAI"
import SocialProfile from "../page/SocialProfile"
import Team from "../page/Team"
import UserIsAuthenticatedGuard from "../guards/userIsAuthenticatedGuard"
import SocialTracker from "../page/SocialTracker"
import {
  Scope,
  RootRoute,
  RadiusRouteObject,
} from "../util/types"
import { useSelector } from "../state/hooks"
import Customers from "../page/Customers"
import CustomerUser from "../page/CustomerUser"
import Communications from "../page/Communications"
import ScopesGuard from "../guards/ScopesGuard"

import defaults from "../config/defaults"

function NotFoundPage(): JSX.Element {
  const { user: currentUser, scopes } = useSelector(({ user }) => user)
  const { t: translate } = useTranslation([], { keyPrefix: "common" })

  useEffect(() => {
    FullStory("trackEvent", {
      name: "404",
      properties: {
        uid: API.isSuccess(currentUser)
          ? currentUser.payload.currentUser?.id
          : undefined,
        username: API.isSuccess(currentUser)
          ? currentUser.payload.currentUser?.username
          : undefined,
        organization: API.isSuccess(currentUser)
          ? currentUser.payload.currentUser?.customer.vanity
          : undefined,
        date: dayjs().format(Constant.LONGFORM_DATE_TIME),
      },
    })
  }, [ currentUser ])

  if (currentUser === "loading") {
    return <LoadingPage />
  }

  if (API.isSuccess(currentUser)) {
    return scopes.includes(Scope.REACT_PLATFORM) ? (
      <ErrorPage statusCode={ 404 } />
    ) : (
      <ErrorPublic
        statusCode={ 404 }
        errorMessage={ translate("Users must have the REACT_PLATFORM scope to view the Radius dashboard") }
      />
    )
  }

  return <ErrorPublic statusCode={ 404 } errorMessage={ translate("The page you were looking for doesn't exist") } />
}

const routes: RadiusRouteObject[] = [
  {
    errorElement: <ErrorPage />,
    children: [
      // REDIRECT ROUTES
      {
        index: true,
        element: <AppLandingPage />,
      },

      // UNPROTECTED ROUTES
      // TODO: Update Login Flow Pages to use React Router Outlets
      {
        // Handle Login Callback
        path: "post-login",
        element: <PostLogin />,
      },
      {
        // Handle Logout Callback
        path: "post-logout",
        element: <Navigate to="/find-team" replace={ true } />,
      },
      {
        path: "logout-page",
        element: <Logout />,
      },
      {
        // Find a Team Page
        path: "find-team",
        element: <Team />,
      },
      {
        path: "influential/login",
        element: <AdminLogin />,
      },
      {
        path: ":vanity",
        element: <CustomerExistsGuard><Outlet /></CustomerExistsGuard>,
        children: [
          {
            // Login Page
            path: "login",
            element: <Login />,
          },
          {
            // Login Via Email Page
            path: "login/email",
            element: <EmailLogin />,
          },
          {
            // Forgot Password Page
            path: "forgot-password",
            element: <ForgotPassword />,
          },
        ],
      },

      {
        // GraphQL Sandbox (Only available in dev environments)
        path: "sandbox",
        element: process.env.NODE_ENV === "production"
          ? <ErrorPage statusCode={ 404 } />
          : <GraphQLSandbox endpoint={ defaults.TIN.APP.BACKENDS.API_V1 } />,
      },

      {
        // GraphQL Search Sandbox (Only available in dev environments)
        path: "searchSandbox",
        element: process.env.NODE_ENV === "production"
          ? <ErrorPage statusCode={ 404 } />
          : <GraphQLSandbox endpoint={ defaults.TIN.APP.BACKENDS.SEARCH } />,
      },

      // PUBLIC ROUTES
      {
        path: "public",
        children: [
          {
            // Public List page
            path: "list/:listCode/:tabPath?/:contentTabPath?",
            element: <PublicList />,
          },
          {
            // Public List Group page
            path: "group/:listGroupCode",
            element: <PublicListGroup />,
          },
          {
            // Public Campaign Report page
            path: "report/campaign/:reportCode",
            element: <PublicCampaignReport />,
          },
        ],
      },

      // PROTECTED ROUTES
      {
        element: (
          <UserIsAuthenticatedGuard>
            <Outlet />
          </UserIsAuthenticatedGuard>
        ),
        children: [
          // Protected Vanity Routes
          {
            path: ":vanity",
            element: (
              <CustomerExistsGuard>
                <CurrentUserLoaderGuard>
                  <ScopesGuard>
                    <Outlet />
                  </ScopesGuard>
                </CurrentUserLoaderGuard>
              </CustomerExistsGuard>
            ),
            children: [
              {
                index: true,
                element: <Navigate to="dashboard" replace={ true } />,
              },
              {
                // Dashboard
                path: "dashboard",
                element: <Dashboard />,
              },
              {
                // List page
                path: "lists/:listID/:tabPath?",
                element: <List />,
              },
              {
                // List account page
                path: "lists/:listID/social-account/:socialProfileID/:tabPath?/:contentTabPath?",
                element: <ListSocialProfile />,
              },
              {
                // List Group page
                path: "list-groups/:listGroupID",
                element: <ListGroup />,
              },
              {
                // Search
                path: RootRoute.SEARCH_AI,
                element: <SearchAI />,
                handle: {
                  scopes: [ Scope.FEATURE_SEARCH_AI ],
                },
              },
              {
                // Search
                path: "search",
                element: <Search />,
              },
              {
                // Social Account On Search
                path: "search/:socialAccountId/:tabPath?",
                element: <SocialProfile />,
              },
              {
                // Profile
                // NOTE: Since profile module is used for both profiles and social
                // accounts, the only query parameter should be JUST the social
                // account ID. Not all social accounts are associated with a profile,
                // but profiles may be queried from social account.
                path: "profile/:socialAccountId/:tabPath?",
                element: <SocialProfile />,
              },
              {
                // Campaign
                path: "campaigns/:campaignID/:tabPath?/:adID?",
                element: <Campaign />,
              },
              {
                // Tracker
                path: "social-tracker/:trackerID?",
                element: <SocialTracker />,
              },
              {
                // Communications
                path: "communications/group/:commGroupID/conversations",
                element: <Communications to="Inbox" />,
              },
              {
                // Communications
                path: "communications/group/:commGroupID/sent",
                element: <Communications to="Sent" />,
              },
              {
                // Communications
                path: "communications/group/:commGroupID/batch",
                element: <Communications to="Batch" />,
              },
              {
                // Communications
                path: "communications/group/:commGroupID/drafts",
                element: <Communications to="Drafts" />,
              },
              {
                // Communications Group Accounts
                path: "communications/group/:commGroupID/accounts",
                element: <Communications to="GroupAccounts" />,
              },
              {
                // Communications Group Accounts Search (adding accounts)
                path: "communications/group/:commGroupID/accounts/search",
                element: <Communications to="GroupAccountSearch" />,
              },
              {
                // Customers
                path: "customer-management/:customerID?/:tabPath?",
                element: (
                  <Customers />
                ),
              },
              {
                // Customer User
                path: "customer-management/:customerID/user/:userID/campaigns",
                element: (
                  <CustomerUser />
                ),
              },
            ],
          },
        ],
      },

      {
        // 404 Page
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
]

const router = createBrowserRouter(routes)

export default function Router() {
  return <RouterProvider router={ router } />
}
