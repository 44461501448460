import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import { Status, PillLabelColor } from "../../util/types"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"

// Communication Group Page Slice Interface and Initial State
export interface CommunicationsPageState {
  communicationGroup: Status<GraphQL.GetCommunicationGroupPageQuery>,
  communicationGroupAccounts: Status<GraphQL.SearchGroupAccountsByCommGroupIdQuery>,
  groupAccountRows: GraphQL.SearchGroupAccountsByCommGroupIdQuery["searchCommunicationGroupNetworkAccount"]["rows"]
  relaodGroupAccounts: boolean,
  labelColors: PillLabelColor[],
}

const initialState: CommunicationsPageState = {
  communicationGroup: "init",
  communicationGroupAccounts: "init",
  groupAccountRows: [],
  relaodGroupAccounts: true,
  labelColors: [],
}

// Campaign Page Slice
export const CommunicationsPageSlice = createSlice({
  name: "CommunicationsPageSlice",
  initialState,
  reducers: {
    setCommunicationGroup: (
      state,
      action: PayloadAction<Status<GraphQL.GetCommunicationGroupPageQuery>>,
    ) => ({
      ...state,
      communicationGroup: action.payload,
    }),
    setGroupAccounts: (
      state,
      action: PayloadAction<Status<GraphQL.SearchGroupAccountsByCommGroupIdQuery>>,
    ) => ({
      ...state,
      communicationGroupAccounts: action.payload,
    }),
    setGroupAccountsRows: (
      state,
      action: PayloadAction<GraphQL.SearchGroupAccountsByCommGroupIdQuery["searchCommunicationGroupNetworkAccount"]["rows"]>,
    ) => ({
      ...state,
      groupAccountRows: action.payload,
    }),
    setGroupAccountReloadStatus: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      relaodGroupAccounts: action.payload,
    }),
    setLabelColors: (state, action: PayloadAction<PillLabelColor[]>) => ({
      ...state,
      labelColors: action.payload,
    }),
    resetGroupAccounts: (state) => ({
      ...state,
      communicationGroupAccounts: "init",
    }),
  },
})

export const {
  setCommunicationGroup,
  setGroupAccounts,
  setGroupAccountsRows,
  setGroupAccountReloadStatus,
  resetGroupAccounts,
  setLabelColors,
} = CommunicationsPageSlice.actions
export default CommunicationsPageSlice.reducer

export const getCommunicationGroup = (
  variables: GraphQL.GetCommunicationGroupQueryVariables,
) => async (dispatch: Dispatch) => {
  dispatch(setCommunicationGroup("loading"))

  const result = await API.getCommunicationGroup(variables)

  if (API.isSuccess(result)) {
    // Pull out the labels
    const labelColors: PillLabelColor[] = setLabelColorsForState(result.payload.communicationGroup.labels)

    // Set them in state
    dispatch(setLabelColors(labelColors))
  }

  dispatch(setCommunicationGroup(result))
}

export const getGroupAccounts = (
  variables: GraphQL.SearchGroupAccountsByCommGroupIdQueryVariables,
) => async (dispatch: Dispatch) => {
  // Indicate that we're loading
  dispatch(setGroupAccounts("loading"))

  // Do the query
  const result = await API.searchGroupAccountsByCommGroupId(variables)

  // Set the rows
  if (API.isSuccess(result)) dispatch(setGroupAccountsRows(result.payload.searchCommunicationGroupNetworkAccount.rows))

  // Set results
  dispatch(setGroupAccounts(result))
}

export const updateCommunicationGroupLabels = (params: {
  variables: GraphQL.EditCommunicationGroupMutationVariables,
  onSuccess: () => void,
  onError: () => void,
}) => async (dispatch: Dispatch) => {
  // Set that it's loading
  dispatch(setCommunicationGroup("loading"))

  // Update the group
  await API.editCommunicationGroup(params.variables)
  const result = await API.getCommunicationGroup({ id: params.variables.communicationGroupId })

  if (API.isSuccess(result)) {
    // Pull out the labels
    const labelColors: PillLabelColor[] = setLabelColorsForState(result.payload.communicationGroup.labels)

    // Set them in state
    dispatch(setLabelColors(labelColors))

    // Call the callback function
    params.onSuccess()
  }
  if (API.isError(result)) params.onError()

  dispatch(setCommunicationGroup(result))
}

export const addLabelToMultipleSocialAccounts = (
  params: {
    vars: GraphQL.AddLabelToSocialAccountsMutationVariables,
    onSuccess: () => void,
    onError: () => void,
  },
) => async () => {
  // Call to update database
  const results = await API.addLabelToSocialAccounts(params.vars)

  // Indicate success/failure
  if (API.isSuccess(results)) params.onSuccess()
  if (API.isError(results)) params.onError()
}

export const addLabelToSocialAccount = (
  params: {
    vars: GraphQL.AddLabelToSocialAccountMutationVariables,
    onSuccess: () => void,
    onError: () => void,
  },
) => async () => {
  // Call to update database
  const results = await API.addLabelToSocialAccount(params.vars)

  // Indicate success/failure
  if (API.isSuccess(results)) params.onSuccess()
  if (API.isError(results)) params.onError()
}

export const removeLabelFromSocialAccount = (
  params: {
    vars: GraphQL.DeleteLabelFromSocialAccountMutationVariables,
    onSuccess: () => void,
    onError: () => void,
  },
) => async () => {
  // Call to remove from database
  const results = await API.deleteLabelFromSocialAccount(params.vars)

  // Indicate success/failure
  if (API.isSuccess(results)) params.onSuccess()
  if (API.isError(results)) params.onError()
}

export const addSocialAccounts = (params: {
  vars: GraphQL.AddSocialAccountsToCommunicationGroupAccountsMutationVariables,
  onSuccess: () => void,
  onError: () => void,
}) => async () => {
  // Make call to add them
  const results = await API.addSocialAccountsToCommunicatioGroupAccounts(params.vars)

  // Check the results
  if (API.isSuccess(results)) params.onSuccess()
  if (API.isError(results)) params.onError()
}

const setLabelColorsForState = (
  labels: GraphQL.GetCommunicationGroupPageQuery["communicationGroup"]["labels"],
): PillLabelColor[] => {
  // Set the colors
  const labelColors: PillLabelColor[] = []
  labels.forEach((lbl, index) => {
    let clabel: PillLabelColor
    switch (lbl.label) {
      case "contracting":
      case "opted-in":
      case "garnering content":
        clabel = {
          id: lbl.id,
          label: lbl.label,
          colorIndex: (index % 5),
          type: "Default",
        }
        break
      default:
        clabel = {
          id: lbl.id,
          label: lbl.label,
          colorIndex: (index % 5),
          type: "Custom",
        }
        break
    }

    // Add label to array
    labelColors.push(clabel)
  })

  // Return the colors
  return labelColors
}
