import React from "react"
import { useTranslation } from "react-i18next"
import { closeAssignReviewerModal, setAssignReviewerSearchInput } from "../../state/campaignDeliverableAssignReviewerModalSlice"
import Modal from "../Modal"
import { useDispatch, useSelector } from "../../state/hooks"
import SearchReviewerGroup from "./SearchReviewerGroup"
import "./style.sass"

function ModalCampaignDeliverableAssignReviewerGroup(): React.JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableAssignReviewerGroup" })

  const dispatch = useDispatch()
  const { modalOpen, selectedReviewerGroup } = useSelector(
    ({ campaignDeliverableAssignReviewerModal }) => campaignDeliverableAssignReviewerModal,
  )

  const closeModal = async () => {
    dispatch(setAssignReviewerSearchInput(""))
    dispatch(closeAssignReviewerModal())
  }

  return (
    <Modal
      title={ translate("Select Next Reviewer Group") }
      subtitle={ translate("Send Content To Next Group") }
      primaryLabel={ translate("Send To Group") }
      secondaryLabel={ translate("Cancel") }
      open={ modalOpen }
      closeAction={ closeModal }
      secondaryAction={ closeModal }
      primaryAction={ closeModal }
      maxWidth="xl"
      className="cp_component_modal-deliverable-assignreviewer"
      hasPrimaryButton={ true }
      disabled={ selectedReviewerGroup === "" }
    >
      <SearchReviewerGroup />
    </Modal>
  )
}

export default ModalCampaignDeliverableAssignReviewerGroup
