import React, { useEffect, useMemo } from "react"
import "./style.sass"

import { useParams } from "react-router-dom"
import ToastController from "../../controller/Toast"
import PageShell from "../../component/PageShell"
import { useDispatch, useSelector } from "../../state/hooks"
import { getCommunicationGroup } from "../../state/communicationsPage"
import CommunicationsOverview from "../../component/CommunicationsOverview"
import LoadingIndicator from "../../component/LoadingIndicator"
import ErrorHandler from "../../component/ErrorHandler"
import { CommsFolder } from "../../util/constant"

type Props = {
  to: CommsFolder
}

export default function Communications({
  to,
}: Props) {
  const { commGroupID } = useParams()
  const dispatch = useDispatch()
  const { communicationGroup } = useSelector(({ communicationsPage }) => communicationsPage)

  useEffect(() => {
    if (commGroupID) {
      dispatch(getCommunicationGroup({
        id: commGroupID,
      }))
    }
  }, [ commGroupID ])

  const pageShellLabel = useMemo(() => {
    if (communicationGroup === "init" || communicationGroup === "loading") return <LoadingIndicator size={ 20 } />
    if (communicationGroup.status === "error") {
      return <ErrorHandler />
    }
    return communicationGroup.payload.communicationGroup.name
  }, [ communicationGroup ])

  return (
    <div className="cp_customers_page">
      <ToastController />
      <PageShell title={ pageShellLabel } />
      <div className="cp_customers_page-container">
        <CommunicationsOverview commsBody={ to } />
      </div>
    </div>
  )
}
