import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import "./style.sass"

import { useParams } from "react-router-dom"
import ToastController from "../../controller/Toast"
import PageShell from "../../component/PageShell"
import CustomersOverview from "../../component/CustomersOverview"
import CustomerOverview from "../../component/CustomerOverview"
import { useDispatch, useSelector } from "../../state/hooks"
import { getCustomer } from "../../state/customerSlice"
import LoadingIndicatorCard from "../../component/LoadingIndicatorCard"
import ErrorHandler from "../../component/ErrorHandler"
import { Scope } from "../../util/types"
import ErrorPage from "../Error"

export default function Customers() {
  const { t: translate } = useTranslation([], { keyPrefix: "page.Customers" })

  const { customerID } = useParams()
  const { user: userStatus, scopes } = useSelector(({ user }) => user)
  const dispatch = useDispatch()

  useEffect(() => {
    if (customerID) {
      dispatch(getCustomer({ customerId: customerID }))
    }
  }, [ customerID ])

  if (userStatus === "init" || userStatus === "loading") {
    return (
      <div className="cp_customers_page">
        <LoadingIndicatorCard size={ 50 } />
      </div>
    )
  }
  if (userStatus.status === "error") {
    return <ErrorHandler />
  }

  if (!(scopes.includes(Scope.FEATURE_CUSTOMER_MANAGEMENT) || scopes.includes(Scope.USER_UPDATE))) {
    return <ErrorPage statusCode={ 404 } />
  }

  return (
    <div className="cp_customers_page">
      <ToastController />
      <PageShell title={ translate("Customers") } />
      <div className="cp_customers_page-container">
        { !customerID ? <CustomersOverview /> : <CustomerOverview /> }
      </div>
    </div>
  )
}
