import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Container } from "@mui/material"

import SearchAudienceForm from "../SearchAudienceForm"
import SearchContentForm from "../SearchContentForm"
import SearchProfileForm from "../SearchProfileForm"
import Tabs from "../Tabs"
import { Scope } from "../../util/types"
import { useSelector } from "../../state/hooks"

import "./style.sass"

function SearchFilterTabs() {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.SearchFilterTabs" })

  const [ currentTab, setCurrentTab ] = useState<number>(0)
  const scopes = useSelector(({ user }) => user.scopes)

  const handleTabChange = (newValue: number): void => {
    setCurrentTab(newValue)
  }

  // Tabs
  const filterTabs: { label: string, key: string }[] = []

  if (scopes.includes(Scope.FEATURE_SEARCH_INFLUENCER)) {
    filterTabs.push({ label: translate("Profile"), key: translate("Profile") })
  }

  if (scopes.includes(Scope.FEATURE_SEARCH_AUDIENCE)) {
    filterTabs.push({
      label: translate("Audience"),
      key: translate("Audience"),
    })
  }

  // Currently no scope for content... always show.
  filterTabs.push({ label: translate("Content"), key: translate("Content") })

  // NOTE: Because the number of tabs fluctuates depending on the scopes of
  // the user, need to use the index of the tab as opposed to static numbers
  // to determine which tab is active.
  const tabKeys = filterTabs.map(({ key }) => key)

  return (
    <Container className="cp_component_searchFilter-tabs">
      <Tabs
        tabs={ filterTabs }
        handleChange={ handleTabChange }
      />

      { scopes.includes(Scope.FEATURE_SEARCH_INFLUENCER)
        && currentTab === tabKeys.indexOf(translate("Profile"))
        && (
          <div className="form-container">
            <SearchProfileForm />
          </div>
        )
      }

      { scopes.includes(Scope.FEATURE_SEARCH_AUDIENCE)
        && currentTab === tabKeys.indexOf(translate("Audience"))
        && (
          <div className="form-container">
            <SearchAudienceForm />
          </div>
        )
      }

      { currentTab === tabKeys.indexOf(translate("Content"))
        && (
          <div className="form-container">
            <SearchContentForm />
          </div>
        )
      }
    </Container>
  )
}

export default SearchFilterTabs
