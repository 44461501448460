import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"

// Comms create template modal slice Interface and Initial State
export interface CommsCreateEditTemplateModalState {
  open: boolean,
  template?: GraphQL.CommunicationTemplateFragment
}

const initialState: CommsCreateEditTemplateModalState = {
  open: false,
  template: undefined,
}

// Campaign Page Slice
export const CommsCreateEditTemplateModal = createSlice({
  name: "CommsCreateEditTemplateModal",
  initialState,
  reducers: {
    openCommsCreateTemplate: (
      state,
      action: PayloadAction<{ template?: GraphQL.CommunicationTemplateFragment } | undefined>,
    ) => ({
      ...state,
      open: true,
      template: action.payload?.template,
    }),
    closeCommsCreateTemplate: (
      state,
    ) => ({
      ...state,
      open: false,
    }),
  },
})

export const {
  openCommsCreateTemplate,
  closeCommsCreateTemplate,
} = CommsCreateEditTemplateModal.actions
export default CommsCreateEditTemplateModal.reducer

export const createCommunicationTemplate = (
  params: {
    variables: GraphQL.CreateCommunicationTemplateMutationVariables,
    onSuccess: () => void,
    onError: () => void
  },
) => async (): Promise<void> => {
  const results = await API.createCommunicationTemplate(params.variables)

  if (results.status === "success") {
    params.onSuccess()
  } else {
    params.onError()
  }
}
export const updateCommunicationTemplate = (
  params: {
    variables: GraphQL.UpdateCommunicationTemplateMutationVariables,
    onSuccess: () => void,
    onError: () => void
  },
) => async (): Promise<void> => {
  const results = await API.updateCommunicationTemplate(params.variables)

  if (results.status === "success") {
    params.onSuccess()
  } else {
    params.onError()
  }
}
