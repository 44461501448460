import React, { useEffect, useState } from "react"
import "./style.sass"
import { useTranslation } from "react-i18next"
import {
  LabelImportant as LabelImportantIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  EditOutlined,
  Circle,
  DeleteOutlineOutlined,
} from "@mui/icons-material"
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"
import { useParams } from "react-router-dom"
import { GridRowSelectionModel } from "@mui/x-data-grid"
import { useSelector, useDispatch } from "../../../../state/hooks"
import { RootState } from "../../../../state/store"
import {
  setGroupAccountReloadStatus,
  updateCommunicationGroupLabels,
  addLabelToMultipleSocialAccounts,
  addLabelToSocialAccount,
} from "../../../../state/communicationsPage"
import Dropdown from "../../../Dropdown"
import Divider from "../../../Divider"
import Input from "../../../Input"
import * as API from "../../../../util/apiClient"
import { Toast, PillLabelColor } from "../../../../util/types"
import { pushToast } from "../../../../state/toastSlice"
import { setSelectedAccounts } from "../../../../state/searchSlice"

type GroupAccountsLabelProps = {
  rowSelections: string[]
  isSearch?: boolean
  onRowSelectionChange?: (selection: GridRowSelectionModel) => void
}

export default function GroupAccountsLabels({
  rowSelections,
  isSearch = false,
  onRowSelectionChange,
}: GroupAccountsLabelProps) {
  // ************************ Component variables ************************
  const defaultLabels: string[] = [ "contracting", "opted-in", "garnering content" ]
  const { t: translate } = useTranslation([], { keyPrefix: "component.GroupAccountsLabel" })
  const dispatch = useDispatch()
  const { commGroupID } = useParams()

  // ************************ Selectors ************************
  const {
    labelColors,
    communicationGroup: commsGroup,
    communicationGroupAccounts,
  } = useSelector((root: RootState) => root.communicationsPage)

  // ************************ Local state ************************
  const [ labels, setLabels ] = useState<PillLabelColor[]>([])
  const [ editedLabelValue, setEditedLableValue ] = useState<PillLabelColor>()

  // ************************ React hooks ************************
  useEffect(() => {
    if (API.isSuccess(commsGroup)) {
      setLabels(labelColors)
      setEditedLableValue(undefined)
    }
  }, [ commsGroup ])

  // ************************ functions ************************
  const applyLabel = (label: PillLabelColor) => {
    // Determine social account to update
    if (!commGroupID) return
    if (API.isSuccess(communicationGroupAccounts)) {
      // Pull out the rows
      const { rows: tableRows } = communicationGroupAccounts.payload.searchCommunicationGroupNetworkAccount
      if (tableRows.length === 0) return

      // Get the selected rows
      const selectedRows = tableRows.filter((row) => rowSelections.includes(row.id))
      if (!selectedRows || selectedRows.length === 0) return

      // Check to see if multiple rows
      if (selectedRows.length > 1) {
        dispatch(addLabelToMultipleSocialAccounts({
          vars: {
            communicationGroupId: commGroupID,
            label: label.label,
            networkAccountIds: selectedRows.map((row) => row.socialAccount.id),
          },
          onSuccess: () => {
            // Create and submit toast
            const toast: Toast = {
              type: "success",
              message: translate("Successfully added label to selected accounts"),
            }
            dispatch(pushToast(toast))

            // Reload group accounts
            dispatch(setGroupAccountReloadStatus(true))

            // Deselect after updating
            if (onRowSelectionChange) onRowSelectionChange([])
            if (isSearch) dispatch(setSelectedAccounts([]))
          },
          onError: () => {
            // Create and submit toast
            const toast: Toast = {
              type: "error",
              message: translate("Failed to add label to selected accounts"),
            }
            dispatch(pushToast(toast))
          },
        }))
      } else {
        dispatch(addLabelToSocialAccount({
          vars: {
            communicationGroupId: commGroupID,
            networkAccountId: selectedRows[0].socialAccount.id,
            label: label.label,
          },
          onSuccess: () => {
            // Create and submit toast
            const toast: Toast = {
              type: "success",
              message: translate("Successfully added label to account"),
            }
            dispatch(pushToast(toast))

            // Reload group accounts
            dispatch(setGroupAccountReloadStatus(true))

            // Deselect after updating
            if (onRowSelectionChange) onRowSelectionChange([])
            if (isSearch) dispatch(setSelectedAccounts([]))
          },
          onError: () => {
            // Create and submit toast
            const toast: Toast = {
              type: "error",
              message: translate("Failed to add label to account"),
            }
            dispatch(pushToast(toast))
          },
        }))
      }
    }
  }

  const deleteLabel = (label: PillLabelColor) => {
    // Remove from local list of labels
    const labelsWithoutSelected = labels.filter((lbl) => lbl.id !== label.id)

    // Make sure we have access to comms group and a group id exists
    const lbls: string[] = [ ...defaultLabels, ...labelsWithoutSelected.map((lbl) => lbl.label) ]
    if (API.isSuccess(commsGroup) && commGroupID) {
      // Update database by removing the deleted label
      dispatch(updateCommunicationGroupLabels({
        variables: {
          communicationGroupId: commGroupID,
          name: commsGroup.payload.communicationGroup.name,
          labels: lbls,
          suggestionListIds: commsGroup.payload.communicationGroup.suggestionLists.map((sl) => sl.id),
          campaignId: commsGroup.payload.communicationGroup.campaign?.id,
          subscribedUserIds: commsGroup.payload.communicationGroup.subscribedUsers.map((susr) => susr.id),
        },
        onSuccess: () => {
          // Create and submit toast
          const toast: Toast = {
            type: "success",
            message: translate("Successfully removed label"),
          }
          dispatch(pushToast(toast))
        },
        onError: () => {
          // Create and submit toast
          const toast: Toast = {
            type: "error",
            message: translate("Failed to removed label"),
          }
          dispatch(pushToast(toast))
        },
      }))
    }

    // Set the new set of labels
    setLabels(labelsWithoutSelected)
  }

  const editLabelValueChanged = (value: string) => {
    // Update label value
    setEditedLableValue({
      id: (editedLabelValue) ? editedLabelValue.id : "",
      label: value,
      colorIndex: (editedLabelValue) ? editedLabelValue.colorIndex : 0,
      type: "Custom",
    })
  }

  const approveUpdateLabel = () => {
    // Don't need to do anything if no text exists
    if (!editedLabelValue || editedLabelValue.label === "") return

    // Create new list of labels, with updated label value
    const lbls: PillLabelColor[] = []
    labels.forEach((lbl) => {
      if (lbl.id !== editedLabelValue.id) lbls.push(lbl)
      else lbls.push(editedLabelValue)
    })

    // Make sure we have access to comms group and a group id exists
    const lblids: string[] = [ ...defaultLabels, ...lbls.map((lbl) => lbl.label) ]
    if (API.isSuccess(commsGroup) && commGroupID) {
      // Update database with new label value
      dispatch(updateCommunicationGroupLabels({
        variables: {
          communicationGroupId: commGroupID,
          name: commsGroup.payload.communicationGroup.name,
          labels: lblids,
          suggestionListIds: commsGroup.payload.communicationGroup.suggestionLists.map((sl) => sl.id),
          campaignId: commsGroup.payload.communicationGroup.campaign?.id,
          subscribedUserIds: commsGroup.payload.communicationGroup.subscribedUsers.map((susr) => susr.id),
        },
        onSuccess: () => {
          // Create and submit toast
          const toast: Toast = {
            type: "success",
            message: translate("Successfully updated label value"),
          }
          dispatch(pushToast(toast))
        },
        onError: () => {
          // Create and submit toast
          const toast: Toast = {
            type: "error",
            message: translate("Failed to update label value"),
          }
          dispatch(pushToast(toast))
        },
      }))
    }

    // Update the screen with the new labels
    setLabels(lbls)

    // Clear the label
    setEditedLableValue(undefined)
  }

  const cancelUpdateLabel = () => {
    // Clear the label
    setEditedLableValue(undefined)
  }

  // ************************ Pre-Render computations ************************

  // Render the component
  return (
    <Dropdown
      id="cp_label-dropdown"
      buttonType="custom"
      customButtonChildren={ <LabelImportantIcon className="important-label-button" /> }
    >
      <List disablePadding={ true }>
        { labels.filter((label) => label.type === "Default").map((label) => (
          <ListItem disablePadding={ true } disableGutters={ true }>
            <ListItemButton
              id={ translate(label.label) }
              onClick={ () => applyLabel(label) }
            >
              <ListItemIcon>
                <Circle className={ `label-style${ label.colorIndex }` } />
              </ListItemIcon>
              <ListItemText>
                { label.label }
              </ListItemText>
            </ListItemButton>
          </ListItem>
        )) }
        { labels.length > 3 && (<Divider />) }
        { labels.filter((label) => label.type === "Custom").map((label) => (
          <ListItem
            className="personalized-label-list-item"
            disablePadding={ true }
            disableGutters={ true }
          >
            <ListItemButton
              id={ `label-${ label.id }` }
              onClick={ () => applyLabel(label) }
            >
              <ListItemIcon>
                <Circle className={ `label-style${ label.colorIndex }` } />
              </ListItemIcon>
              <ListItemText>
                { label.label }
              </ListItemText>
            </ListItemButton>
            <div id={ `modification-btn-group-${ label.id }` } className="modification-label-buttons">
              <EditOutlined className="edit-label-icon-button" onClick={ () => { setEditedLableValue(label) } } />
              <DeleteOutlineOutlined className="delete-label-icon-button" onClick={ () => { deleteLabel(label) } } />
            </div>
          </ListItem>
        )) }
        { labels.length > 3 && (
          <div className="edit-label-container">
            <Input
              className="input-field"
              id="edit-label-input"
              placeholder="a cool category"
              size="small"
              value={ (editedLabelValue) ? editedLabelValue.label : "" }
              onChange={ (e) => editLabelValueChanged(e.currentTarget.value) }
            />
            <CheckIcon className="update-label-check-button" onClick={ () => { approveUpdateLabel() } } />
            <CloseIcon className="cancel-edit-label-button" onClick={ () => { cancelUpdateLabel() } } />
          </div>
        ) }
      </List>
    </Dropdown>
  )
}
