import React from "react"
import { useTranslation } from "react-i18next"
import { Timeline } from "@mui/lab"

import "./history.sass"
import { useSelector } from "../../state/hooks"
import HistoryEvent from "./HistoryEvent"
import LoadingIndicator from "../LoadingIndicator"
import { isError } from "../../util/apiClient"

function ModalCampaignDeliverableHistoryTab(): React.JSX.Element {
  const {
    history,
  } = useSelector(({ campaignDeliverableContentModalV2 }) => campaignDeliverableContentModalV2)

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableHistoryTab" })

  if (history === "loading") return <LoadingIndicator />

  // TODO: replace this with loading indicator after history hook is added
  if (history === "init") {
    return (
      <div className="cp_modal-campaign-deliverable-history-tab_component">
        { translate("History") }
      </div>
    )
  }

  if (isError(history)) return <> </>

  return (
    <div className="cp_modal-campaign-deliverable-history-tab_component">
      { translate("History") }
      <div className="cp_modal-campaign-deliverable-history-tab_component-timeline-container">
        <div className="cp_modal-campaign-deliverable-history-tab_component-timeline">
          <Timeline position="right">
            { history.payload.deliverableHistory.map((event, i, events) => (
              <HistoryEvent showDivider={ i !== events.length - 1 } event={ event } />
            )) }
          </Timeline>
        </div>
      </div>
    </div>
  )
}

export default ModalCampaignDeliverableHistoryTab
