import React, { JSX, ReactNode } from "react"
import Dialog, { DialogProps } from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Typography from "@mui/material/Typography"
import Button, { ButtonSize, onClick } from "../Button"
import IconButtonClose from "../IconButtonClose"
import "./style.sass"
import Tooltip from "../Tooltip"

export interface ModalProps extends DialogProps {
  /**
   * Custom title component
   */
  customTitle?: JSX.Element
  /**
   * Custom top right action buttons
   */
  customTopRightActions?: JSX.Element[]
  /**
   * The larger title for the modal.
   */
  title?: string
  /**
   * An optional smaller title for the modal.
   */
  subtitle?: string
  /**
   * The onClick handler for the primary modal button.
   */
  primaryAction?: onClick
  /**
   * The onClick handler for the secondary modal button.
   */
  secondaryAction?: onClick
  /**
   * The onClick handler for the close button in the top right corner of modal.
   */
  closeAction: onClick
  /**
   * Label applied to the primary modal button.
   */
  primaryLabel?: ReactNode
  /**
   * Label applied to the secondary modal button.
   */
  secondaryLabel?: string
  /**
   * Allows if primary button renders or not. Default is true
   */
  hasPrimaryButton?: boolean
  /**
   * Allows if secondary button renders or not. Default is true
   */
  hasSecondaryButton?: boolean
  /**
   * Allos if the footer renders or not. Default is true
   */
  hasFooter?: boolean
  /**
   * The content to render in the body of the modal.
   */

  children: React.ReactNode
  /**
   * Modal sizes small, medium (default), large or x-large.
   * @default "md"
   */
  maxWidth?: "sm" | "md" | "lg" | "xl"
  /**
   * Set primary button's disabled state.
   * @default "false"
   */
  disabled?: boolean
  /**
   * Set Secondary button's disabled state
   */
  secondaryDisabled?: boolean
  /**
   * A tooltip label for the primary button.
   */
  primaryToolTip?: string
}

function titleElement(
  titles: { title: string, subtitle?: string },
  customTitle?: JSX.Element,
): JSX.Element {
  if (customTitle != null) {
    return customTitle
  }

  return (
    <div>
      <Typography variant="h5" className="cp_component_modal-title">
        { titles.title }
      </Typography>
      {
        titles.subtitle && (
          <Typography className="cp_component_modal-subtitle">
            { titles.subtitle }
          </Typography>
        )
      }
    </div>
  )
}

function Modal(props: ModalProps): JSX.Element {
  const {
    children,
    closeAction,
    customTitle,
    customTopRightActions,
    disabled,
    secondaryDisabled,
    fullWidth = true,
    hasFooter = true,
    hasPrimaryButton = true,
    hasSecondaryButton = true,
    maxWidth = "md",
    primaryAction = () => {},
    primaryLabel,
    secondaryAction = () => {},
    secondaryLabel,
    subtitle,
    title = "",
    primaryToolTip,
    ...dialogProps
  } = props

  const buttonSize: ButtonSize = maxWidth === "sm" ? "small" : "medium"
  const buttonFullWidth: boolean = maxWidth === "sm"

  return (
    <Dialog
      fullWidth={ fullWidth }
      maxWidth={ maxWidth }
      { ...dialogProps }
    >
      <DialogTitle sx={ {
        display: "flex",
        justifyContent: "space-between",
      } }
      >
        { titleElement({ title, subtitle }, customTitle) }
        <div>
          { customTopRightActions }
          <IconButtonClose onClick={ closeAction } />
        </div>
      </DialogTitle>
      <DialogContent>
        { children }
      </DialogContent>
      {
        hasFooter
        && (
        <DialogActions>
          { hasPrimaryButton && primaryToolTip && (
            <Tooltip title={ primaryToolTip }>
              <span>
                <Button
                  id="cp_component_modal-button-primary"
                  isPrimary={ true }
                  isEnabled={ !disabled }
                  label={ primaryLabel }
                  onClick={ primaryAction }
                  fullWidth={ buttonFullWidth }
                  size={ buttonSize }
                />
              </span>
            </Tooltip>
          ) }
          { hasPrimaryButton && !primaryToolTip && (
          <Button
            id="cp_component_modal-button-primary"
            isPrimary={ true }
            isEnabled={ !disabled }
            label={ primaryLabel }
            onClick={ primaryAction }
            fullWidth={ buttonFullWidth }
            size={ buttonSize }
          />
          ) }
          { hasSecondaryButton
        && (
        <Button
          id="cp_component_modal-button-secondary"
          isPrimary={ false }
          isEnabled={ !secondaryDisabled }
          label={ secondaryLabel }
          onClick={ secondaryAction }
          size={ buttonSize }
          fullWidth={ buttonFullWidth }
        />
        ) }
        </DialogActions>
        ) }
    </Dialog>
  )
}

export default Modal
