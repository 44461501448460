import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import * as Constant from "./constant"
import * as GraphQL from "../graphql"
import * as Type from "./types"
import timezones from "./timezoneOffsets"
import { FileResponse, MediaObject } from "./types"
import {
  generateContentInputQueryParams,
  generateSearchInputQueryParams,
  initialSearchState,
} from "./searchHelper"

// Miscellaneous Helpers

export const getLocalTZOffset = (): number => {
  const offset = dayjs().format("ZZ")
  return parseInt(
    offset.substring(0, 3),
    10,
  )
}

export const getTimeZoneByOffset = (offset: number): Type.Timezone | undefined => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const tz = timezones.find(([ name, _offset ]) => _offset === offset)
  return tz
}
export const getTimeZoneByName = (name: string): Type.Timezone | undefined => {
  const tz = timezones.find(([ _name ]) => _name === name)
  return tz
}

export function formatShorthandNumber(
  magnitude: number,
  num: number,
  suffix: string,
): string {
  const shorthand = (num / magnitude).toFixed(1)
  return `${ shorthand }${ suffix }`
}

export function shorthandNumber(num: number): string {
  if (num < Constant.THOUSAND) {
    return num.toString()
  }

  if (num < Constant.MILLION) {
    return formatShorthandNumber(
      Constant.THOUSAND,
      num,
      Constant.THOUSAND_SUFFIX,
    )
  }

  if (num < Constant.BILLION) {
    return formatShorthandNumber(
      Constant.MILLION,
      num,
      Constant.MILLION_SUFFIX,
    )
  }

  return formatShorthandNumber(
    Constant.BILLION,
    num,
    Constant.BILLION_SUFFIX,
  )
}

export function isNumeric(value: string): boolean {
  return !Number.isNaN(value) && !Number.isNaN(parseFloat(value))
}

// Depricating use of this. Use GraphQL.Network type instead
export function graphQLNetworkToNetwork(
  network: GraphQL.Network,
): Type._Network | null {
  switch (network) {
    case GraphQL.Network.Facebook:
      return "facebook"

    case GraphQL.Network.Instagram:
      return "instagram"

    case GraphQL.Network.Snapchat:
      return "snapchat"

    case GraphQL.Network.Tiktok:
      return "tiktok"

    case GraphQL.Network.Youtube:
      return "youtube"

    default:
      return null
  }
}

export function numberWithCommas(x: number | string) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export function prettyPrintDecimal(
  d: number | null | undefined,
  decimalPlaces: number = 2,
): string {
  return `${ ((d || 0) * 100).toFixed(decimalPlaces) }`
}

export function capitalize(s: string): string {
  if (s === "") return s
  if (s.length === 1) return s.toUpperCase()
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export function capitalizeEachWord(string: string) {
  return string.split(" ").map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(" ")
}

export function uploadFile(file: File): Promise<FileResponse> {
  const formData = new FormData()
  formData.append(Constant.UPLOAD_AVATAR_TYPE, file)

  return fetch(Constant.MEDIA_AVATAR_UPLOAD_PATH, {
    method: "POST",
    body: formData,
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("@UPLOADFILE :: Network response was not ok")
      }
      return response.json()
    })
    .then((data) => data as FileResponse)
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error("@UPLOADFILE :: There was a problem with the fetch operation:", error)
      throw error
    })
}

export function uploadMedia(file: File): Promise<FileResponse> {
  const formData = new FormData()
  formData.append(Constant.UPLOAD_TYPE, file)

  return fetch(Constant.MEDIA_UPLOAD_PATH, {
    method: "POST",
    body: formData,
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("@UPLOADFILE :: Network response was not ok")
      }
      return response.json()
    })
    .then((data) => data as FileResponse)
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error("@UPLOADFILE :: There was a problem with the fetch operation:", error)
      throw error
    })
}

export function attachCommFile(file: File): Promise<MediaObject> {
  const formData = new FormData()
  formData.append(Constant.ATTACHMENT_TYPE, file)

  return fetch(Constant.COMM_FILE_ATTACHMENT_PATH, {
    method: "POST",
    body: formData,
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("@UPLOADFILE :: Network response was not ok")
      }
      return response.json()
    })
    .then((data) => data as MediaObject)
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error("@UPLOADFILE :: There was a problem with the fetch operation:", error)
      throw error
    })
}

export function generateProfileUrl(
  socialAccountId: string,
  vanity: string,
) {
  return `/${ vanity }/profile/${ socialAccountId }`
}

export function generateSearchSocialAccountUrl(
  socialAccountId: string,
  vanity: string,
) {
  return `/${ vanity }/search/${ socialAccountId }`
}

export function generateSearchSocialAccountContentUrl(
  socialAccountId: string,
  vanity: string,
  contentInput?: GraphQL.GodSearchContentInput,
) {
  let url = `/${ vanity }/search/${ socialAccountId }/content`
  const inputArray = []

  const socialAccountContentSearch = initialSearchState()
  socialAccountContentSearch.socialAccountIds = [ socialAccountId ]
  socialAccountContentSearch.resultType = GraphQL.GodSearchType.Post
  inputArray.push(`q=${ encodeURIComponent((generateSearchInputQueryParams(socialAccountContentSearch))) }`)

  if (contentInput) {
    const contentParams = generateContentInputQueryParams(contentInput)
    if (contentParams) inputArray.push(`content=${ encodeURIComponent((contentParams)) }`)
  }

  url += `?${ inputArray.join("&") }`
  return url
}

export function generateDemographicRangeString(sexes: Array<string>, minAge?: number | null, maxAge?: number | null) {
  const renderAge = minAge || maxAge
  const renderGender = sexes.length > 0
  if (!renderAge && !renderGender) {
    return null
  }
  const items = []
  if (renderAge) {
    const min = minAge || 13
    const max = maxAge || 65
    items.push(`${ min }-${ max }`)
  }
  if (renderGender) {
    items.push(`${ sexes.join(", ") }`)
  }
  return items.join(" | ")
}

export const isNumericString = (value: string): boolean => /[a-zA-Z]|[^\w]/.test(value)
// Extend Day.js with the CustomParseFormat plugin
dayjs.extend(customParseFormat)

export function postDateFormatter(timestamp: number) {
  // Convert the timestamp from seconds to milliseconds
  const dateInMilliseconds = timestamp * 1000
  // Format the date: "29 Oct 2022 @ 4:32 PM" to match design
  const formattedDate = dayjs(dateInMilliseconds).format(Constant.LONGFORM_DATE_TIME)
  return formattedDate
}

// Format a timestamp to a custom date format
export function formatTimestampToCustomDate(timestamp: number): string {
  const date = dayjs.unix(timestamp)
  return date.format("MM/DD/YYYY h:mma") // 08/18/2022 9:44am format
}

// NOTE: This regex was pulled directly from Primm so that email validation may
// be synced from front end to back. If the regex in Primm ever changes, then
// we'll need to update the regex here as well.
// eslint-disable-next-line max-len
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const userNameRegex = /^[a-zA-Z0-9_]+$/

export const getDownloadReportCsvUrl = (params: {
  reportCode: string,
  filterLevel: 0 | 1
}) => `/api/report/${ params.reportCode }/csv?filter=${ params.filterLevel }`

// Scope Helpers
export const buildNetworkListByScope = (
  scopes: string[],
): GraphQL.Network[] => Type
  .ALL_NETWORKS
  .filter((n) => {
    if (scopes.includes(Type.Scope.FEATURE_TIKTOK)) return true
    return n !== GraphQL.Network.Tiktok
  })
  .filter((n) => {
    if (scopes.includes(Type.Scope.FEATURE_ENABLE_SNAPCHAT)) return true
    return n !== GraphQL.Network.Snapchat
  })

export const snakeToTitleString = (snakeCaseString: string) => {
  const words = snakeCaseString.split("_")
  const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  const filtered = capitalizedWords.filter((word) => word.length > 0)
  return filtered.join(" ")
}

export function limitTextLength(text: string, maxLength: number): string {
  if (text.length <= maxLength) return text
  return `${ text.slice(0, maxLength - 3) }...`
}

export function calcGrade(value: number): [string, string] {
  const grade = value >= 90 ? "a"
    : value >= 80 ? "b"
      : value >= 70 ? "c"
        : value >= 60 ? "d"
          : "f"

  const modifier = (() => {
    const remainder = Math.floor(value) % 10
    if (value < 60) return ""
    if (value >= 100) return "+"
    if (remainder >= 7) return "+"
    if (remainder <= 3) return "-"
    return ""
  })()

  return [ grade, modifier ]
}

export const formatPercent = (precision: number, value: number): string => `${ (value * 100).toFixed(precision) }%`

export const floor = (precision: number, value: number): number => {
  const factor = 10 ** precision
  return Math.floor(value * factor) / factor
}

export function getPossessive(name: string): string {
  // Check if the name ends with 's' or 'S'
  if (name.endsWith("s") || name.endsWith("S")) {
    return `${ name }'`
  }
  return `${ name }'s`
}

export function validEmailFormat(email: string): boolean {
  // Check to see if match
  // eslint-disable-next-line max-len, comma-dangle, no-useless-escape
  const match = email.match(emailRegex)

  // Return the validation
  return (match !== null)
}
