import React from "react"
import { useTranslation } from "react-i18next"
import { Edit } from "@mui/icons-material"
import { Box } from "@mui/material"
import { useParams } from "react-router-dom"
import Modal from "../Modal"
import { useDispatch, useSelector } from "../../state/hooks"
import { setCommGroupModalOpen, seteditCommGroupID } from "../../state/ModalCommGroupSlice"
import "./modal-campaign-association-warning.sass"
import { setNoCampaignAssociatedWarningOpen } from "../../state/ModalCreateDeliverableRequirements"

export default function ModalCampaignAssociationWarning() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalCampaignAssociationWarning" })
  const { t: tCommon } = useTranslation([], { keyPrefix: "common" })

  const { commGroupID } = useParams()
  const {
    noCampaignAssociatedWarningOpen: open,
  } = useSelector(({ modalCreateDeliverableRequirements }) => modalCreateDeliverableRequirements)
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(setNoCampaignAssociatedWarningOpen(false))
  }

  const handleEditClick = () => {
    if (commGroupID) {
      dispatch(seteditCommGroupID(commGroupID))
      dispatch(setCommGroupModalOpen(true))
      handleClose()
    }
  }

  return (
    <Modal
      className="cp_component_modal-campaign-association-warning"
      title={ translate("Create Deliverable") }
      hasPrimaryButton={ false }
      secondaryLabel={ tCommon("Cancel") }
      secondaryAction={ handleClose }
      closeAction={ handleClose }
      open={ open }
    >
      <p className="modal-notification-text">
        { translate("Oops, it doesn't look like the communication group is associated with a campaign.") }
      </p>
      <Box className="edit-link-container" onClick={ handleEditClick }>
        <Edit />
        <p className="inline-button-text">{ translate("Edit Communication Group") }</p>
      </Box>
    </Modal>
  )
}
