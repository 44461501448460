import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import "./style.sass"
import { Collapse, MenuItem } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import WebAssetIcon from "@mui/icons-material/WebAsset"
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded"
import { PendingActions } from "@mui/icons-material"
import * as GraphQL from "../../graphql"
import Avatar from "../Avatar"
import Networks from "./Networks"
import Divider from "../Divider"
import IconButton from "../IconButton"
import Button from "../Button"
import Pill from "../Pill"
import { prettyPrintDecimal, shorthandNumber } from "../../util/miscHelper"
import { Scope, ScoreBreakDown } from "../../util/types"
import StatusDot from "../StatusDot"
import { useDispatch, useSelector } from "../../state/hooks"
import { setPrimaryEmail, fetchSocialProfile } from "../../state/socialProfileSlice"
import { rawDataToScoreBreakDown, resetScoreBreakDown } from "../../state/scoreBreakDownSlice"
import Select from "../Select"
import ModalContactInformation from "./ModalContactInformation"
import { scoreModalTypes } from "../../util/constant"
import LoadingIndicator from "../LoadingIndicator"
import ModalScoreBreakDown from "../ModalScoreBreakDown"
import { updateProfile } from "../../state/profileSlice"

export function ProfileOverview(): React.JSX.Element {
  const [ collapsed, setCollapsed ] = useState(false)
  const [ openContactInformation, setOpenContactInformation ] = useState(false)
  const [ isLoading, setIsLoading ] = React.useState(false)
  const [ engagementScoreModal, setEngagementScoreModal ] = useState(false)
  const socialProfile = useSelector(({ socialProfile: slice }) => slice.profile)
  const { scopes } = useSelector(({ user }) => user)

  const dispatch = useDispatch()

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ProfileOverview" })

  useEffect(() => {
    if (engagementScoreModal === false) {
      dispatch(resetScoreBreakDown())
    }
  }, [ engagementScoreModal ])

  const showContactInfo = React.useMemo(() => scopes.includes(Scope.SOCIAL_ACCOUNT_CONTACT_INFO), [ scopes ])

  if (socialProfile === "loading" || socialProfile === "init") {
    return (
      <div className="cp_profile-overview_component_loading">
        <LoadingIndicator size={ 30 } />
      </div>
    )
  }

  if (socialProfile.status === "error") {
    return (
      <p>
        { translate("Unable to load social profile") }
      </p>
    )
  }

  const {
    id,
    bio,
    engagementScore,
    emails,
    emailsSourcedFromTeam,
    personality,
    profilePictureUrl,
    primaryEmail,
    socialAccountStatistics,
    userName,
    websiteUrl,
    isPlaceholder,
  } = socialProfile.payload.socialAccount

  const activeNetworks = personality?.socialAccounts || []

  const contact = personality?.contacts?.[0]

  const contactEmails = contact?.emails.map(({ email, primaryEmail: isPrimaryEmail }) => ({
    address: email,
    isPrimaryEmail,
  })) || []

  const computedPrimaryEmail = contactEmails.find(({ isPrimaryEmail }) => isPrimaryEmail) || primaryEmail

  const emailsFromTeam = emailsSourcedFromTeam.map((email) => {
    const ea = { address: email.address.toLowerCase() }
    return ea
  })
  const addresses = emailsFromTeam.map((email) => email.address)
  contactEmails.forEach((email) => {
    if (!addresses.includes(email.address.toLowerCase())) emailsFromTeam.push({ address: email.address.toLowerCase() })
  })

  const allEmails = [ ...emails, ...emailsFromTeam ]

  const emailOptions = allEmails.map(
    ({ address }) => (
      <MenuItem
        disabled={ address === computedPrimaryEmail?.address }
        key={ address }
        value={ address }
      >
        { address }
      </MenuItem>
    ),
  )

  const updateProfileEmail = async (primaryEmailIndex: number) => {
    if (!personality) {
      return
    }

    const socialAccountsProfileInput = personality.socialAccounts
      .map((account) => ({
        foreignUserId: account.id,
        id: account.id,
        network: account.network,
        userName: account.userName,
      }))

    const updatedContacts:GraphQL.PersonalityContactInput[] = [
      {
        personalityContactId: contact?.id,
        firstName: contact?.firstName || undefined,
        lastName: contact?.lastName || undefined,
        emails: (contact?.emails || [])
          .filter(({ email }) => email.trim() !== "")
          .map(({ email }, i) => ({ address: email, primary: i === primaryEmailIndex })),
      },
    ]

    const updatedProfile: GraphQL.EditProfileMutationVariables = {
      id: personality?.id,
      name: personality.name,
      verticalIds: personality.verticals.map(({ id: verticalId }) => verticalId),
      socialAccounts: socialAccountsProfileInput,
      avatarUrl: personality.avatar?.url.address,
      vip: personality.vip,
      blacklist: personality.blacklist,
      blacklistReason: personality.blacklistReason,
      contacts: updatedContacts,
    }
    // Update the profile
    const updated = await dispatch(updateProfile(updatedProfile))
    if (updated) {
      await dispatch(fetchSocialProfile(id))
    }
  }

  const setPrimaryEmailHandler = async (email: string) => {
    setIsLoading(true)
    const profileMailIndex = contactEmails.findIndex(({ address }) => address === email)
    if (profileMailIndex !== -1) {
      await updateProfileEmail(profileMailIndex)
    } else {
      await dispatch(setPrimaryEmail({
        networkAccountId: id,
        email,
      }))
    }
    setIsLoading(false)
  }

  const handleEngagmentScoreModal = (scoreBreakDown: ScoreBreakDown, modalType: string) => {
    if (scoreBreakDown.scores.some((item) => item === null)) return
    dispatch(rawDataToScoreBreakDown(scoreBreakDown, modalType))
    setEngagementScoreModal(true)
  }

  return (
    <div className="cp_profile-overview_component">
      <div className="cp_profile-overview_component-avatar">
        <div className="cp_profile-overview_component-avatar-wrapper">
          { !isPlaceholder
            ? <Avatar src={ profilePictureUrl } className="cp_profile-overview_component-avatar-image" />
            : <PendingActions className="cp_profile-overview-placeholder-icon" /> }
          <p className="cp_profile-overview_component-avatar-name">
            { contact?.firstName && contact?.lastName
              ? `${ contact.firstName } ${ contact.lastName }`
              : userName }
          </p>
          <p className="cp_profile-overview_component-avatar-email">
            { computedPrimaryEmail?.address || translate("No Primary Email") }
          </p>
        </div>
      </div>
      { activeNetworks.length > 0 && (
        <>
          <Networks
            activeNetworks={ activeNetworks }
            headingLabel={ translate("Available Accounts") }
          />
          <Divider />
        </>
      ) }
      <div className="cp_profile-overview_component-scores-wrapper">
        <div>
          <p className="label_small-caps-semibold cp_profile-overview_component-scores-heading">
            { translate("FOLLOWERS") }
          </p>
          <Pill label={ shorthandNumber(socialAccountStatistics.followers) } color="warning" />
        </div>
        <div>
          <p className="label_small-caps-semibold cp_profile-overview_component-scores-heading">
            { translate("ENG. SCORE") }
          </p>
          { engagementScore && (
            <Pill
              label={ Math.round(engagementScore.value) }
              color="info"
              handleClick={ () => {
                const { socialAccount } = socialProfile.payload
                const scoreBreakDown = {
                  socialAccount: socialAccount as GraphQL.SocialAccount,
                  scores: [ engagementScore as GraphQL.Score ],
                }
                handleEngagmentScoreModal(scoreBreakDown, scoreModalTypes.ENGAGEMENT)
              } }
            />
          ) }
        </div>
        <div>
          <p className="label_small-caps-semibold cp_profile-overview_component-scores-heading">
            { translate("ENG. RATE") }
          </p>
          <Pill label={ `${ prettyPrintDecimal(socialAccountStatistics.engagementRate) }%` } />
        </div>
      </div>
      <Divider />
      <div className="cp_profile-overview_component-bio-wrapper">
        <p className="label_small-caps-semibold cp_profile-overview_component-bio-heading">
          { translate("Account Biography") }
        </p>
        <p className="label_small-caps-semibold cp_profile-overview_component-bio-body">
          { bio || translate("Biography Not Available") }
        </p>
      </div>
      { showContactInfo ? (
        <>
          <Divider />
          <div className="cp_profile-overview_component-contact-wrapper">
            <p className="label_small-caps-semibold cp_profile-overview_component-contact-heading">
              { translate("Contact Information") }
              <IconButton onClick={ () => setCollapsed(!collapsed) }>
                { collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon /> }
              </IconButton>
            </p>
            <Collapse in={ !collapsed }>
              <p className="label_small-caps-semibold cp_profile-overview_component-contact-title">
                <WebAssetIcon />
                { translate("Websites from Public Profile") }
              </p>
              <p className="label_small-caps-semibold cp_profile-overview_component-contact-website">
                { websiteUrl }
              </p>
              <p className="label_small-caps-semibold cp_profile-overview_component-contact-title">
                <MailOutlineRoundedIcon />
                { translate("Emails From Public Profile") }
              </p>
              { emails.map(({ address }) => (
                <p key={ address } className="label_small-caps-semibold cp_profile-overview_component-contact-email">
                  { address }
                </p>
              )) }
              <p className="label_small-caps-semibold cp_profile-overview_component-contact-title">
                <MailOutlineRoundedIcon />
                { translate("Emails Added By Your Team") }
                <Button
                  className="cp_btn_component_highlight"
                  onClick={ () => setOpenContactInformation(true) }
                  type="button"
                  variant="text"
                  isPrimary={ false }
                  isEnabled={ true }
                  label={ translate("Edit") }
                  size="small"
                />
              </p>
              { emailsFromTeam.map(({ address }) => (
                <div key={ address } className="cp_profile-overview_component-contact-email-wrapper">
                  <p className="label_small-caps-semibold cp_profile-overview_component-contact-email">
                    { address }
                  </p>
                  <p className="label_small-caps-semibold cp_profile-overview_component-email_status">
                    { computedPrimaryEmail?.address === address && <StatusDot status="support" size="sm" /> }
                    { computedPrimaryEmail?.address === address && translate("PRIMARY") }
                  </p>
                </div>
              )) }
              <p className="label_small-caps-semibold cp_profile-overview_component-email-select">
                { translate("Choose a primary Email Address") }
              </p>
              <Select
                disabled={ isLoading }
                value={ computedPrimaryEmail?.address }
                fullWidth={ true }
                id="cp_profile-overview_select-email"
                label=""
                labelId=""
                menuItems={ emailOptions }
                onChange={ (e) => setPrimaryEmailHandler(e.target.value as string) }
                IconComponent={ isLoading ? LoadingIndicator : undefined }
              />
            </Collapse>
          </div>
        </>
      ) : null }
      <ModalContactInformation
        open={ openContactInformation }
        closeAction={ () => setOpenContactInformation(false) }
        secondaryAction={ () => setOpenContactInformation(false) }
      />
      <ModalScoreBreakDown isModalScoreOpen={ engagementScoreModal } closeModal={ () => setEngagementScoreModal(false) } />
    </div>
  )
}

export default ProfileOverview
