import {
  CheckBoxOutlined,
  CheckCircleOutline,
  RemoveRedEyeOutlined,
} from "@mui/icons-material"
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "../../../../state/hooks"
import { togglePermission } from "../../../../state/permissionsToggleSlice"
import Switch from "../../../Switch"

interface PermissionToggle {
  id: "finalize-feedback" | "approve-only" | "view-leave-feedback"
  titleKey: string
  descriptionKey: string
  icon: React.ReactNode
}

const permissionToggles: PermissionToggle[] = [
  {
    id: "finalize-feedback",
    titleKey: "Finalize Feedback",
    descriptionKey: "Enabling this permission also enables Approve and View & Leave Feedback permissions.",
    icon: <CheckBoxOutlined />,
  },
  {
    id: "approve-only",
    titleKey: "Approve Only",
    descriptionKey: "Enabling this permission also enables View & Leave Feedback permissions.",
    icon: <CheckCircleOutline />,
  },
  {
    id: "view-leave-feedback",
    titleKey: "View & Leave Feedback Only",
    descriptionKey: "User can view Deliverable content and leave feedback on Media or Caption.",
    icon: <RemoveRedEyeOutlined />,
  },
]

export default function PermissionsToggle() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.PermissionsToggle" })
  const dispatch = useDispatch()
  const permissions = useSelector(({ permissionsToggle }) => permissionsToggle.permissions)

  const handleToggle = (id: "finalize-feedback" | "approve-only" | "view-leave-feedback") => {
    dispatch(togglePermission(id))
  }
  return (
    <Box className="permissions-toggle-container">
      <List>
        { permissionToggles.map((toggle) => (
          <ListItem
            key={ toggle.id }
            alignItems="flex-start"
            className="permissions-list"
          >
            <ListItemIcon className="permissions-icon">
              { toggle.icon }
            </ListItemIcon>
            <ListItemText
              primary={ (
                <Typography component="div" className="permissions-title">
                  { translate(toggle.titleKey) }
                </Typography>
              ) }
              secondary={ (
                <Typography color="text.secondary">
                  { translate(toggle.descriptionKey) }
                </Typography>
              ) }
            />
            <ListItemSecondaryAction>
              <Switch
                id={ toggle.id }
                className="switch-component"
                handleChange={ () => handleToggle(toggle.id) }
                isChecked={ permissions[toggle.id] }
              />
            </ListItemSecondaryAction>
          </ListItem>
        )) }
      </List>
    </Box>
  )
}
