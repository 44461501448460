export const TYPOGRAPHY = {
  FONT_WEIGHT: {
    /**
       * 400
       */
    NORMAL: 400,
    /**
     * 600
     */
    BOLD: 600,
    /**
     * 700
     */
    BOLDER: 700,
  },
  FONT_SIZE: {
    /**
     * 0.75rem
     */
    NORMAL: "0.75rem",
    LARGE: "1.25rem",
  },
}

export const COLORS = {
  dark: {
    white: "#ffffff",
  },
  light: {
    white: "#ffffff",
  },
}

export default TYPOGRAPHY
