import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { FullConversationFragment } from "../../graphql"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"

// Comms create template modal slice Interface and Initial State
export interface ModalCreateDeliverableState {
  open: boolean
  activeStep: number
  stepsCompleted: Array<number>
  deliverableTitle: string
  clientNote: string
  caption: string
  selectedCaptionId: string
  selectedMedia: GraphQL.AttachmentFragment[]
  conversation?: FullConversationFragment
}

const initialState: ModalCreateDeliverableState = {
  open: false,
  activeStep: 1,
  stepsCompleted: [],
  deliverableTitle: "",
  clientNote: "",
  caption: "",
  selectedCaptionId: "",
  selectedMedia: [],
}

// Modal Create Deliverable Slice
export const modalCreateDeliverable = createSlice({
  name: "ModalCreateDeliverable",
  initialState,
  reducers: {
    openModalCreateDeliverable: (
      state,
      action: PayloadAction<{
        conversation: FullConversationFragment
      }>,
    ) => ({
      ...state,
      open: true,
      activeStep: 1,
      stepsCompleted: [],
      deliverableTitle: "",
      selectedCaptionId: "",
      caption: "",
      clientNote: "",
      selectedMedia: [],
      conversation: action.payload.conversation,
    }),
    closeModalCreateDeliverable: (
      state,
    ) => ({
      ...state,
      open: false,
    }),
    setActiveStep: (
      state,
      action: PayloadAction<number>,
    ) => ({
      ...state,
      activeStep: action.payload,
    }),
    setDeliverableTitle: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      deliverableTitle: action.payload,
    }),
    setClientNote: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      clientNote: action.payload,
    }),
    setCaption: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      caption: action.payload,
    }),
    pushStepCompleted: (
      state,
      action: PayloadAction<number>,
    ) => ({
      ...state,
      stepsCompleted: [ ...state.stepsCompleted, action.payload ],
    }),
    removeStepCompleted: (
      state,
      action: PayloadAction<number>,
    ) => ({
      ...state,
      stepsCompleted: state.stepsCompleted.filter((step) => step !== action.payload),
    }),
    addSelectedMedia: (
      state,
      action: PayloadAction<GraphQL.AttachmentFragment>,
    ) => ({
      ...state,
      selectedMedia: [ ...state.selectedMedia, action.payload ],
    }),
    removeSelectedMedia: (
      state,
      action: PayloadAction<GraphQL.AttachmentFragment>,
    ) => ({
      ...state,
      selectedMedia: state.selectedMedia.filter((media) => media.id !== action.payload.id),
    }),
    setSelectedCaptionId: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      selectedCaptionId: action.payload,
    }),
  },
})

export const {
  openModalCreateDeliverable,
  closeModalCreateDeliverable,
  setActiveStep,
  setDeliverableTitle,
  setClientNote,
  pushStepCompleted,
  removeStepCompleted,
  setCaption,
  addSelectedMedia,
  removeSelectedMedia,
  setSelectedCaptionId,
} = modalCreateDeliverable.actions
export default modalCreateDeliverable.reducer

export const createDeliverable = (
  params: {
    fileVariables: GraphQL.CreateMediaBatchMutationVariables,
    deliverableVariables: Omit<GraphQL.CreateDeliverableFromConversationMutationVariables, "mediaInput">,
    onSuccess: (
      campaignId: string,
      deliverableId: string
    ) => void,
    onError: () => void
  },
) => async (): Promise<void> => {
  const mediaBatchResult = await API.createMediaBatch(params.fileVariables)
  if (API.isError(mediaBatchResult) || !mediaBatchResult.payload?.createMediaBatch) {
    params.onError()
    return
  }

  const mediaInput: GraphQL.DeliverableMediaInput[] = mediaBatchResult.payload.createMediaBatch.map((media, idx) => ({
    displayOrder: idx,
    mediaId: media.id,
  }))

  const deliverableResult = await API.createDeliverableFromConversation({
    ...params.deliverableVariables,
    mediaInput,
  })

  if (API.isError(deliverableResult) || !deliverableResult.payload) {
    params.onError()
  } else {
    const {
      id: deliverableId,
      campaignNetworkAccount: {
        campaign: {
          id: campaignId,
        },
      },
    } = deliverableResult.payload.createDeliverableFromConversation
    params.onSuccess(campaignId, deliverableId)
  }
}
