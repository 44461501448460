/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import React, {
  JSX,
  useEffect,
  useMemo,
} from "react"
import { useTranslation } from "react-i18next"
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material"
import {
  AccountCircle,
  ArrowDropDown,
  InsertPhotoOutlined,
} from "@mui/icons-material"

import * as GraphQL from "../../graphql"
import * as SearchHelper from "../../state/searchAISlice/helper"
import { isSuccess } from "../../util/apiClient"
import Dropdown from "../Dropdown"
import StartsWithInput from "./StartsWithInput"
import { TYPOGRAPHY } from "../../style/mui-styles"
import SearchAIAccountsTable from "../SearchAIAccountsTable"
import SearchAIContentTable from "../SearchAIContentTable"
import {
  clearSearchState,
  fetchAccountSearchResults,
  setSearchAIType,
  setSearchInput,
} from "../../state/searchAISlice"
import { useDispatch, useSelector } from "../../state/hooks"

import "./style.sass"
import { SearchAIType } from "../../util/types"

function renderResultTypeIcon(resultType: SearchAIType): JSX.Element {
  return (
    <div className="cp_component_search-ai-results-type-dropdown">
      { resultType === SearchAIType.Accounts ? <AccountCircle />
        : <InsertPhotoOutlined /> }
      <ArrowDropDown />
    </div>
  )
}

export default function SearchResults(): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.SearchAIResults" })
  const dispatch = useDispatch()
  const {
    searchAIType,
    searchResultsAccounts,
    searchResultsContent,
  } = useSelector(({ searchAI }) => searchAI)
  // On unmount, clean up search state
  useEffect(() => () => {
    dispatch(clearSearchState())
  }, [])

  const resultCount = useMemo(() => {
    let count = 0
    if (searchAIType === SearchAIType.Accounts && isSuccess(searchResultsAccounts)) count = searchResultsAccounts.payload.searchAccounts.total
    if (searchAIType === SearchAIType.Content && isSuccess(searchResultsContent)) count = searchResultsContent.payload.searchPosts.total
    return count
  }, [ searchResultsAccounts, searchResultsContent ])

  return (
    <div className="cp_component_search-ai-results">
      <Stack direction="row" justifyContent="space-between" marginBottom="1rem">
        <Stack direction="row" alignItems="center" gap={ 2 }>
          <StartsWithInput />
          <Typography
            className="cp_component_search-ai-results-totals"
            fontSize={ TYPOGRAPHY.FONT_SIZE.NORMAL }
            fontWeight={ TYPOGRAPHY.FONT_WEIGHT.NORMAL }
          >
            { translate("RESULTS", {
              total: resultCount,
            }) }
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" gap={ 2 }>
          <Dropdown
            buttonType="custom"
            customButtonChildren={
            renderResultTypeIcon(searchAIType)
          }
          >
            <List disablePadding={ true }>
              <ListItem disablePadding={ true } disableGutters={ true }>
                <ListItemButton
                  onClick={ async () => {
                    await dispatch(setSearchAIType(SearchAIType.Accounts))
                    dispatch(fetchAccountSearchResults())
                  } }
                >
                  <ListItemIcon>
                    <AccountCircle className="list-item-icon" />
                  </ListItemIcon>
                  <ListItemText primary={ translate("Accounts") } />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding={ true } disableGutters={ true }>
                <ListItemButton
                  onClick={ async () => {
                    await dispatch(setSearchAIType(SearchAIType.Content))
                    dispatch(fetchAccountSearchResults())
                  } }
                >
                  <ListItemIcon>
                    <InsertPhotoOutlined className="list-item-icon" />
                  </ListItemIcon>
                  <ListItemText primary={ translate("Content") } />
                </ListItemButton>
              </ListItem>
            </List>
          </Dropdown>
        </Stack>
      </Stack>
      <section className="cp_component_search-ai-results-table-container">
        { searchAIType === SearchAIType.Accounts && <SearchAIAccountsTable /> }
        { searchAIType === SearchAIType.Content && <SearchAIContentTable /> }
      </section>
    </div>
  )
}
