import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import "./select-media-input-step.sass"
import { useDispatch, useSelector } from "../../state/hooks"
import Divider from "../Divider"
import SelectionCarousel from "../SelectionCarousel"
import {
  addSelectedMedia,
  pushStepCompleted,
  removeSelectedMedia,
  removeStepCompleted,
} from "../../state/ModalCreateDeliverable"

const INPUT_STEP = 3

export default function SelectMediaInputStep() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalCreateDeliverable" })
  const {
    conversation, selectedMedia, stepsCompleted,
  } = useSelector(({ modalCreateDeliverable }) => modalCreateDeliverable)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!stepsCompleted.includes(INPUT_STEP) && selectedMedia) dispatch(pushStepCompleted(INPUT_STEP))
    if (stepsCompleted.includes(INPUT_STEP) && !selectedMedia) dispatch(removeStepCompleted(INPUT_STEP))
  }, [ selectedMedia ])

  return (
    <div className="cp_component_select-media-input-step">
      <p>
        { translate("Select post media for the deliverable") }
        { " " }
        <b>{ conversation?.socialAccount.userName || "" }</b>
        { " " }
        { translate("in") }
        { " " }
        <b>{ conversation?.communicationGroup.campaign?.name || "" }</b>
      </p>
      <Divider />
      <SelectionCarousel
        selectedMedia={ selectedMedia }
        addSelectedMedia={ (media) => dispatch(addSelectedMedia(media)) }
        removeSelectedMedia={ (media) => dispatch(removeSelectedMedia(media)) }
        media={ conversation?.conversationThread.attachments || [] }
      />
    </div>
  )
}
