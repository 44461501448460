/* eslint-disable no-underscore-dangle */
import React from "react"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"

import * as API from "../../util/apiClient"
import * as Constant from "../../util/constant"
import * as GraphQL from "../../graphql"
import Button from "../Button"
import Divider from "../Divider"
import Input from "../Input"
import LoadingIndicator from "../LoadingIndicator"
import Modal from "../Modal"
import Switch from "../Switch"
import Tabs, { TabLabelProps } from "../Tabs"
import { useSelector, useDispatch } from "../../state/hooks"
import {
  closeCreateFeedbackModal,
  createComment,
  createInternalNote,
  setApprovalStatus,
} from "../../state/listSlice"

import "./modal-create-feedback.sass"

interface CommentProps {
  comment: GraphQL.SuggestionListCommentFragment
  type: string
}
function FeedbackComment({
  comment,
  type,
}: CommentProps) {
  let commentTitle = ""
  if (comment.author.__typename === "AnonymousUser") {
    const { name, company } = comment.author
    commentTitle = `${ name } from ${ company }'s ${ type }`
  } else if (comment.author.__typename === "User") {
    commentTitle = `${ comment.author.contact.name } from ${ comment.author.customer.company?.name }'s ${ type }`
  }

  return (
    <div className="cp_comment_component">
      <div className="cp_comment_component-header">
        <p className="cp_comment_component-header-title">
          { commentTitle }
        </p>
        <p className="cp_comment_component-header-timestamp">
          { dayjs(comment.created * 1000).format(Constant.LONGFORM_DATE_TIME) }
        </p>
      </div>
      <Divider />
      <div className="cp_comment_component-content">
        { comment.content }
      </div>
    </div>
  )
}

function ModalCreateFeedback() {
  const dispatch = useDispatch()
  const {
    createFeedback,
    list,
  } = useSelector(({ list: listState }) => listState)
  const [ tab, setTab ] = React.useState(0)
  const [ feedbackComment, setFeedbackComment ] = React.useState("")
  const [ internalNote, setInternalNote ] = React.useState("")
  const [ loading, setLoading ] = React.useState(false)
  const [ loadingApprovalStatus, setLoadingApprovalStatus ] = React.useState(false)

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCreateFeedback" })

  if (!createFeedback || list === "init" || list === "loading" || API.isError(list)) return null

  const submitPublicFeedback = async () => {
    setLoading(true)

    await dispatch(createComment({
      suggestionListSocialAccountId: createFeedback.id,
      message: feedbackComment,
    }))

    setLoading(false)
    setFeedbackComment("")
  }
  const submitInternalNote = async () => {
    setLoading(true)

    await dispatch(createInternalNote({
      suggestionListSocialAccountId: createFeedback.id,
      message: internalNote,
    }))

    setLoading(false)
    setInternalNote("")
  }

  const updateApprovalStatus = async (approvalStatus: boolean) => {
    setLoadingApprovalStatus(true)

    await dispatch(setApprovalStatus({
      suggestionListSocialAccountId: createFeedback.id,
      approvalStatus,
    }))

    setLoadingApprovalStatus(false)
  }
  const isApproved = Boolean(createFeedback.approvalStatus?.value)
  const switchLabel = isApproved ? translate("APPROVED") : translate("NOT APPROVED")

  const feedbackComments = createFeedback.comments
    .filter(({ type }) => type === GraphQL.SuggestionListSocialAccountCommentType.Feedback)

  const internalNotes = createFeedback.comments
    .filter(({ type }) => type === GraphQL.SuggestionListSocialAccountCommentType.Internal)

  const tabs: TabLabelProps[] = [
    { label: translate("Public Feedback"), badge: feedbackComments.length },
    { label: translate("Internal Notes"), badge: internalNotes.length },
  ]

  return (
    <Modal
      title={ translate("Feedback & Approval") }
      open={ Boolean(createFeedback) }
      closeAction={ () => dispatch(closeCreateFeedbackModal()) }
      hasFooter={ false }
      maxWidth="xl"
    >
      <div className="cp_create-feedback_component">
        <Tabs
          handleChange={ setTab }
          tabs={ tabs }
          controls={ (
            <div className="cp_create-feedback_component-approved">
              <p className="cp_create-feedback_component-approved-label">
                {
                  loadingApprovalStatus ? <LoadingIndicator /> : switchLabel
                }
              </p>
              <Switch
                handleChange={ (e, checked) => updateApprovalStatus(checked) }
                isChecked={ isApproved }
                hasIcon={ true }
                disabled={ loadingApprovalStatus }
              />
            </div>
            ) }
        />
        <div className="cp_create-feedback_component-content">
          <div className="cp_create-feedback_component-content-feedback">
            { tab === 0 ? (
              <>
                <Input
                  className="cp_create-feedback_component-content-feedback-input"
                  rows={ 4 }
                  multiline={ true }
                  label={ translate("FEEDBACK") }
                  placeholder={ translate("Leave public feedback...") }
                  value={ feedbackComment }
                  onChange={ ({ currentTarget }) => setFeedbackComment(currentTarget.value) }
                />
                <Button
                  disabled={ loading && feedbackComment !== "" }
                  label={ loading ? <LoadingIndicator /> : translate("SUBMIT") }
                  onClick={ submitPublicFeedback }
                />
              </>
            )
              : (
                <>
                  <Input
                    className="cp_create-feedback_component-content-feedback-input"
                    rows={ 4 }
                    multiline={ true }
                    label={ translate("NOTES") }
                    placeholder={ translate("Leave an internal note...") }
                    value={ internalNote }
                    onChange={ ({ currentTarget }) => setInternalNote(currentTarget.value) }
                  />
                  <Button
                    disabled={ loading && internalNote !== "" }
                    label={ loading ? <LoadingIndicator /> : translate("SUBMIT") }
                    onClick={ submitInternalNote }
                  />
                </>
              )
            }
          </div>
        </div>

        { tab === 0 && feedbackComments.length > 0 && (
        <div className="cp_create-feedback_component-content-comments">
          <p className="cp_create-feedback_component-content-comments-title">{ translate("Public Feedback") }</p>
          { feedbackComments.map((comment) => <FeedbackComment comment={ comment } type={ translate("feedback") } />) }
        </div>
        ) }
        { tab === 1 && internalNotes.length > 0 && (
        <div className="cp_create-feedback_component-content-comments">
          <p className="cp_create-feedback_component-content-comments-title">{ translate("Internal Notes") }</p>
          { internalNotes.map((comment) => <FeedbackComment comment={ comment } type={ translate("note") } />) }
        </div>
        ) }
      </div>
    </Modal>
  )
}

export default ModalCreateFeedback
