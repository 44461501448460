import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { Status } from "../../util/types"
import * as GraphQL from "../../graphql"

// Deliverable Content Modal V2 Slice Interface and Initial State
export interface CampaignDeliverableContentModalV2State {
  modalOpen: boolean
  confirmFinalize: boolean,
  deliverable?: GraphQL.DeliverableFragment // TODO: replace this with new schema type
  captionFeedback?: GraphQL.DeliverableCaptionFragment // TODO: replace this with new schema type
  captionToApprove?: GraphQL.DeliverableCaptionFragment // TODO: replace this with new schema type
  history: Status<GraphQL.DeliverableHistoryQuery> // TODO: replace this with new schema type
  mediaFeedback?: GraphQL.DeliverableMediaFragment
  mediaToApprove?: GraphQL.DeliverableMediaFragment
  onClose?: (edited: boolean) => Promise<void>
  edited: boolean
  edittingUrl: boolean
  /**
   * This property is used to hide the modal when the user navigates away from the modal to a nested modal.
   * This will preserve the state of the modal when the user navigates back to the parent modal.
   */
  hidden: boolean
}

const initialState: CampaignDeliverableContentModalV2State = {
  modalOpen: false,
  deliverable: undefined,
  confirmFinalize: false,
  history: "init",
  edittingUrl: false,
  edited: false,
  hidden: false,
}

// Deliverable Content Modal Slice
export const CampaignDeliverableContentModalV2Slice = createSlice({
  name: "CampaignDeliverableContentModalV2Slice",
  initialState,
  reducers: {
    openFinalizeConfirmModal: (
      state,
    ) => ({
      ...state,
      confirmFinalize: true,
    }),
    closeFinalizeConfirmModal: (
      state,
    ) => ({
      ...state,
      confirmFinalize: false,
    }),
    openDeliverableContentModal: (
      state,
      action: PayloadAction<{
        deliverable: GraphQL.DeliverableFragment,
        modalOpen: boolean,
        onClose?: (edited: boolean) => Promise<void>
      }>,
    ) => ({
      ...state,
      deliverable: action.payload.deliverable,
      modalOpen: action.payload.modalOpen,
      onClose: action.payload.onClose,
      hidden: false,
    }),
    closeDeliverableContentModal: (
      state,
    ) => ({
      ...state,
      modalOpen: false,
      onClose: undefined,
      hidden: false,
    }),
    hideDeliverableContentModal: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      hidden: action.payload,
    }),
    setEdittingUrl: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      edittingUrl: action.payload,
    }),
    setEdited: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      edited: action.payload,
    }),
    setMediaToApprove: (
      state,
      action: PayloadAction<{
        media?: GraphQL.DeliverableMediaFragment,
      }>,
    ) => ({
      ...state,
      mediaToApprove: action.payload.media,
    }),
    setCaptionToApprove: (
      state,
      action: PayloadAction<{
        caption?: GraphQL.DeliverableCaptionFragment,
      }>,
    ) => ({
      ...state,
      captionToApprove: action.payload.caption,
    }),
    setMediaFeedback: (
      state,
      action: PayloadAction<{
        media?: GraphQL.DeliverableMediaFragment,
      }>,
    ) => ({
      ...state,
      mediaFeedback: action.payload.media,
    }),
    setCaptionFeedback: (
      state,
      action: PayloadAction<{
        caption?: GraphQL.DeliverableCaptionFragment,
      }>,
    ) => ({
      ...state,
      captionFeedback: action.payload.caption,
    }),
  },
})

export const {
  openFinalizeConfirmModal,
  closeFinalizeConfirmModal,
  closeDeliverableContentModal,
  openDeliverableContentModal,
  hideDeliverableContentModal,
  setEdittingUrl,
  setEdited,
  setMediaFeedback,
  setMediaToApprove,
  setCaptionToApprove,
  setCaptionFeedback,
} = CampaignDeliverableContentModalV2Slice.actions
export default CampaignDeliverableContentModalV2Slice.reducer

/* TODO: Integration with api-v2 endpoints.

export const getHistory = (
  params: GraphQL.DeliverableHistoryQueryVariables,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setDeliverableHistory("loading"))
  const result = await API.getDeliverableHistory(params)
  dispatch(setDeliverableHistory(result))
}

// leaving the code comment to serve as a reference
// for the required actions
export const approveDeliverableItem = (
  params: GraphQL.AcceptDeliverableItemMutationVariables,
) => async () => {
  const result = await API.approveDeliverableItem(params)
  return result
}

export const addFeedback = (
  params: GraphQL.CreateDeliverableItemFeedbackMutationVariables,
) => async () => {
  const result = await API.createDeliverableItemFeedback(params)
  return result
}

export const updateDeliverableUrl = (
  params: {
    variables: GraphQL.UpdateDeliverableLiveStatusUrlMutationVariables,
    onSuccess: () => any
    onError: () => any
  },
) => async () => {
  const result = await API.updateDeliverableUrl(params.variables)
  if (API.isSuccess(result)) {
    params.onSuccess()
  } else {
    params.onError()
  }
}

export const refreshDeliverable = (
  params: {
    deliverableId: string
  },
) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const {
    campaignPage: {
      campaign,
    },
  } = getState()
  if (API.isSuccess(campaign)) {
    const {
      deliverables,
    } = campaign.payload.campaign
    const deliverable = deliverables.find((d) => d.id === params.deliverableId)
    if (deliverable) {
      dispatch(setDeliverable({ deliverable }))
    }
  }
}
export const finalizeDeliverable = (
  params: GraphQL.FinalizeDeliverableMutationVariables,
) => async () => {
  const result = await API.finalizeDeliverable(params)
  return result
}

export const updateliverableLiveStatus = (
  params: GraphQL.UpdateDeliverableLiveStatusMutationVariables,
) => async () => {
  const result = await API.updateDeliverableLiveStatus(params)
  return result
}
*/
