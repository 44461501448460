import React, { JSX } from "react"
import {
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import RefreshIcon from "@mui/icons-material/Refresh"

import PromptAI from "./PromptAI"
import ProfileFilters from "./ProfileFilters"
import NetworkFilter from "./AINetworkFilter"
import { Network } from "../../graphql/search"
import { TYPOGRAPHY } from "../../style/mui-styles"
import {
  cloneSearchInput,
  initialProfileInput,
  initialContentInput,
} from "../../state/searchAISlice/helper"
import "./style.sass"
import { useDispatch, useSelector } from "../../state/hooks"
import { setSearchInput, setSelectedLocations } from "../../state/searchAISlice"
import ContentFilters from "./ContentFilters"

const DISABLED_NETWORKS: Network[] = [ Network.Facebook, Network.Snapchat, Network.Tiktok ]

export default function AISearchFilter(): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.AISearchFilter" })

  const input = useSelector(({ searchAI }) => searchAI.searchAIInput)
  const dispatch = useDispatch()

  const resetQuickFilters = () => {
    const newInput = cloneSearchInput(input)
    newInput.socialNetworks = [ Network.Youtube, Network.Instagram ]
    newInput.prompts = []
    dispatch(setSearchInput(newInput))
  }

  const resetAllFilters = () => {
    const newInput = cloneSearchInput(input)
    newInput.contentParams = initialContentInput()
    newInput.profileParams = initialProfileInput()
    dispatch(setSearchInput(newInput))
    dispatch(setSelectedLocations([]))
  }

  return (
    <>
      <Stack
        id="cp_component_ai-search-filter"
        padding={ 2 }
        className="cp_component_ai-search-filter"
        gap={ 2 }
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={ 1 }>
          <Typography fontSize="1rem" fontWeight={ TYPOGRAPHY.FONT_WEIGHT.BOLDER }>
            { translate("Quick Filters") }
          </Typography>
          <IconButton className="cp_component_ai-search-filter-refresh" size="small" onClick={ resetQuickFilters }>
            <RefreshIcon />
          </IconButton>
        </Stack>
        <PromptAI />
        <NetworkFilter disabledNetworks={ DISABLED_NETWORKS } hideDisabled={ false } />
      </Stack>
      <Stack
        mt={ 2 }
        id="cp_component_ai-search-filter-all"
        padding={ 2 }
        className="cp_component_ai-search-filter"
        gap={ 2 }
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={ 1 }>
          <Typography fontSize="1rem" fontWeight={ TYPOGRAPHY.FONT_WEIGHT.BOLDER }>
            { translate("All Filters") }
          </Typography>
          <IconButton className="cp_component_ai-search-filter-refresh" size="small" onClick={ resetAllFilters }>
            <RefreshIcon />
          </IconButton>
        </Stack>
        <ProfileFilters />

        { /* removing for phase 1
        <AudienceFilters />
        */ }
        <ContentFilters />
      </Stack>
    </>
  )
}
