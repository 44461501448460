import React, {
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import CheckIcon from "@mui/icons-material/Check"
import { useTranslation } from "react-i18next"

import Button from "../Button"
import Caption from "./Caption"
import IconButtonClose from "../IconButtonClose"
import LoadingIndicator from "../LoadingIndicator"
import { DeliverableCaptionFragment } from "../../graphql" // TODO: Replace with the new v2 schema type
import { setCaptionToApprove } from "../../state/campaignDeliverableContentModalV2Slice"
import { useDispatch, useSelector } from "../../state/hooks"

import "./caption-approval-confirmation.sass"

interface CaptionApprovalConfirmationProps {
  caption: DeliverableCaptionFragment
}

function CaptionApprovalConfirmation({ caption }: CaptionApprovalConfirmationProps): React.JSX.Element {
  const [ loading, setLoading ] = useState(false)
  const [ successClassName, setSuccessClassName ] = useState("")
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableContentTab" })

  const { deliverable } = useSelector(({ campaignDeliverableContentModalV2 }) => campaignDeliverableContentModalV2)

  const dispatch = useDispatch()

  const closeCard = useCallback(() => dispatch(setCaptionToApprove({ caption: undefined })), [])

  const approveCaption = useCallback(async () => {
    if (!deliverable) return
    setSuccessClassName("approved")
    setLoading(false)
    /* Replace with the new v2 mutation
    setLoading(true)
    const result = await approveDeliverableItem({ deliverableId: deliverable.id || "", deliverableItemId: caption.id })
    setLoading(false)
    if (isSuccess(result)) {
      dispatch(setEdited(true))
      setSuccessClassName("approved")
      if (result.payload) dispatch(setDeliverable({ deliverable: result.payload.acceptDeliverableItem }))
      setTimeout(() => {
        closeCard()
      }, 3000)
    }
    */
  }, [ deliverable, caption ])

  const buttonLabel: ReactNode = useMemo(() => {
    switch (true) {
      case loading:
        return <LoadingIndicator />
      case successClassName === "":
        return translate("YES, I APPROVE")
      case successClassName === "approved":
        return <CheckIcon fontSize="small" />
      default:
        return translate("YES, I APPROVE")
    }
  }, [ loading, successClassName ])

  return (
    <div className="cp_campaign-deliverable-caption-confirmation_component">
      <p className="body_large">
        { translate("Caption") }
      </p>
      <div className={ `cp_campaign-deliverable-caption-confirmation_component-columns ${ successClassName }` }>
        <Caption
          caption={ caption }
        />
        <div className="cp_campaign-deliverable-caption-confirmation_component-columns-right">
          <div className="cp_campaign-deliverable-caption-confirmation_component-columns-right-header">
            <IconButtonClose
              onClick={ closeCard }
              disabled={ loading || successClassName === "approved" }
            />
          </div>
          <div className="cp_campaign-deliverable-caption-confirmation_component-columns-right-body">
            <CheckCircleOutlineIcon className={ successClassName } />
            <p>
              {
              successClassName === "approved"
                ? translate(
                  "The following caption has been approved for",
                  { username: deliverable?.campaignNetworkAccount.socialAccount.userName || "" },
                )
                : translate("Are you sure you want to approve this post caption? You cannot undo this action.")
              }
            </p>
          </div>
          <div className="cp_campaign-deliverable-caption-confirmation_component-columns-right-footer">
            <Button
              onClick={ approveCaption }
              label={ buttonLabel }
              disabled={ loading || successClassName === "approved" }
            />
            <Button
              onClick={ closeCard }
              isPrimary={ false }
              disabled={ loading || successClassName === "approved" }
              label={ translate("CANCEL") }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CaptionApprovalConfirmation
