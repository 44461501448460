import React, { JSX } from "react"

import { UIMatch, useMatches } from "react-router-dom"
import * as Type from "../util/types"
import { useSelector } from "../state/hooks"
import ErrorPage from "../page/Error"
import LoadingPage from "../page/Loading"

import "./scopes-guard.sass"

interface HandleProps {
  scopes?: Type.Scope[]
}

export default function ScopesGuard(props: {
  children: React.ReactNode;
}): JSX.Element {
  const { scopes, user } = useSelector(({ user: userSlice }) => userSlice)
  const matches = useMatches() as UIMatch<unknown, HandleProps>[]

  const scopedMatches = matches
    .filter((match) => Boolean(match.handle?.scopes))

  const requiredScopes: Type.Scope[] = []

  scopedMatches.forEach((match) => {
    if (!match.handle.scopes) return
    requiredScopes.push(...match.handle.scopes)
  })

  const missingScopes = requiredScopes.filter((requiredScope) => !scopes.includes(requiredScope))

  if (user === "loading" || user === "init") {
    return <LoadingPage />
  }

  if (missingScopes.length > 0) {
    return (
      <ErrorPage statusCode={ 404 } />
    )
  }

  return <>{ props.children }</>
}
