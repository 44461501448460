import * as React from "react"
import { useTranslation } from "react-i18next"
import {
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"

import "./customer-table-context-menu.sass"
import IconButton from "../IconButton"
import { useSelector, useDispatch } from "../../state/hooks"
import {
  setModalCustomerResetPassword,
  setModalCustomerResetPasswordCustomer,
  setModalCustomerActivation,
  setModalCustomerActivationState,
} from "../../state/customersPageSlice"
import { Scope } from "../../util/types"
import { openEditCustomerUserModal } from "../../state/customerUserModalSlice"
import { CustomerTeamMemberFragment } from "../../graphql"

interface Props {
  id?: string
  userStatusCode?: string
  teamMember: CustomerTeamMemberFragment
  refreshTable: () => Promise<void>
}

export default function CustomerTableContextMenu({
  teamMember,
  refreshTable,
  id,
  userStatusCode,
}: Props) {
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null)
  const { t: translate } = useTranslation([], { keyPrefix: "component.CustomerTableContextMenu" })
  const { scopes } = useSelector(({ user }) => user)
  const dispatch = useDispatch()

  const onEditClick = () => {
    setAnchorEl(null)
    dispatch(openEditCustomerUserModal({
      userIdToEdit: teamMember.id,
      customerId: teamMember.customer.id,
      updateCallback: refreshTable,
    }))
  }

  const onResetUserPasswordClick = () => {
    dispatch(setModalCustomerResetPasswordCustomer({
      email: teamMember.contact.email.address,
      name: teamMember.contact.name,
      vanity: teamMember.customer.vanity,
    }))
    dispatch(setModalCustomerResetPassword(true))
  }

  const onActivateClick = () => {
    if (!teamMember.customer.company) return
    dispatch(setModalCustomerActivationState({
      id: teamMember.id,
      userName: teamMember.contact.name,
      companyName: teamMember.customer.company.name,
      activate: true,
      updateCallback: refreshTable,
    }))
    dispatch(setModalCustomerActivation(true))
  }

  const onDeactivateClick = () => {
    if (!teamMember.customer.company) return
    dispatch(setModalCustomerActivationState({
      id: teamMember.id,
      userName: teamMember.contact.name,
      companyName: teamMember.customer.company.name,
      activate: false,
      updateCallback: refreshTable,
    }))
    dispatch(setModalCustomerActivation(true))
  }

  return (
    <>
      <IconButton
        id={ id }
        className="cp_customer-table-context-menu_icon-button"
        onClick={ (e) => {
          e.stopPropagation()
          setAnchorEl(e.currentTarget)
        } }
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={ anchorEl }
        open={ Boolean(anchorEl) }
        onClose={ () => setAnchorEl(null) }
        className="cp_customer-table-context-menu_menu-component"
        transformOrigin={ {
          horizontal: "center",
          vertical: "top",
        } }
      >
        <MenuList
          dense={ true }
          className="menu-list"
          id="cp_customer-table-context-menu_menu-component"
        >
          { scopes.includes(Scope.FEATURE_CUSTOMER_MANAGEMENT) && (
          <MenuItem
            className="menu-item"
            id-="cp_customer-table-context-menu_edit-button"
            onClick={ onEditClick }
          >
            <ListItemText className="menu-item-label">
              { translate("Edit User") }
            </ListItemText>
          </MenuItem>
          ) }
          { scopes.includes(Scope.USER_UPDATE) && (
            <>
              <MenuItem
                className="menu-item cp_customer-table-context-menu_reset-password"
                onClick={ () => {
                  setAnchorEl(null)
                  onResetUserPasswordClick()
                } }
              >
                <ListItemText className="menu-item-label">
                  { translate("Reset User Password") }
                </ListItemText>
              </MenuItem>
              <MenuItem
                className={ `menu-item 
                  ${ userStatusCode === "active"
                  ? "cp_customer-table-context-menu_deactivate"
                  : "cp_customer-table-context-menu_reactivate"
                  }`
                }
                onClick={ () => {
                  if (userStatusCode === "active") {
                    onDeactivateClick()
                  } else {
                    onActivateClick()
                  }
                  setAnchorEl(null)
                } }
              >
                <ListItemText className="menu-item-label">
                  { userStatusCode === "active" ? translate("Deactivate") : translate("Reactivate") }
                </ListItemText>
              </MenuItem>
            </>
          ) }
        </MenuList>
      </Menu>
    </>
  )
}
