import React, {
  useEffect,
  useMemo,
  useState,
} from "react"
import "./style.sass"
import { useNavigate, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { EditOutlined, Tune as TuneIcon } from "@mui/icons-material"
import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridRowSelectionModel,
  GridTreeNode,
  GridValidRowModel,
} from "@mui/x-data-grid-pro"
import GroupAccountsLabels from "./labels"
import { useDispatch, useSelector } from "../../../state/hooks"
import { RootState } from "../../../state/store"
import * as TableContent from "./TableContent"
import {
  getCommunicationGroup,
  getGroupAccounts,
  setGroupAccountReloadStatus,
} from "../../../state/communicationsPage"
import {
  ContactModalProps,
  resetContactInfoModal,
  setContactInfoModalData,
  setContactInfoModalOpen,
  setSocialAccountId,
  setSocialAvatarInfo,
} from "../../../state/groupAccountsContactInfoModal"
import { setModalOpenState as setFiltersModalState } from "../../../state/ModalGroupAccountsFilterSlice"
import { seteditCommGroupID, setCommGroupModalOpen } from "../../../state/ModalCommGroupSlice"
import SearchBar from "../../SearchBar"
import IconButton from "../../IconButton"
import { Scope, Toast } from "../../../util/types"
import Button from "../../Button"
import { pushToast } from "../../../state/toastSlice"
import * as API from "../../../util/apiClient"

/**
 * Type for the parameter, when clicking on a cell in the table
 */
type TableDataGridParams = GridCellParams<any, unknown, unknown, GridTreeNode>

/**
 * Component to render the content for group accounts for a specific campaign and/or list of accounts
 * @returns Renders the content of the group accounts
 */
export default function GroupAccounts() {
  // ************************ Local state ************************
  const [ rowSelections, setRowSelections ] = useState<GridRowSelectionModel>([])
  const [ searchInputValue, setSearchInputValue ] = useState("")

  // ************************ Component variables ************************
  const navigate = useNavigate()
  const { t: translate } = useTranslation([], { keyPrefix: "component.GroupAccountsBody" })
  const dispatch = useDispatch()
  const { commGroupID, vanity } = useParams()
  const tableHeaders: GridColDef[] = TableContent.getColumnHeaders(commGroupID || "", searchInputValue, translate)

  // ************************ Selectors ************************
  const { scopes } = useSelector(({ user }) => user)
  const {
    communicationGroupAccounts,
    relaodGroupAccounts: reload,
    communicationGroup: commsGroup,
    groupAccountRows: rawRows,
  } = useSelector((root: RootState) => root.communicationsPage)
  const { selectedFilters: filters } = useSelector((root: RootState) => root.groupAccountsFilters)

  // ************************ React hooks ************************
  const rows: (GridValidRowModel | null)[] = useMemo(() => rawRows
    .filter((row) => TableContent.applyFiltersToRow(row, filters))
    .map((row) => TableContent.getRowData(row)), [ rawRows, filters ])

  /**
   * Called initially to ensure that the communication group information is available in state
   */
  useEffect(() => {
    if (commsGroup === "init" && commGroupID) {
      dispatch(getCommunicationGroup({
        id: commGroupID,
      }))
    }
  }, [])

  /**
   * Function to load the group accounts for this particular communication group
   */
  useEffect(() => {
    // Check to see if reloading the content after updating contact information
    if (reload) {
      dispatch(setGroupAccountReloadStatus(false))
      dispatch(getGroupAccounts({
        startsWith: searchInputValue,
        communicationGroupId: `${ commGroupID }`,
      }))
    }
  }, [ reload ])

  /**
   * Function to load the group accounts when search input value has changed
   */
  useEffect(() => {
    dispatch(getGroupAccounts({
      startsWith: searchInputValue,
      communicationGroupId: `${ commGroupID }`,
    }))
  }, [ searchInputValue ])

  useEffect(() => {
    if (API.isSuccess(communicationGroupAccounts)) {
      if (rawRows.length > 0) {
        // Check to see if there are no rows that will display
        const showErrorToast = (rawRows.filter((row) => TableContent.applyFiltersToRow(row, filters)).length === 0)
          || (searchInputValue !== "" && rawRows.length === 0)

        // Show error taost
        if (showErrorToast) {
          // Create and submit toast
          const toast: Toast = {
            type: "error",
            message: translate("No network accounts found"),
          }
          dispatch(pushToast(toast))
        }
      }
    }
  }, [ communicationGroupAccounts, filters ])

  // ************************ functions ************************
  /**
   * Update the state value for search input, which should trigger a reload of the group accounts
   * @param event The element that contains the information on the search bar
   */
  const onSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Placeholder
    setSearchInputValue(event.target.value)
  }

  const onEditClick = () => {
    if (commGroupID) {
      dispatch(seteditCommGroupID(commGroupID))
      dispatch(setCommGroupModalOpen(true))
    }
  }

  const onAddAccountClick = () => {
    // Placeholder
    navigate(`/${ vanity }/communications/group/${ commGroupID }/accounts/search`)
  }

  /**
   * Handles any clicks on cells in the table
   * @param param0 The cell and row that was clicked on in the table
   */
  const cellClickAction = ({ field, row }: TableDataGridParams) => {
    // Check to see if the contact info column was clicked on
    if (field === "contactInfo") {
      // Reset the form
      dispatch(resetContactInfoModal())

      // Set account id
      dispatch(setSocialAccountId(row.account.id))

      // Set the social avatar information
      dispatch(setSocialAvatarInfo({
        ...row.account,
      }))

      // Set modal data
      const modalData: ContactModalProps = {
        firstName: row.contactInfo.firstName,
        lastName: row.contactInfo.lastName,
        primaryEmail: row.contactInfo.email,
        websiteUrl: row.account.websiteUrl,
        emails: row.account.emails,
        emailsFromTeam: row.account.emailsFromTeam,
      }

      // Set required fields
      dispatch(setContactInfoModalData(modalData))

      // Display the modal
      dispatch(setContactInfoModalOpen(true))
    }
  }

  const openFilterModal = () => {
    // Open the modal
    dispatch(setFiltersModalState(true))
  }

  // ************************ Pre-Render computations ************************

  // Render the component
  return (
    <div className="cp_component_communications-group-accounts-container">
      <div className="header-section">
        <SearchBar id="search-bar" className="search-bar" onChange={ (event) => onSearchInput(event) } />
        <div className="details-container">
          <IconButton id="group-accounts-filter-modal-btn" variant="outlined" onClick={ openFilterModal }>
            <TuneIcon />
          </IconButton>
          { scopes.includes(Scope.COMMUNICATIONS_MANAGEMENT) && (
            <GroupAccountsLabels
              rowSelections={ rowSelections.map((rowId) => rowId.toString()) }
              onRowSelectionChange={ setRowSelections }
            />
          ) }
          <IconButton variant="outlined" onClick={ onEditClick }>
            <EditOutlined />
          </IconButton>
          { scopes.includes(Scope.COMMUNICATIONS_MANAGEMENT) && (
            <Button
              id="ga-add-account-button"
              className="add-account-button"
              label={ translate("+ ADD ACCOUNT") }
              onClick={ onAddAccountClick }
            />
          ) }
        </div>
      </div>
      <div className="communications-body">
        <DataGridPro
          checkboxSelection={ true }
          onRowSelectionModelChange={ (rowSelectionModel) => setRowSelections(rowSelectionModel) }
          rowSelectionModel={ rowSelections }
          columns={ tableHeaders }
          disableRowSelectionOnClick={ true }
          hideFooter={ true }
          rowHeight={ 80 }
          rows={ rows }
          loading={ (communicationGroupAccounts === "init" || communicationGroupAccounts === "loading") }
          pinnedColumns={ {
            left: [ "__check__", "account" ],
            right: [ "groupsContextMenu" ],
          } }
          onCellClick={ cellClickAction }
        />
      </div>
    </div>
  )
}
