import React from "react"
import { Link } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import IconButton from "../IconButton"

import "./editor-attachment.sass"

type AttachmentProps = {
  id: string
  url: string
  attachementName: string
  deleteAttachement: ((id: string) => void)
}

function EditorAttachment({
  id, url, attachementName, deleteAttachement,
}: AttachmentProps) {
  const handleDeleteClick = () => {
    deleteAttachement(id)
  }

  return (
    <div className="cp_rte-attachment">
      <Link href={ url } target="_blank" underline="none">
        { attachementName }
      </Link>
      <IconButton
        className="cp_rte-delete-attachment"
        size="small"
        disabled={ false }
        onClick={ handleDeleteClick }
      >
        <CloseIcon />
      </IconButton>
    </div>
  )
}

export default EditorAttachment
