import React, {
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import CheckIcon from "@mui/icons-material/Check"
import { useTranslation } from "react-i18next"

import Button from "../Button"
import DeliverableMediaCard from "./MediaCard"
import IconButtonClose from "../IconButtonClose"
import LoadingIndicator from "../LoadingIndicator"
import { DeliverableMediaFragment } from "../../graphql"
import { setMediaToApprove } from "../../state/campaignDeliverableContentModalV2Slice"
import { useDispatch, useSelector } from "../../state/hooks"

import "./media-approval-confirmation.sass"

interface MediaApprovalConfirmationProps {
  deliverableMedia: DeliverableMediaFragment
}

function MediaApprovalConfirmation({ deliverableMedia }: MediaApprovalConfirmationProps): React.JSX.Element {
  const [ loading, setLoading ] = useState(false)
  const [ successClassName, setSuccessClassName ] = useState("")
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableContentTab" })

  const { deliverable } = useSelector(({ campaignDeliverableContentModalV2 }) => campaignDeliverableContentModalV2)

  const dispatch = useDispatch()

  const closeCard = useCallback(() => dispatch(setMediaToApprove({ media: undefined })), [])

  const approveMedia = useCallback(async () => {
    setLoading(false)
    setSuccessClassName("approved")
    /* TBD in ID-3148.Integrate with the api-v2 mutation
    if (!deliverable) return
    setLoading(true)
    const result = await approveDeliverableItem({ deliverableId: deliverable.id || "", deliverableItemId: deliverableMedia.id })
    setLoading(false)
    /* if (isSuccess(result)) {
      dispatch(setEdited(true))
      setSuccessClassName("approved")
      if (result.payload) dispatch(setDeliverable({ deliverable: result.payload.acceptDeliverableItem }))
      setTimeout(() => {
        closeCard()
      }, 3000)
    }
    */
  }, [ deliverable, deliverableMedia ])

  const buttonLabel: ReactNode = useMemo(() => {
    switch (true) {
      case loading:
        return <LoadingIndicator />
      case successClassName === "":
        return translate("YES, I APPROVE")
      case successClassName === "approved":
        return <CheckIcon fontSize="small" />
      default:
        return translate("YES, I APPROVE")
    }
  }, [ loading, successClassName ])

  return (
    <div className="cp_campaign-deliverable-media-confirmation_component">
      <p className="body_large">
        { translate("Media*") }
      </p>
      <div className={ `cp_campaign-deliverable-media-confirmation_component-columns ${ successClassName }` }>
        <DeliverableMediaCard
          key={ deliverableMedia.id }
          deliverableMedia={ deliverableMedia }
        />
        <div className="cp_campaign-deliverable-media-confirmation_component-columns-right">
          <div className="cp_campaign-deliverable-media-confirmation_component-columns-right-header">
            <IconButtonClose
              onClick={ closeCard }
              disabled={ loading || successClassName === "approved" }
            />
          </div>
          <div className="cp_campaign-deliverable-media-confirmation_component-columns-right-body">
            <CheckCircleOutlineIcon className={ successClassName } />
            <p>
              {
              successClassName === "approved"
                ? translate(
                  "The following post has been approved for",
                  { username: deliverable?.campaignNetworkAccount.socialAccount.userName || "" },
                )
                : translate("Are you sure you want to approve this post media? You cannot undo this action.")
              }
            </p>
          </div>
          <div className="cp_campaign-deliverable-media-confirmation_component-columns-right-footer">
            <Button
              onClick={ approveMedia }
              label={ buttonLabel }
              disabled={ loading || successClassName === "approved" }
            />
            <Button
              onClick={ closeCard }
              isPrimary={ false }
              disabled={ loading || successClassName === "approved" }
              label={ translate("CANCEL") }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MediaApprovalConfirmation
