import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import "./select-caption-input-step.sass"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material"
import { ArrowForwardIos } from "@mui/icons-material"
import Input from "../Input"
import { useDispatch, useSelector } from "../../state/hooks"
import {
  pushStepCompleted,
  removeStepCompleted,
  setCaption,
  setSelectedCaptionId,
} from "../../state/ModalCreateDeliverable"
import Divider from "../Divider"
import Checkbox from "../Checkbox"
import { SnippetFragment } from "../../graphql"

const INPUT_STEP = 2

export default function SelectCaptionInputStep() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalCreateDeliverable" })
  const {
    conversation,
    caption,
    stepsCompleted,
    selectedCaptionId,
  } = useSelector(({ modalCreateDeliverable }) => modalCreateDeliverable)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!stepsCompleted.includes(INPUT_STEP) && caption.length > 0) dispatch(pushStepCompleted(INPUT_STEP))
    if (stepsCompleted.includes(INPUT_STEP) && caption.length === 0) dispatch(removeStepCompleted(INPUT_STEP))
  }, [ caption ])

  const [ expanded, setExpanded ] = useState<null | "select-caption" | "custom-caption">(null)
  const [ customCaption, setCustomCaption ] = useState("")

  const handleAccordionClick = (
    panel: "select-caption" | "custom-caption",
  ) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : null)
  }

  const handleCaptionClick = (snippet: SnippetFragment) => {
    setCustomCaption("")
    if (snippet.id === selectedCaptionId) {
      dispatch(setSelectedCaptionId(""))
      dispatch(setCaption(""))
    } else {
      dispatch(setSelectedCaptionId(snippet.id))
      dispatch(setCaption(snippet.text))
    }
  }

  const handleCustomCaptionInput = (input: string) => {
    if (selectedCaptionId) dispatch(setSelectedCaptionId(""))
    setCustomCaption(input)
    dispatch(setCaption(input))
  }

  return (
    <div className="cp_component_select-caption-input-step">
      <h4>{ `${ translate("Deliverable Caption") }*` }</h4>

      <Accordion
        id="cp_component_modal-create-deliverable-select-caption-accordion"
        disableGutters={ true }
        expanded={ expanded === "select-caption" }
        onChange={ handleAccordionClick("select-caption") }
      >
        <AccordionSummary
          className={ expanded === "select-caption" ? "active-accordion" : "" }
          expandIcon={ <ArrowForwardIos /> }
        >
          <h5>{ translate("Select Caption") }</h5>
          <p className="accordion-summary-subtext">
            { `- ${ translate("Choose a caption from the creator's Content Drop Box submissions.") }` }
          </p>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />
          { conversation?.conversationThread.snippets.map((snippet) => (
            <div className="snippet-selection" key={ snippet.id }>
              <Checkbox
                id="cp_component_create-deliverable-modal_snippet-checkbox"
                checked={ selectedCaptionId === snippet.id }
                onClick={ () => handleCaptionClick(snippet) }
              />
              <p>{ snippet.text }</p>
            </div>
          )) }
        </AccordionDetails>
      </Accordion>

      <Accordion
        id="cp_component_modal-create-deliverable-custom-caption-accordion"
        disableGutters={ true }
        expanded={ expanded === "custom-caption" }
        onChange={ handleAccordionClick("custom-caption") }
      >
        <AccordionSummary
          className={ expanded === "custom-caption" ? "active-accordion" : "" }
          expandIcon={ <ArrowForwardIos /> }
        >
          <h5>{ translate("Custom Caption") }</h5>
          <p className="accordion-summary-subtext">{ `- ${ translate("Create your own caption!") }` }</p>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />
          <div className="custom-caption-input-label">
            <p className="label_small-caps-bold">{ `${ translate("Enter Custom Caption") }` }</p>
            <p className="optional-text">
              { translate("(Optional)") }
            </p>
          </div>
          <Input
            id="cp_component_modal-create-deliverable-custom-caption-input"
            className="custom-caption-input"
            value={ customCaption }
            multiline={ true }
            maxRows={ 2 }
            onChange={ (e) => handleCustomCaptionInput(e.target.value) }
            placeholder={ translate("Enter Caption...") }
          />
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
