import React, { useMemo } from "react"
import dayjs from "dayjs"

import * as Constant from "../../util/constant"
import Divider from "../Divider"
import { DeliverableItemFeedbackFragment } from "../../graphql" // TODO: Replace with the v2 schema type

import "./feedback-card.sass"

interface DeliverableFeedbackCardProps {
  feedback: DeliverableItemFeedbackFragment
}

function DeliverableFeedbackCard({ feedback }: DeliverableFeedbackCardProps): React.JSX.Element {
  const formattedDate = useMemo(() => dayjs(feedback.created * 1000)
    .format(Constant.LONGFORM_DATE_TIME), [ feedback.created ])
  return (
    <div className="cp_media-feedback-card_component">
      <div className="cp_media-feedback-card_component-header">
        <div className="cp_media-feedback-card_component-header-name">
          <p className="cp_media-feedback-card_component-header-name-tag">
            @
            { feedback.reviewer.username }
          </p>
          <p className="cp_media-feedback-card_component-header-name-company">
            { feedback.reviewer.customer.company?.name }
          </p>
        </div>
        <div className="cp_media-feedback-card_component-header-date">
          { formattedDate }
        </div>
      </div>
      <Divider />
      <div className="cp_media-feedback-card_component-body">
        <p>{ feedback.text }</p>
      </div>
    </div>
  )
}

export default DeliverableFeedbackCard
