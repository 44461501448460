import React, { useMemo } from "react"
import dayjs from "dayjs"
import { GridColDef } from "@mui/x-data-grid"
import { useNavigate, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import * as Constant from "../../../util/constant"
import * as GraphQL from "../../../graphql"
import DataGrid from "../../DataGrid"
import EmptyElement from "../../EmptyElement"
import EmptySplash from "../../EmptySplash"
import EntityInfoRow from "../../EntityInfoRow"
import Timestamp from "../../DataGrid/RenderCellComponents/Timestamp"

import "./communications-table.sass"

type Props = {
    comms: GraphQL.CommunicationGroupRowFragment[]
}

export default function CommunicationsTable({ comms }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ProfileTabCommsTable" })
  const navigate = useNavigate()
  const { vanity } = useParams()

  const COLUMNS: GridColDef[] = useMemo(() => [
    {
      field: "name",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      resizeable: false,
      flex: 1,
      headerName: translate("Name"),
      renderCell: (params) => (
        <EntityInfoRow
          avatarSize="md"
          name={ params.row.name }
        />
      ),
    },
    {
      field: "created",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      resizeable: false,
      flex: 1,
      headerName: translate("Created"),
      renderCell: (params) => (
        <Timestamp
          time={ dayjs(params.row.created * 1000)
            .format(Constant.LONGFORM_DATE).toUpperCase() }
        />
      ),
    },
    {
      field: "updated",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      resizeable: false,
      flex: 1,
      headerName: translate("Updated"),
      renderCell: (params) => (
        <Timestamp
          time={ dayjs(params.row.updated * 1000)
            .format(Constant.LONGFORM_DATE).toUpperCase() }
        />
      ),
    },
  ], [ translate ])

  return (
    <>
      <DataGrid
        className="cp_profile-tab_communications-table"
        getRowId={ (row) => row.id }
        onRowClick={ (params) => navigate(`/${ vanity }/communications/group/${ params.id }/conversations`) }
        disableRowSelectionOnClick={ true }
        rowHeight={ 90 }
        columnHeaderHeight={ 40 }
        columns={ COLUMNS }
        disableColumnReorder={ true }
        hideFooter={ true }
        pinnedColumns={ {
          left: [ "list" ],
          right: [ "ellipsisMenu" ],
        } }
        rows={ comms }
        slots={ {
          noResultsOverlay: EmptyElement,
          noRowsOverlay: EmptyElement,
        } }
      />
      { comms.length === 0 && (
        <EmptySplash bodyText={ translate("No groups found") } />
      ) }
    </>
  )
}
