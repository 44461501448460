import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import "./style.sass"
import { GridColDef, GridColumnHeaderTitle } from "@mui/x-data-grid"
import { LinearProgress } from "@mui/material"
import dayjs from "dayjs"
import { Visibility, Check } from "@mui/icons-material"
import { useParams, useNavigate } from "react-router-dom"
import DataGrid from "../DataGrid"
import { CampaignForCustomerUserRowFragment, CampaignPermissionType } from "../../graphql"
import Timestamp from "../DataGrid/RenderCellComponents/Timestamp"
import "./user-campaign-table.sass"
import Avatar from "../Avatar"
import { MONTH_DAY_ABBR_FORMAT } from "../../util/constant"
import { useSelector } from "../../state/hooks"
import { isSuccess } from "../../util/apiClient"

type Props = {
  campaigns: CampaignForCustomerUserRowFragment[]
  fetchMore: () => void
  isLoading: boolean
}

function NoCampaignsOverlay() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CustomerUserCampaignsTable" })
  return (
    <div className="cp_component_user-campaigns-table-no-results">
      { translate("No matching campaigns.") }
    </div>
  )
}

export default function UserCampaignsTable({
  campaigns,
  isLoading,
  // backend throws error when fetchMore is called
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  fetchMore,
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CustomerUserCampaignsTable" })
  const {
    userID,
    vanity,
  } = useParams()

  const { user } = useSelector(({ user: userSlice }) => userSlice)

  const authenticatedCustomerId = React.useMemo(() => {
    if (!isSuccess(user) || !user.payload.currentUser) return ""
    return user.payload.currentUser.customer.id || ""
  }, [ user ])

  const navigate = useNavigate()

  const COLUMNS: GridColDef[] = useMemo(() => [
    {
      field: "campaign",
      sortable: false,
      renderHeader: () => (
        <>
          <GridColumnHeaderTitle columnWidth={ 10 } label={ translate("Campaign") } />
        </>
      ),
      renderCell: (params) => (
        <div className={ `campaign ${ params.row.cellClassName }` }>
          <Avatar size="lg" src={ params.row.avatar }>
            { params.row.name.charAt(0) }
          </Avatar>
          <p>
            { params.row.name }
          </p>
        </div>
      ),
      disableColumnMenu: true,
      resizable: false,
      flex: 2,
    },
    {
      field: "created",
      renderHeader: () => (
        <>
          <GridColumnHeaderTitle columnWidth={ 10 } label={ translate("Created") } />
        </>
      ),
      sortable: false,
      renderCell: (params) => (
        <div className={ params.row.cellClassName }>
          <Timestamp
            id="cp_component-customer-table_time-customer-timestamp"
            time={
            dayjs(params.row.created * 1000)
              .format(MONTH_DAY_ABBR_FORMAT).toUpperCase()
          }
          />
        </div>
      ),
      disableColumnMenu: true,
      resizable: false,
      flex: 1,
    },
    {
      field: "type",
      renderHeader: () => (
        <>
          <GridColumnHeaderTitle columnWidth={ 10 } label={ translate("Type") } />
        </>
      ),
      sortable: false,
      renderCell: (params) => (
        <p className={ `${ params.row.cellClassName }` }>
          { params.row.type }
        </p>
      ),
      disableColumnMenu: true,
      resizable: false,
      flex: 1,
    },
    {
      field: "managerType",
      renderHeader: () => (
        <>
          <GridColumnHeaderTitle columnWidth={ 10 } label={ translate("Manager Type") } />
        </>
      ),
      sortable: false,
      renderCell: (params) => (
        <p className={ `${ params.row.cellClassName }` }>
          { params.row.managerType }
        </p>
      ),
      disableColumnMenu: true,
      resizable: false,
      flex: 1,
    },
    {
      field: "permissions",
      renderHeader: () => (
        <>
          <GridColumnHeaderTitle columnWidth={ 10 } label={ translate("Permissions") } />
        </>
      ),
      sortable: false,
      renderCell: (params) => (
        <div className={ `permissions ${ params.row.cellClassName }` }>
          { params.row.permissions.includes(CampaignPermissionType.View) && <Visibility color="inherit" /> }
          { params.row.permissions.includes(CampaignPermissionType.Approve) && <Check color="inherit" /> }
          { params.row.permissions.length === 0 && <p>{ translate("N/A") }</p> }
        </div>
      ),
      disableColumnMenu: true,
      resizable: false,
      flex: 1.4,
    },
    {
      field: "customer",
      renderHeader: () => (
        <>
          <GridColumnHeaderTitle columnWidth={ 10 } label={ translate("Customer") } />
        </>
      ),
      sortable: false,
      renderCell: (params) => (
        <p className={ `${ params.row.cellClassName }` }>
          { params.row.customer }
        </p>
      ),
      disableColumnMenu: true,
      resizable: false,
      flex: 1.4,
    },
  ], [ translate ])

  return (
    <DataGrid
      className="cp_component_user-campaigns-table"
      rowHeight={ 100 }
      columnHeaderHeight={ 40 }
      disableColumnReorder={ true }
      disableRowSelectionOnClick={ true }
      hideFooter={ true }
      columns={ COLUMNS }
      rows={ campaigns.map((campaign) => ({
        id: campaign.id,
        avatar: campaign.thumbnail?.url?.address || "",
        name: campaign.name,
        created: campaign.created,
        campaign,
        permissions: campaign.userPermissions.find((permission) => permission.user.id === userID)?.permissions || [],
        type: campaign.enableTiktok ? translate("TikTok") : translate("Standard"),
        customer: campaign.creator.customer.vanity,
        managerType: campaign.userPermissions.find((permission) => permission.user.id === userID)
          ? translate("Brand Manager") : translate("Campaign Manager"),
        creatorID: campaign.creator.customer.id,
        cellClassName: campaign.creator.customer.id !== authenticatedCustomerId ? "not-creator" : "",
      })) }
      onRowClick={ (params) => {
        if (params.row.creatorID !== authenticatedCustomerId) return
        navigate(`/${ vanity }/campaigns/${ params.row.id }/overview`)
      } }
      pinnedColumns={ {
        left: [ "name" ],
        right: [ "ellipsisMenu" ],
      } }
      loading={ isLoading }
      scrollEndThreshold={ 200 }
      slots={ {
        loadingOverlay: LinearProgress,
        noRowsOverlay: NoCampaignsOverlay,
        noResultsOverlay: NoCampaignsOverlay,
      } }
    />
  )
}
