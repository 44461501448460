import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"

// Comms create template modal slice Interface and Initial State
export interface ModalCreateDeliverableRequirementsState {
  noCampaignAssociatedWarningOpen: boolean
  noUserAssociatedWithCampaignModalOpen: boolean
  user?: {
    id: string,
    name: string
  }
  campaign?: {
    id: string,
    name: string
  }
}

const initialState: ModalCreateDeliverableRequirementsState = {
  noCampaignAssociatedWarningOpen: false,
  noUserAssociatedWithCampaignModalOpen: false,
}

// Modal Create Deliverable Slice
export const modalCreateDeliverableRequirements = createSlice({
  name: "ModalCreateDeliverableRequirements",
  initialState,
  reducers: {
    setNoCampaignAssociatedWarningOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      noCampaignAssociatedWarningOpen: action.payload,
    }),
    openNoUserAssociatedWithCampaignModalOpen: (
      state,
      action: PayloadAction<{
        user: {
          id: string,
          name: string
        },
        campaign: {
          id: string,
          name: string
        }
      }>,
    ) => ({
      ...state,
      noUserAssociatedWithCampaignModalOpen: true,
      user: action.payload.user,
      campaign: action.payload.campaign,
    }),
    closeNoUserAssociatedWithCampaignModalOpen: (
      state,
    ) => ({
      ...state,
      noUserAssociatedWithCampaignModalOpen: false,
      user: undefined,
      campaign: undefined,
    }),
  },
})

export const {
  setNoCampaignAssociatedWarningOpen,
  closeNoUserAssociatedWithCampaignModalOpen,
  openNoUserAssociatedWithCampaignModalOpen,
} = modalCreateDeliverableRequirements.actions
export default modalCreateDeliverableRequirements.reducer

export const addUserToCampaign = (params: {
  variables: GraphQL.CreateCampaignNetworkAccountMutationVariables,
  onSuccess: () => void,
  onError: () => void
}) => async () => {
  const result = await API.createCampaignNetworkAccount(params.variables)
  if (API.isSuccess(result)) {
    params.onSuccess()
  } else {
    params.onError()
  }
}
