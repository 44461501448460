import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "../../state/hooks"
import Divider from "../Divider"
import "./review-and-create-step.sass"

export default function ReviewAndCreateStep() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalCreateDeliverable" })
  const {
    conversation,
    caption,
    deliverableTitle,
    selectedMedia,
  } = useSelector(({ modalCreateDeliverable }) => modalCreateDeliverable)

  return (
    <div className="cp_component_review-and-create-step">
      <h4>{ translate("Review & Create!") }</h4>
      <p className="sub-title">
        { translate("Review deliverable for") }
        { " " }
        <b>{ conversation?.socialAccount.userName || "" }</b>
        { " " }
        { translate("in") }
        { " " }
        <b>{ conversation?.communicationGroup.campaign?.name || "" }</b>
      </p>

      <Divider />

      <div>
        <p className="label_small-caps-bold">{ `${ translate("Deliverable Title") }*` }</p>
        <p className="deliverable-title">{ deliverableTitle }</p>
      </div>

      <div>
        <p className="label_small-caps-bold">{ translate("Caption") }</p>
        <p className="caption">{ caption }</p>
      </div>

      <div>
        <p className="label_small-caps-bold">{ translate("Media") }</p>
        { selectedMedia.map((media) => <img src={ media.thumbnail?.url } alt={ media.name } />) }
      </div>
    </div>
  )
}
