import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"
import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import { Status } from "../../util/types"

export interface ModalCreateMessage {
  modalCreateMessageOpen: boolean,
  commTemplates: Status<GraphQL.GetCommunicationTemplatesQuery>,
  commGroupEmails: Status<GraphQL.SearchCommunicationGroupNetworkAccountQuery>,
  commSubmisssion: Status<GraphQL.CreateConversationsMutation | null>,
}

// Initialize state
const initialState: ModalCreateMessage = {
  modalCreateMessageOpen: false,
  commTemplates: "init",
  commGroupEmails: "init",
  commSubmisssion: "init",
}

export const ModalCreateMessageSlice = createSlice({
  name: "ModalCreateMessageSlice",
  initialState,
  reducers: {
    setModalCreateMessageOpen: (state, action: PayloadAction<boolean>) => ({
      ...state,
      modalCreateMessageOpen: action.payload,
    }),
    setCommTemplates: (
      state,
      action: PayloadAction<Status<GraphQL.GetCommunicationTemplatesQuery>>,
    ) => ({
      ...state,
      commTemplates: action.payload,
    }),
    setCommEmails: (
      state,
      action: PayloadAction<Status<GraphQL.SearchCommunicationGroupNetworkAccountQuery>>,
    ) => ({
      ...state,
      commGroupEmails: action.payload,
    }),
    setCommSubmission: (
      state,
      action: PayloadAction<Status<GraphQL.CreateConversationsMutation | null>>,
    ) => ({
      ...state,
      commSubmisssion: action.payload,
    }),
    resetCommSubmission: (state) => ({ ...state, commSubmisssion: "init" }),
  },
})

export const {
  setModalCreateMessageOpen,
  setCommTemplates,
  setCommEmails,
  setCommSubmission,
  resetCommSubmission,
} = ModalCreateMessageSlice.actions
export default ModalCreateMessageSlice.reducer

// Add Accounts to Lists Modal Slice Thunks
export const fetchCommTemplates = () => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setCommTemplates("loading"))

  const commTemplates = await API.fetchCommTemplates()

  dispatch(setCommTemplates(commTemplates))
}

export const fetchCommEmails = (
  communicationGroupId: string,
  startsWith: string,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setCommEmails("loading"))

  const commTemplates = await API.fetchCommunicationGroupEmails(communicationGroupId, startsWith)

  dispatch(setCommEmails(commTemplates))
}

export const createCommSubmission = (
  {
    communicationGroupId,
    subject,
    body,
    recipients,
    attachments,
    enableUploadLink,
  }: GraphQL.CreateConversationsMutationVariables,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setCommSubmission("loading"))

  const commSubmissionResponse = await API.createCommConversation(
    {
      communicationGroupId,
      subject,
      body,
      recipients,
      attachments,
      enableUploadLink,
    },
  )

  dispatch(setCommSubmission(commSubmissionResponse))
}
