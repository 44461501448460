/* eslint-disable react/no-unused-prop-types */
// NOTE: All props are being used, eslint is just dumb

import React, { JSX } from "react"
import { TFunction } from "i18next"
import { useTranslation } from "react-i18next"
import {
  ChatBubbleOutlineRounded,
  DownloadForOffline,
  ExitToApp,
  FastRewind,
  FastForward,
  FavoriteBorder,
  Group,
  PlayArrowOutlined,
  Share,
  Visibility,
  ScreenshotMonitorOutlined,
  SwipeUp,
} from "@mui/icons-material"

import * as GraphQL from "../../graphql"
import * as Misc from "../../util/miscHelper"
import Tooltip from "../Tooltip"

import "./style.sass"

type Props = {
  isReel: boolean,
  isStory: boolean,
  isLens: boolean,
  isSpotlightContent: boolean,
  isSaved: boolean,
  network: GraphQL.Network,
  postComments: number,
  postContent: string,
  postExits: number,
  postImpressions: number,
  postLikes: number,
  postPlays: number,
  postReach: number,
  postReachEstimated: number,
  postSaves: number,
  postShares: number,
  postScreenshots: number,
  postSwipeUps: number,
  postTapsBackward: number,
  postTapsForward: number,
  postViews: number,
  isSearchResultDisplay?: boolean
}

// ICONS WITH STATS
function renderComments(
  postComments: number,
  tooltipText: string,
): JSX.Element {
  return (
    <Tooltip arrow={ true } placeholder="bottom" title={ tooltipText }>
      <p className="label_large-caps-bold">
        <ChatBubbleOutlineRounded />
        { Misc.shorthandNumber(postComments) }
      </p>
    </Tooltip>
  )
}

function renderExits(postExits: number, tooltipText: string): JSX.Element {
  return (
    <Tooltip arrow={ true } placeholder="bottom" title={ tooltipText }>
      <p className="label_large-caps-bold">
        <ExitToApp />
        { Misc.shorthandNumber(postExits) }
      </p>
    </Tooltip>
  )
}

function renderScreenshots(screenshots: number, tooltipText: string): JSX.Element {
  return (
    <Tooltip arrow={ true } placeholder="bottom" title={ tooltipText }>
      <p className="label_large-caps-bold">
        <ScreenshotMonitorOutlined />
        { Misc.shorthandNumber(screenshots) }
      </p>
    </Tooltip>
  )
}

function renderImpressions(
  postImpressions: number,
  tooltipText: string,
): JSX.Element {
  return (
    <Tooltip arrow={ true } placeholder="bottom" title={ tooltipText }>
      <p className="label_large-caps-bold">
        <Visibility />
        { Misc.shorthandNumber(postImpressions) }
      </p>
    </Tooltip>
  )
}

function renderLikes(postLikes: number, tooltipText: string): JSX.Element {
  return (
    <Tooltip arrow={ true } placeholder="bottom" title={ tooltipText }>
      <p className="label_large-caps-bold">
        <FavoriteBorder />
        { Misc.shorthandNumber(postLikes) }
      </p>
    </Tooltip>
  )
}

function renderReach(postReach: number, tooltipText: string): JSX.Element {
  return (
    <Tooltip arrow={ true } placeholder="bottom" title={ tooltipText }>
      <p className="label_large-caps-bold">
        <Group />
        { Misc.shorthandNumber(postReach) }
      </p>
    </Tooltip>
  )
}

// TODO: Replace this when it is needed again
// function renderFavorites(postFavorites: number, tooltipText: string): JSX.Element {
//   return (
//     <Tooltip arrow={ true } placeholder="bottom" title={ tooltipText }>
//       <p className="label_large-caps-bold">
//         <FavoriteBorderOutlined />
//         { Misc.shorthandNumber(postFavorites) }
//       </p>
//     </Tooltip>
//   )
// }

function renderSwipeUps(postSwipeUps: number, tooltipText: string): JSX.Element {
  return (
    <Tooltip arrow={ true } placeholder="bottom" title={ tooltipText }>
      <p className="label_large-caps-bold">
        <SwipeUp />
        { Misc.shorthandNumber(postSwipeUps) }
      </p>
    </Tooltip>
  )
}

function renderSaves(postSaves: number, tooltipText: string): JSX.Element {
  return (
    <Tooltip arrow={ true } placeholder="bottom" title={ tooltipText }>
      <p className="label_large-caps-bold">
        <DownloadForOffline />
        { Misc.shorthandNumber(postSaves) }
      </p>
    </Tooltip>
  )
}

function renderShares(postShares: number, tooltipText: string): JSX.Element {
  return (
    <Tooltip arrow={ true } placeholder="bottom" title={ tooltipText }>
      <p className="label_large-caps-bold">
        <Share />
        { Misc.shorthandNumber(postShares) }
      </p>
    </Tooltip>
  )
}

function renderTapsBackward(postTapsBackward: number, tooltipText: string): JSX.Element {
  return (
    <Tooltip arrow={ true } placeholder="bottom" title={ tooltipText }>
      <p className="label_large-caps-bold">
        <FastRewind />
        { Misc.shorthandNumber(postTapsBackward) }
      </p>
    </Tooltip>
  )
}

function renderTapsForward(postTapsForward: number, tooltipText: string): JSX.Element {
  return (
    <Tooltip arrow={ true } placeholder="bottom" title={ tooltipText }>
      <p className="label_large-caps-bold">
        <FastForward />
        { Misc.shorthandNumber(postTapsForward) }
      </p>
    </Tooltip>
  )
}

function renderViews(postViews: number, tooltipText: string): JSX.Element {
  return (
    <Tooltip arrow={ true } placeholder="bottom" title={ tooltipText }>
      <p className="label_large-caps-bold">
        <PlayArrowOutlined />
        { Misc.shorthandNumber(postViews) }
      </p>
    </Tooltip>
  )
}

// NETWORK STAT GROUPS
function renderInstagramStats(props: Props, translate: TFunction): JSX.Element {
  // For Instagram Stories...
  if (props.isStory) {
    return (
      <aside className="cp_component_post-stats">
        { props.postReach === 0
          ? null
          : renderReach(props.postReach, translate("Reach"))
        }

        { props.postImpressions === 0
          ? null
          : renderImpressions(props.postImpressions, translate("Impressions"))
        }

        { props.postTapsBackward === 0
          ? null
          : renderTapsBackward(props.postTapsBackward, translate("Taps Backward"))
        }

        { props.postTapsForward === 0
          ? null
          : renderTapsForward(props.postTapsForward, translate("Taps Forward"))
        }

        { props.postExits === 0
          ? null
          : renderExits(props.postExits, translate("Exits"))
        }
      </aside>
    )
  }

  // For Reels...
  if (props.isReel) {
    return (
      <aside className="cp_component_post-stats">
        { renderLikes(props.postLikes, translate("Likes")) }
        { renderComments(props.postComments, translate("Comments")) }

        { props.postReach === 0
          ? null
          : renderReach(props.postReach, translate("Reach"))
        }

        { props.postPlays === 0
          ? null
          : renderViews(props.postPlays, translate("Plays"))
        }

        { props.postSaves === 0
          ? null
          : renderSaves(props.postSaves, translate("Saves"))
        }
      </aside>
    )
  }

  // For In-Feed Posts...
  return (
    <aside className="cp_component_post-stats">
      { renderLikes(props.postLikes, translate("Likes")) }
      { renderComments(props.postComments, translate("Comments")) }

      { props.postReach === 0
        ? null
        : renderReach(props.postReach, translate("Reach"))
      }

      { props.postImpressions === 0
        ? null
        : renderImpressions(props.postImpressions, translate("Impressions"))
      }

      { props.postSaves === 0
        ? null
        : renderSaves(props.postSaves, translate("Saves"))
      }
    </aside>
  )
}

function renderSnapStats(props: Props, translate: TFunction): JSX.Element {
  if (props.isSearchResultDisplay) {
    return (
      <aside className="cp_component_post-stats">
        { renderViews(props.postViews, translate("Views")) }
        { renderReach(props.postReach, translate("Viewers")) }
        { renderScreenshots(props.postScreenshots, translate("Screenshots")) }
        { renderShares(props.postShares, translate("Shares")) }
      </aside>
    )
  }
  if (props.isSpotlightContent) {
    return (
      <aside className="cp_component_post-stats">
        { renderViews(props.postViews, translate("Views")) }
        { renderReach(props.postReach, translate("Viewers")) }
        { renderScreenshots(props.postScreenshots, translate("Screenshots")) }
        { renderLikes(props.postLikes, translate("Favorites")) }
        { renderShares(props.postShares, translate("Shares")) }
        { renderSwipeUps(props.postSwipeUps, translate("Swipe Ups")) }
      </aside>
    )
  }
  if (props.isSaved) {
    return (
      <aside className="cp_component_post-stats">
        { renderViews(props.postViews, translate("Views")) }
        { renderReach(props.postReach, translate("Viewers")) }
        { renderComments(props.postComments, translate("Replies")) }
        { renderScreenshots(props.postScreenshots, translate("Screenshots")) }
        { renderShares(props.postShares, translate("Shares")) }
        { renderSwipeUps(props.postSwipeUps, translate("Swipe Ups")) }
      </aside>
    )
  }
  return (
    <aside className="cp_component_post-stats">
      { renderViews(props.postViews, translate("Views")) }
      { renderReach(props.postReach, translate("Viewers")) }
      { renderComments(props.postComments, translate("Replies")) }
      { renderScreenshots(props.postScreenshots, translate("Screenshots")) }
      { renderSwipeUps(props.postSwipeUps, translate("Swipe Ups")) }
      { renderTapsBackward(props.postTapsBackward, translate("Taps Backward")) }
      { renderTapsForward(props.postTapsForward, translate("Taps Forward")) }
      { renderShares(props.postShares, translate("Shares")) }
    </aside>
  )
}

export default function PostStats(props: Props): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.PostStats" })

  const viewIcon = props.postViews === 0
    ? null
    : renderViews(props.postViews, translate("Views"))

  switch (props.network) {
    case GraphQL.Network.Facebook:
      return (
        <aside className="cp_component_post-stats">
          { renderLikes(props.postLikes, translate("Likes")) }
          { renderComments(props.postComments, translate("Comments")) }
          { renderShares(props.postShares, translate("Shares")) }
        </aside>
      )

    case GraphQL.Network.Instagram:
      return renderInstagramStats(props, translate)

    case GraphQL.Network.Tiktok:
      return (
        <aside className="cp_component_post-stats">
          { renderLikes(props.postLikes, translate("Likes")) }
          { renderComments(props.postComments, translate("Comments")) }

          { props.postShares === 0
            ? null
            : renderShares(props.postShares, translate("Shares"))
          }

          { props.postViews === 0
            ? null
            : renderViews(props.postViews, translate("Views"))
          }
        </aside>
      )

    case GraphQL.Network.Youtube:
      return (
        <aside className="cp_component_post-stats">
          { viewIcon }
          { renderLikes(props.postLikes, translate("Likes")) }
          { renderComments(props.postComments, translate("Comments")) }
        </aside>
      )

    case GraphQL.Network.Snapchat:
      return renderSnapStats(props, translate)

    default:
      return (
        <aside className="cp_component_post-stats">
          { renderLikes(props.postLikes, translate("Likes")) }
          { renderComments(props.postComments, translate("Comments")) }
        </aside>
      )
  }
}
