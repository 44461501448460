import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Search as SearchIcon } from "@mui/icons-material"
import {
  InputAdornment,
  List,
  ListItem,
} from "@mui/material"
import Input from "../Input"
import { useDispatch, useSelector } from "../../state/hooks"

import "./style.sass"
import { setAssignReviewerSearchInput, setSelectedReviewerGroup } from "../../state/campaignDeliverableAssignReviewerModalSlice"

function SearchReviewerGroup(): React.JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableAssignReviewerGroup" })

  const dispatch = useDispatch()
  const { searchInput, selectedReviewerGroup } = useSelector(
    ({ campaignDeliverableAssignReviewerModal }) => campaignDeliverableAssignReviewerModal,
  )

  const handleToggle = (value: string) => {
    dispatch(setSelectedReviewerGroup(value))
  }

  const reviewerGroupOptions = useMemo(() => [
    // TODO: Replace this with a map of reviewer group
    {
      label: "Reviewer Group 1", value: "id1",
    },
    {
      label: "Reviewer Group 2", value: "id2",
    },
    {
      label: "Reviewer Group 3", value: "id3",
    },
    {
      label: "Reviewer Group 4", value: "id4",
    },
    {
      label: "Reviewer Group 5", value: "id5",
    },
    {
      label: "Reviewer Group 6", value: "id6",
    },
  ].map(({ label, value }) => (
    <ListItem
      className={ `cp_deliverable_assignreviewer-list-${ selectedReviewerGroup === value ? "selected" : value }` }
      id={ value }
      key={ value }
      onClick={ () => handleToggle(value) }
      value={ value }
    >
      { label }
    </ListItem>
  )), [ selectedReviewerGroup ])

  return (
    <div className="cp_deliverable-search-reviewergroup-container" id="cp_component_search-reviewergroup-modal">
      <div className="cp_deliverable-search-reviewergroup-body">
        <Input
          className="cp_component_search-results-search-input"
          placeholder={ translate("Search For Groups") }
          value={ searchInput || "" }
          onChange={ (e) => {
            dispatch(setAssignReviewerSearchInput(e.target.value))
            dispatch(setSelectedReviewerGroup(""))
          } }
          InputProps={ {
            startAdornment: (
              <InputAdornment position="end">
                <SearchIcon
                  className="cp_component_search-results-search-icon"
                />
              </InputAdornment>
            ),
          } }
        />
        <div>
          <List className="cp_deliverable_assignreviewer-list">
            { reviewerGroupOptions }
          </List>
        </div>
      </div>
    </div>
  )
}

export default SearchReviewerGroup
