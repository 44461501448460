import React, {
  JSX,
  useMemo,
  useState,
} from "react"
import { useTranslation } from "react-i18next"

import {
  Container,
  FormControl,
  FormLabel,
  InputAdornment,
  Stack,
} from "@mui/material"
import PersonIcon from "@mui/icons-material/Person"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import Input from "../Input"
import Tooltip from "../Tooltip"
import { useDispatch, useSelector } from "../../state/hooks"
import FilterPanel from "./FilterPanel"

import "./profile-filters.sass"
import { setSearchInput } from "../../state/searchAISlice"
import * as SearchHelper from "../../state/searchAISlice/helper"
import { isNumeric } from "../../util/miscHelper"

// NOTE: This list may become dynamic at some point in the future when
// search is reworked
// const influencerVerticals = [
//   "None",
//   "Art",
//   "Automotive",
//   "Beauty",
//   "Books",
//   "Business",
//   "Cannabis",
//   "Family",
//   "Fashion",
//   "Film",
//   "Fitness",
//   "Food",
//   "Gaming",
//   "LGBTQIA+",
//   "Liquor",
//   "Luxury",
//   "Military",
//   "Music",
//   "Outdoors",
//   "Pets",
//   "Photography",
//   "Real Estate",
//   "Religion",
//   "Sports",
//   "Tattoos",
//   "Tech",
//   "Travel",
// ]

function getMinEngagementRateValue(
  minEngagement: number | null | undefined,
  isDecimal: boolean,
): string {
  if (minEngagement == null) return ""
  const percentValue = (minEngagement * 100).toString()
  if (isDecimal) return `${ percentValue }.`.slice(0, 5)
  return percentValue
}

export default function ProfileFilters(): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.SearchAIFilterTabs" })
  const dispatch = useDispatch()
  // const scopes = useSelector(({ user: userSlice }) => userSlice.scopes)
  const searchAiInput = useSelector(({ searchAI }) => searchAI.searchAIInput)
  // const [ bioInputValue, setBioInputValue ] = useState("")
  const [ isDecimal, setIsDecimal ] = useState(false)

  function getNumberDisplayValue(
    n: string | null | undefined | number,
  ): string {
    if (n == null) return ""
    return translate("intlNumber", { val: n })
  }

  // Event handler for MIN FOLLOWERS
  const handleMinFollowersChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newInput = SearchHelper.cloneSearchInput(searchAiInput)
    const { maxFollowers } = searchAiInput.profileParams
    const inputValue = event.target.value
      .replace(/,/g, "")
      .replace(/\./g, "")

    const newMinFollowers = parseInt(inputValue, 10)

    // Check if the input value is empty or if it's not a number
    if (inputValue === "" || Number.isNaN(newMinFollowers)) {
      newInput.profileParams.minFollowers = null
      dispatch(setSearchInput(newInput))
      return
    }

    // Check if new min followers is a greater number than current max followers
    if (maxFollowers != null && maxFollowers < newMinFollowers) {
      newInput.profileParams.maxFollowers = null
      dispatch(setSearchInput(newInput))
      return
    }

    newInput.profileParams.minFollowers = newMinFollowers
    dispatch(setSearchInput(newInput))
  }

  // Event handler for MAX FOLLOWERS
  const handleMaxFollowersChange = (
    event:
    React.ChangeEvent<HTMLInputElement
    | HTMLTextAreaElement>
    | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
    | React.KeyboardEvent<HTMLDivElement>,
    isDone?: boolean,
  ): void => {
    const newInput = SearchHelper.cloneSearchInput(searchAiInput)
    const { minFollowers } = searchAiInput.profileParams
    const target = event.target as HTMLInputElement | HTMLTextAreaElement
    const inputValue = target.value
      .replace(/,/g, "")
      .replace(/\./g, "")

    const newMaxFollowers = parseInt(inputValue, 10)

    // Check if the input value is empty or if it's not a number
    if (inputValue === "" || Number.isNaN(newMaxFollowers)) {
      newInput.profileParams.maxFollowers = null
      dispatch(setSearchInput(newInput))
      return
    }

    if (isDone) {
      // Check if new max followers is a smaller number than current min followers
      if (minFollowers != null && minFollowers > newMaxFollowers) {
        newInput.profileParams.minFollowers = null
        dispatch(setSearchInput(newInput))
        return
      }
    }

    newInput.profileParams.maxFollowers = newMaxFollowers
    dispatch(setSearchInput(newInput))
  }

  const handleMaxFollwerKeydown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleMaxFollowersChange(event, true)
    }
  }

  // Event handler for Bio Keywords
  // const handleAddKeywords = (): void => {
  //   // Split the input by commas, trim whitespace, and filter out any empty strings
  //   const newKeywords = bioInputValue.split(",")
  //     .map((keyword) => keyword.trim())
  //     .filter((keyword) => keyword)

  //   const existingKeywords: string[] = searchAiInput.profileParams.bioKeywords

  //   const filteredKeywords = newKeywords
  //     .filter((keyword) => !existingKeywords.includes(keyword))

  //   if (filteredKeywords.length > 0) {
  //     const newInput = SearchHelper.cloneSearchInput(searchAiInput)
  //     const combinedKeywords = [ ...filteredKeywords, ...existingKeywords ]

  //     newInput.profileParams.bioKeywords = combinedKeywords
  //     dispatch(setSearchInput(newInput))
  //   }

  //   setBioInputValue("") // Clear the input field after adding keywords
  // }

  // function renderBioKeywordsPills(
  //   searchInput: SearchHelper.SearchInput,
  //   updateSearchInput: (si: SearchHelper.SearchInput) => void,
  // ): JSX.Element[] {
  //   const existingKeywords: string[] = searchInput.profileParams.bioKeywords

  //   const handleRemoveKeyword = (keyword: string): void => {
  //     const newInput = SearchHelper.cloneSearchInput(searchInput)
  //     const filteredKeywords = searchInput.profileParams.bioKeywords
  //       .filter((bk) => (!bk.includes(keyword)))

  //     newInput.profileParams.bioKeywords = filteredKeywords
  //     updateSearchInput(newInput)
  //   }

  //   return existingKeywords.map((keyword) => (
  //     <Pill
  //       key={ keyword }
  //       label={ keyword }
  //       onDelete={ () => handleRemoveKeyword(keyword) }
  //     />
  //   ))
  // }

  // Event handler Bio keywords field on enter
  // const handleBioKeyDown = (event: React.KeyboardEvent): void => {
  //   // When the user presses Enter, add the keywords
  //   if (event.key === "Enter") {
  //     event.preventDefault() // Prevent form submission and other default actions
  //     handleAddKeywords()
  //   }
  // }

  // Event handler Engagment field
  const handleEngmntFieldChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value
    const newInput = SearchHelper.cloneSearchInput(searchAiInput)

    if (newValue === "") {
      newInput.profileParams.minEngagement = null
      dispatch(setSearchInput(newInput))
      return
    }

    if (isNumeric(newValue) && parseFloat(newValue) <= 100) {
      newInput.profileParams.minEngagement = parseFloat(newValue) * 0.01
      dispatch(setSearchInput(newInput))
    }

    if (newValue.slice(-1) === ".") {
      setIsDecimal(true)
      return
    }

    setIsDecimal(false)
  }

  // Event handler Vertical
  // const handleVerticalChange = (event: SelectChangeEvent<string>): void => {
  //   const newVertical = event.target.value
  //   const newInput = SearchHelper.cloneSearchInput(searchAiInput)

  //   if (newVertical === translate("None")) {
  //     newInput.profileParams.verticals = [ "None" ]
  //     dispatch(setSearchInput(newInput))
  //     return
  //   }

  //   newInput.profileParams.verticals = [ newVertical ]
  //   dispatch(setSearchInput(newInput))
  // }

  const filterCount = useMemo(() => {
    let count = searchAiInput.profileParams.bioKeywords.length
    if (searchAiInput.profileParams.minFollowers) count += 1
    if (searchAiInput.profileParams.minEngagement) count += 1
    if (searchAiInput.profileParams.maxFollowers) count += 1
    return count
  }, [ searchAiInput.profileParams ])

  return (
    <FilterPanel title={ translate("Profile") } panelIcon={ PersonIcon } filterCount={ filterCount }>
      <Stack padding={ 2 } gap={ 1 }>
        <Stack direction="row" justifyContent="space-between">
          <Container className="cp_component_search-ai-profile cp_component_search-ai-form-container">
            <form>
              { /* Followers section */ }
              <FormControl
                className="cp_component_search-ai-profile-followers"
                component="fieldset"
                fullWidth={ true }
              >
                <div className="cp_component_search-ai-profile-followers_wrapper min-followers">
                  <FormLabel component="legend">
                    { translate("MIN FOLLOWERS") }
                  </FormLabel>

                  <Input
                    label=""
                    value={ getNumberDisplayValue(
                      searchAiInput.profileParams.minFollowers,
                    ) }
                    onChange={ handleMinFollowersChange }
                    placeholder={ translate("Min") }
                    type="text"
                  />
                </div>
                <div className="cp_component_search-ai-profile-followers_wrapper max-followers">
                  <FormLabel component="legend">
                    { translate("MAX FOLLOWERS") }
                  </FormLabel>

                  <Input
                    label=""
                    value={ getNumberDisplayValue(searchAiInput.profileParams.maxFollowers) }
                    onChange={ (e) => { handleMaxFollowersChange(e) } }
                    onBlur={ (e) => { handleMaxFollowersChange(e, true) } }
                    onKeyDown={ (e) => { handleMaxFollwerKeydown(e) } }
                    placeholder={ translate("Max") }
                    type="text"
                    InputProps={ {
                      inputProps: {
                        min: 0,
                      },
                    } }
                  />
                </div>
              </FormControl>
              { /* Bio Keywords section */ }
              { /* <FormControl
                className="cp_component_search-ai-profile-biokeywords"
                component="fieldset"
                fullWidth={ true }
              >
                <FormLabel component="legend">
                  { translate("Bio Keywords") }
                  <Tooltip arrow={ true } title={ translate("Comma Separated") }>
                    <HelpOutlineIcon fontSize="small" />
                  </Tooltip>
                </FormLabel>
                <Input
                  label=""
                  value={ bioInputValue }
                  placeholder={ translate("Bio Keywords") }
                  onChange={ (e) => setBioInputValue(e.target.value) }
                  onKeyDown={ handleBioKeyDown }
                  InputProps={ {
                    endAdornment: (
                      <IconButton onClick={ handleAddKeywords }>
                        <AddIcon />
                      </IconButton>
                    ),
                  } }
                />
                <div className="cp_component_search-ai-profile-biokeywords-pills">
                  {
              renderBioKeywordsPills(
                searchAiInput,
                (i: SearchHelper.SearchInput) => dispatch(setSearchInput(i)),
              )
            }
                </div>
              </FormControl> */ }
              { /* Engagement section */ }
              <FormControl
                className="cp_component_search-ai-profile-engagement"
                component="fieldset"
                fullWidth={ true }
              >
                <FormLabel component="legend">
                  { translate("MINIMUM ENGAGEMENT RATE (AVG)") }
                  <Tooltip arrow={ true } title={ translate("engagement tooltip mssg") }>
                    <HelpOutlineIcon fontSize="small" />
                  </Tooltip>
                </FormLabel>
                <Input
                  label=""
                  placeholder={ translate("Enter engagement rate") }
                  value={ getMinEngagementRateValue(
                    searchAiInput.profileParams.minEngagement,
                    isDecimal,
                  ) }
                  onChange={ handleEngmntFieldChange }
                  type="text"
                  InputProps={ {
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    inputProps: {
                      min: 0,
                      max: 100,
                      step: "0.01",
                      maxLength: 5,
                    },
                  } }
                />
              </FormControl>
              { /* Vertical section */ }
              { /* <FormControl
                className="cp_component_search-ai-profile-vertical"
                component="fieldset"
                fullWidth={ true }
              >
                <FormLabel component="legend">
                  { translate("Vertical") }
                  <Tooltip arrow={ true } title={ translate("Vertical tooltip mssg") }>
                    <HelpOutlineIcon fontSize="small" />
                  </Tooltip>
                </FormLabel>
                <Select
                  value={
              searchAiInput.profileParams.verticals == null
              || searchAiInput.profileParams.verticals.length === 0
              || searchAiInput.profileParams.verticals[0] == null
              || searchAiInput.profileParams.verticals[0] === ""
                ? translate("None")
                : translate(searchAiInput.profileParams.verticals[0])
            }
                  onChange={ handleVerticalChange }
                  id="cp_component_search-ai-profile-vertical-select"
                  label=""
                  displayEmpty={ false }
                  labelId="cp_component_search-ai-profile-vertical-label"
                  menuItems={
              ComponentHelper.selectionOptions(influencerVerticals.map((vertical) => translate(vertical)), true)
            }
                />
              </FormControl> */ }

              { /* Checkbox section */ }
              { /* <FormControl
                className="cp_component_search-ai-profile-traits"
                component="fieldset"
                fullWidth={ true }
              >
                <FormGroup>
                  { scopes.includes(Scope.VIP_PROFILES) && (
                  <FormControlLabel
                    control={ (
                      <Checkbox
                        checked={
                      searchAiInput.profileParams.vipAccountsOnly || false
                    }
                        onChange={ () => {
                          const newInput = SearchHelper.cloneSearchInput(searchAiInput)
                          const { profileParams: inf } = searchAiInput
                          newInput.profileParams.vipAccountsOnly = !inf.vipAccountsOnly
                          dispatch(setSearchInput(newInput))
                        } }
                        name={ translate("Marked as VIP") }
                      />
                ) }
                    label={ translate("Marked as VIP") }
                  />
                  ) }
                  { scopes.includes(Scope.WORKED_WITH) && (
                  <FormControlLabel
                    control={ (
                      <Checkbox
                        checked={
                      searchAiInput.profileParams.workedWith
                    }
                        onChange={ () => {
                          const newInput = SearchHelper.cloneSearchInput(searchAiInput)
                          newInput.profileParams.workedWith = !newInput.profileParams.workedWith
                          dispatch(setSearchInput(newInput))
                        } }
                        name={ translate("Worked With") }
                      />
                ) }
                    label={ translate("Worked With") }
                  />
                  ) }
                  { scopes.includes(Scope.FEATURE_SEARCH_CONTACT_INFO) && (
                  <FormControlLabel
                    control={ (
                      <Checkbox
                        checked={
                    searchAiInput.profileParams.contactProvided || false
                  }
                        onChange={ () => {
                          const newInput = SearchHelper.cloneSearchInput(searchAiInput)
                          const { profileParams: inf } = searchAiInput
                          newInput.profileParams.contactProvided = !inf.contactProvided
                          dispatch(setSearchInput(newInput))
                        } }
                        name={ translate("Contact Information Provided") }
                      />
              ) }
                    label={ translate("Contact Information Provided") }
                  />
                  ) }
                  { scopes.includes(Scope.BLOCKLISTED_PROFILES) && (
                  <FormControlLabel
                    control={ (
                      <Checkbox
                        checked={
                      searchAiInput.profileParams.hideBlocklistedAccounts
                    }
                        onChange={ () => {
                          const newInput = SearchHelper.cloneSearchInput(searchAiInput)
                          newInput.profileParams.hideBlocklistedAccounts = !newInput.profileParams.hideBlocklistedAccounts
                          dispatch(setSearchInput(newInput))
                        } }
                        name={ translate("Hide Blocklisted Social Accounts") }
                      />
                ) }
                    label={ translate("Hide Blocklisted Social Accounts") }
                  />
                  ) }
                  { scopes.includes(Scope.NSFW_FLAG) && (
                  <FormControlLabel
                    control={ (
                      <Checkbox
                        checked={
                    searchAiInput.profileParams.hideNSFWAccounts
                  }
                        onChange={ () => {
                          const newInput = SearchHelper.cloneSearchInput(searchAiInput)
                          newInput.profileParams.hideNSFWAccounts = !newInput.profileParams.hideNSFWAccounts
                          dispatch(setSearchInput(newInput))
                        } }
                        name={ translate("Hide Not Safe For Work Social Accounts") }
                      />
              ) }
                    label={ translate("Hide Not Safe For Work Social Accounts") }
                  />
                  ) }
                  <FormControlLabel
                    control={ (
                      <Checkbox
                        checked={ searchAiInput.profileParams.hideInactiveAccounts }
                        onChange={ () => {
                          const newInput = SearchHelper.cloneSearchInput(searchAiInput)
                          newInput.profileParams.hideInactiveAccounts = !newInput.profileParams.hideInactiveAccounts
                          dispatch(setSearchInput(newInput))
                        } }
                        name={ translate("Hide Inactive Social Accounts") }
                      />
              ) }
                    label={ translate("Hide Inactive Social Accounts") }
                  />
                  { scopes.includes(Scope.OAUTH_ACCOUNTS) && (
                  <FormControlLabel
                    control={ (
                      <Checkbox
                        checked={
                    searchAiInput.profileParams.oauthAccountsOnly || false
                  }
                        onChange={ () => {
                          const newInput = SearchHelper.cloneSearchInput(searchAiInput)
                          const { profileParams: inf } = searchAiInput
                          newInput.profileParams.oauthAccountsOnly = !inf.oauthAccountsOnly
                          dispatch(setSearchInput(newInput))
                        } }
                        name={ translate("Oauth Accounts") }
                      />
              ) }
                    label={ translate("Oauth Accounts") }
                  />
                  ) }
                </FormGroup>
              </FormControl> */ }
            </form>
          </Container>
        </Stack>
      </Stack>
    </FilterPanel>
  )
}
