import React from "react"
import "./style.sass"
import CommunicationsSidebar from "./CommunicationsSidebar"
import CommunicationsBody from "./CommunicationsBody"
import GroupAccountsBody from "./GroupAccounts"
import { CommsFolder } from "../../util/constant"
import GroupAccountsSearch from "./GroupAccounts/search"

type Props = {
  commsBody: CommsFolder,
}

export default function CommunicationsOverview({
  commsBody,
}: Props) {
  return (
    <div className="cp_component_communications-overview">
      <CommunicationsSidebar to={ commsBody } />

      { (commsBody === "Inbox"
        || commsBody === "Sent"
        || commsBody === "Batch"
        || commsBody === "Drafts") && (
        <CommunicationsBody />
      ) }

      { commsBody === "GroupAccounts" && (
        <GroupAccountsBody />
      ) }

      { commsBody === "GroupAccountSearch" && (
        <GroupAccountsSearch />
      ) }

    </div>
  )
}
