import React, { JSX } from "react"
import { OpenInNew } from "@mui/icons-material"
import { Typography, IconButton } from "@mui/material"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import EmptySplash from "../EmptySplash"
import PostStats from "../PostStats"
import SocialAvatar from "../SocialAvatar"
import { postDateFormatter, prettyPrintDecimal } from "../../util/miscHelper"
import { useSelector } from "../../state/hooks"

import "./relevant-post-preview.sass"
import PostType from "../PostType"

const highlightText = (text: string, keywords: string[]): JSX.Element[] => {
  const regex = new RegExp(`(${ keywords.join("|") })`, "gi")
  const parts = text.split(regex)

  return parts.map((part) => {
    if (keywords.includes(part.toLowerCase())) {
      return <span key={ part } className="highlight-txt">{ part }</span>
    }
    return <span key={ part }>{ part }</span>
  })
}

function SocialTrackerRelevantPosts() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListSocialSubTabs" })
  const { socialTracker } = useSelector(({ socialTrackerPage }) => socialTrackerPage)
  const { trackerID } = useParams()

  if (!API.isSuccess(socialTracker)) return null

  const { searchContentViaContentAlert, getContentAlerts } = socialTracker.payload
  const currentSocialTracker = getContentAlerts.filter((alert) => alert.xref === trackerID)
  if (!currentSocialTracker.length || !currentSocialTracker[0].params) return null
  const { contentParams } = currentSocialTracker[0].params
  const keywords = contentParams ? contentParams.keywords : []
  // Using reduce and concat to merge all tags into one array
  const allKeywords = keywords.reduce((acc: string[], keyword: GraphQL.KeywordInputType) => acc.concat(keyword.tags), [])
  const posts = searchContentViaContentAlert.result
    .__typename === "GodSearchPostOutput" ? searchContentViaContentAlert.result.rows : []

  return (
    <div className="cp_relevant_posts_preview cp-relevant_post-social-tracker">
      { posts.length ? (
        posts.map((post) => {
          const { socialAccount } = post
          if (!socialAccount) return null
          const socialStats = { ...socialAccount.socialAccountStatistics }
          const thumbnailUrl = post.postMedia[0]?.thumbnailUrl || ""
          const mediaUrl = post.postMedia[0]?.mediaUrl || ""
          const processedContent = highlightText(post.postContent, allKeywords)
          return (
            <article key={ post.id } className="cp_relevant_posts_preview-post">
              <section className="cp_relevant_posts_preview-post-header">
                <SocialAvatar
                  profilePictureUrl={ socialAccount.profilePictureUrl }
                  network={ socialAccount.network }
                  followers={ socialStats.followers }
                  fullName={ socialAccount.userName || "" }
                  isNSFW={ socialAccount.isNsfw }
                  isBlacklisted={ false }
                  isPlaceholder={ false }
                  isUnsubscribed={ false }
                  username={ socialAccount.userName }
                />
                <IconButton onClick={ () => window.open(`${ post.permalink }`, "_blank") }>
                  <OpenInNew />
                </IconButton>
              </section>
              <section className="cp_relevant_posts_preview-post-media">
                <div className="cp_relevant_posts_preview-post-image">
                  <div style={ { backgroundImage: `url("${ mediaUrl || thumbnailUrl }")` } }>
                    <div />
                  </div>
                  <img src={ mediaUrl || thumbnailUrl } alt={ post.postContent } />
                </div>
                <aside className="additional-info">
                  <Typography className="posted-time">
                    { `${ postDateFormatter(post.postedTime) }` }
                  </Typography>
                  <PostType
                    isShort={ false }
                    isHighlighted={ post.highlighted }
                    isReel={ post.isReel }
                    isStory={ post.isStory }
                    network={ post.socialAccount.network }
                    showInFeedPill={ false }
                  />
                  <p className="post-content">{ processedContent }</p>
                </aside>
              </section>
              <section className="cp_relevant_posts_preview-post-socials-stats">
                <PostStats
                  isReel={ post.isReel }
                  isStory={ post.isStory }
                  isLens={ false }
                  isSpotlightContent={ post.highlighted }
                  network={ post.socialAccount.network }
                  postComments={ post.postComments }
                  postContent={ post.postContent }
                  postExits={ post.postExits || 0 }
                  postImpressions={ post.postImpressions || 0 }
                  postLikes={ post.postLikes }
                  postPlays={ post.postPlays || 0 }
                  postReach={ post.postReach || 0 }
                  postReachEstimated={ post.estimatedPostReach?.value || 0 }
                  postSaves={ post.postSaves || 0 }
                  postShares={ post.postShares }
                  postTapsBackward={ post.postTapsBackward || 0 }
                  postTapsForward={ post.postTapsForward || 0 }
                  postViews={ post.postViews }
                  postScreenshots={ post.screenshots || 0 }
                  postSwipeUps={ post.swipeUps || 0 }
                  isSaved={ post.saved }
                />
                { (!post.isStory && post.engagementRate !== 0) && (
                  <Typography>
                    <span>{ `${ translate("ENGAGEMENT RATE") }: ` }</span>
                    { `${ prettyPrintDecimal(post.engagementRate, 2) }%` }
                  </Typography>
                ) }
              </section>
            </article>
          )
        })
      ) : (
        <EmptySplash
          headlineText={ translate("No Posts") }
          bodyText={ translate("We could not find any posts for this alert.") }
        />
      ) }
    </div>
  )
}

export default SocialTrackerRelevantPosts
