import * as React from "react"
import { useTranslation } from "react-i18next"
import "./campaign-widget-deliverables.sass"

import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { useMemo } from "react"
import { CampaignPageFragment, DeliverableStatus } from "../../../graphql"
import EntityInfoRow from "../../EntityInfoRow"
import { shorthandNumber } from "../../../util/miscHelper"
import { Scope } from "../../../util/types"
import Avatar from "../../Avatar"
import StatusDeliverable from "../../StatusDeliverable"
import { openDeliverableContentModal } from "../../../state/campaignDeliverableContentModalSlice"
import { openDeliverableContentModal as openModalV2 } from "../../../state/campaignDeliverableContentModalV2Slice"
import { useDispatch, useSelector } from "../../../state/hooks"
import { getCampaign } from "../../../state/dashboardSlice/campaigns"
import LoadingIndicator from "../../LoadingIndicator"

interface CampaignWidgetDeliverablesProps {
  campaign: CampaignPageFragment
}

function NoDeliverablesOverlay() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.DashboardCampaignWidget" })
  return (
    <div className="cp_campaign-widget-deliverables_component-no-results">
      { translate("No Deliverables Added") }
    </div>
  )
}

export default function CampaignWidgetDeliverables({ campaign }: CampaignWidgetDeliverablesProps) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.DashboardCampaignWidget" })
  const dispatch = useDispatch()
  const { scopes } = useSelector(({ user }) => user)
  const { fetchingCampaignId } = useSelector(({ dashboard }) => dashboard)
  const isTieredApproval = scopes.includes(Scope.CAMPAIGN_MANAGEMENT && Scope.DEVELOPMENT)

  const COLUMNS: GridColDef[] = useMemo(() => [
    {
      field: "account",
      headerName: translate("Account"),
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <EntityInfoRow
          key={ params.row.id }
          avatarSrc={ params.row.profilePictureUrl }
          network={ params.row.network }
          avatarSize="md"
          name={ `@${ params.row.account }` }
          subInfo={ `${ shorthandNumber(params.row.followers) } ${ translate("FOLLOWERS") }` }
        />
      ),
      resizable: false,
      flex: 1,
      maxWidth: 230,
    },
    {
      field: "caption",
      headerName: translate("Post Caption"),
      sortable: true,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="caption">
          <p className="caption-content">
            { params.row.caption }
          </p>
        </div>
      ),
      flex: 0.7,
      maxWidth: 200,
      align: "left",
    },
    {
      field: "media",
      headerName: translate("Media"),
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const media = params.row.media && params.row.media.length > 0 ? params.row.media[0] : undefined
        if (!media) return null
        return (
          <Avatar
            className="table-media"
            variant="rounded"
            src={ media?.media?.url?.address || "" }
          />
        )
      },
      flex: 0.7,
      maxWidth: 120,
      align: "center",
    },
    {
      field: "status",
      headerName: translate("Status"),
      sortable: false,
      filterable: false,
      disableColumnMenu: false,
      type: "singleSelect",
      valueOptions: [
        DeliverableStatus.AwaitingContent,
        DeliverableStatus.Pending,
        DeliverableStatus.Finalized,
        DeliverableStatus.Live,
        DeliverableStatus.Uploaded,
      ],
      renderCell: (params) => (
        <StatusDeliverable
          deliverableStatus={ params.row.status }
        />
      ),
      flex: 0.7,
      maxWidth: 150,
      align: "left",
    },
  ], [ translate ])

  if (fetchingCampaignId === campaign.id) {
    return (
      <div className="cp_campaign-widget-deliverables_component loading">
        <LoadingIndicator />
      </div>
    )
  }

  return (
    <DataGrid
      className="cp_campaign-widget-deliverables_component"
      disableRowSelectionOnClick={ true }
      slots={ {
        noResultsOverlay: NoDeliverablesOverlay,
        noRowsOverlay: NoDeliverablesOverlay,
      } }
      rowHeight={ 90 }
      columns={ COLUMNS }
      hideFooter={ true }
      rows={ campaign.deliverables.map(({
        id,
        deliverableCaption,
        deliverableMedia,
        campaignNetworkAccount,
        contentStatus,
      }, i, deliverables) => ({
        account: campaignNetworkAccount.socialAccount.userName,
        profilePictureUrl: campaignNetworkAccount.socialAccount.profilePictureUrl,
        followers: campaignNetworkAccount.socialAccount.socialAccountStatistics.followers,
        id,
        network: campaignNetworkAccount.socialAccount.network,
        caption: deliverableCaption?.text || "",
        media: deliverableMedia,
        status: contentStatus,
        deliverable: deliverables[i],
      })) }
      onRowClick={ (params) => {
        if (!scopes.includes(Scope.FEATURE_CAMPAIGN)) return
        if (isTieredApproval) {
          dispatch(openModalV2({
            deliverable: params.row.deliverable,
            modalOpen: true,
            onClose: async (edited: boolean) => {
              if (edited) await dispatch(getCampaign({ campaignId: campaign.id }))
            },
          }))
        } else {
          dispatch(openDeliverableContentModal({
            deliverable: params.row.deliverable,
            onClose: async (edited: boolean) => {
              if (edited) await dispatch(getCampaign({ campaignId: campaign.id }))
            },
            campaign,
          }))
        }
      }
      }
    />
  )
}
