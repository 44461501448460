import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import MailOutlineIcon from "@mui/icons-material/MailOutline"
import WebAssetIcon from "@mui/icons-material/WebAsset"
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
} from "@mui/material"
import { ArrowDropDown } from "@mui/icons-material"
import { useDispatch, useSelector } from "../../state/hooks"
import Modal from "../Modal"
import { RootState } from "../../state/store"
import {
  getGroupAccountContactInfo,
  setContactInfoModalOpen,
  resetContactInfoModal,
  setEmailFromTeamModalOpen,
  ContactModalProps,
  updateContactInformationForAccount,
} from "../../state/groupAccountsContactInfoModal"
import SocialAvatar from "../SocialAvatar"
import * as API from "../../util/apiClient"
import Input from "../Input"
import Dropdown from "../Dropdown"
import Button from "../Button"
import "./index.sass"
import { Toast } from "../../util/types"
import { pushToast } from "../../state/toastSlice"
import { setGroupAccountReloadStatus } from "../../state/communicationsPage"

/**
 * This handles the creation and management of the contact information for a particular row in the communications
 * group accounts table
 * @returns The edit contact information modal dialog
 */
export default function GroupAccountsContactInfoModal() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.GroupAccountsContactInfoModal" })
  const dispatch = useDispatch()

  // Extract all variables from the contact info state
  const {
    GAContactInfoSocialAvatarInfo: socialAvatarInfo,
    GASocialAccountId: socialAccountId,
    GAContactInfoModalOpen: open,
    GAContactInfo,
    ContactInfoModalData: modalData,
  } = useSelector((root: RootState) => root.contactInfoModal)

  // Create and initialize local state
  const [ primaryEmail, setPrimaryEmail ] = useState(modalData.primaryEmail)
  const [ firstName, setFirstName ] = useState(modalData.firstName)
  const [ lastName, setLastName ] = useState(modalData.lastName)
  const [ editingEmailsFromTeam, setEditingEmailsFromTeam ] = useState(false)

  /**
   * Clear all the form values
   */
  const clearForm = () => {
    setPrimaryEmail("")
    setFirstName("")
    setLastName("")
  }

  /**
   * Call GraphQL query to load the all the contact information and needed values for this modal
   */
  useEffect(() => {
    if (socialAccountId !== "" && open && !editingEmailsFromTeam) {
      clearForm()
      setPrimaryEmail(modalData.primaryEmail)
      setFirstName(modalData.firstName)
      setLastName(modalData.lastName)
      dispatch(getGroupAccountContactInfo(
        socialAccountId,
        (modalData.emailsToBeSaved) ? modalData.emailsToBeSaved : [],
        (modalData.emailsToBeDeleted) ? modalData.emailsToBeDeleted : [],
      ))
    } else if (open) setEditingEmailsFromTeam(false)
  }, [ open, socialAccountId ])

  /**
   * Update local state variables with information from GraphQL query above
   */
  useEffect(() => {
    if (API.isSuccess(GAContactInfo)) {
      // Pull out the social account
      const { socialAccount: account } = GAContactInfo.payload

      // Set local state values
      setFirstName((account.contact?.firstName) ? account.contact.firstName : "")
      setLastName((account.contact?.lastName) ? account.contact.lastName : "")
      setPrimaryEmail((account.primaryEmail?.address) ? account.primaryEmail.address : "")
    }
  }, [ GAContactInfo ])

  /**
   * Clears the form and closes the modal
   */
  const onClose = () => {
    // Close the modal window
    dispatch(resetContactInfoModal())
    clearForm()
    dispatch(setContactInfoModalOpen(false))
  }

  /**
   * Updates the first name, last name, and primary email address in the database
   */
  const handleUpdate = async () => {
    // Close the modal
    dispatch(setContactInfoModalOpen(false))

    // Update the modal data
    const data: ContactModalProps = {
      firstName,
      lastName,
      primaryEmail,
      websiteUrl: modalData.websiteUrl,
      emails: modalData.emails,
      emailsFromTeam: modalData.emailsFromTeam,
      emailsToBeSaved: modalData.emailsToBeSaved,
      emailsToBeDeleted: modalData.emailsToBeDeleted,
      profile: modalData.profile,
    }

    // Update the data
    await dispatch(updateContactInformationForAccount({
      socialAccountId,
      data,
      socialAvatar: socialAvatarInfo,
      onSuccess: () => {
        // Create and submit toast
        const toast: Toast = {
          type: "success",
          message: translate("Group account contact information has been successfully updated!"),
        }
        dispatch(pushToast(toast))
      },
      onWarning: () => {
        const toast: Toast = {
          type: "warning",
          message: translate("No update was made to name, first and last names should both be present!"),
        }
        dispatch(pushToast(toast))
      },
      onError: () => {
        // Create and submit toast
        const toast: Toast = {
          type: "error",
          message: translate("Failed to update group account contact information!"),
        }
        dispatch(pushToast(toast))
      },
    }))

    // Reset modal and clear the form
    clearForm()
    dispatch(resetContactInfoModal())

    // Reload the main page
    dispatch(setGroupAccountReloadStatus(true))
  }

  const editEmailsByYourTeam = () => {
    // Close this dialog and open the edit emails dialog
    dispatch(setContactInfoModalOpen(false))
    setEditingEmailsFromTeam(true)
    dispatch(setEmailFromTeamModalOpen(true))
  }

  // Return the social account
  return (
    <Modal
      className="cp_component_group_accounts_contact_info_modal"
      open={ open }
      customTitle={ <SocialAvatar { ...socialAvatarInfo } /> }
      closeAction={ onClose }
      primaryLabel={ translate("SAVE") }
      secondaryLabel={ translate("CLOSE") }
      secondaryAction={ onClose }
      primaryAction={ handleUpdate }
      disabled={ false }
    >
      <div className="cp_component_group_accounts_content">
        <br />
        <Input
          className="input_first_last_name"
          value={ firstName }
          onChange={ (e) => setFirstName(e.currentTarget.value) }
          fullWidth={ true }
          label={ translate("First Name") }
          placeholder="Enter First Name"
          isValueCaps={ false }
        />
        <br />
        <Input
          className="input_first_last_name"
          value={ lastName }
          onChange={ (e) => setLastName(e.currentTarget.value) }
          fullWidth={ true }
          label={ translate("Last Name") }
          placeholder="Enter Last Name"
          isValueCaps={ false }
        />
        <br />
        <Stack alignItems="center" direction="row" gap={ 1 }>
          <WebAssetIcon />
          <p className="label_small">{ translate("Website From Public Profile") }</p>
        </Stack>
        <p className="body_small">{ (modalData.websiteUrl !== "") ? modalData.websiteUrl : "No website" }</p>
        <br />
        <Stack alignItems="center" direction="row" gap={ 1 }>
          <MailOutlineIcon />
          <p className="label_small">{ translate("Emails From Public Profile") }</p>
        </Stack>
        { (!modalData.emails || modalData.emails.length === 0) && (
          <p className="body_small">No emails available</p>
        ) }
        { modalData.emails.map((email) => (
          <p className="body_small">{ email.address }</p>
        )) }
        <br />
        <Stack alignItems="center" direction="row" gap={ 1 }>
          <MailOutlineIcon />
          <p className="label_small">{ translate("Emails Added By Your Team") }</p>
          <Button id="edit-emails-by-team-button" onClick={ editEmailsByYourTeam } isTextButton={ true } label="Edit" />
        </Stack>
        { (modalData.emailsFromTeam === null || modalData.emailsFromTeam.length === 0) && (
        <p className="body_small">No emails available</p>
        ) }
        { modalData.emailsFromTeam.map((email) => (
          <p className="body_small">{ email.address }</p>
        )) }
        <br />
        <Stack alignItems="center" direction="row" gap={ 1 }>
          <MailOutlineIcon />
          <p className="label_small">{ translate("CHOOSE A PRIMARY EMAIL ADDRESS") }</p>
        </Stack>
        <Dropdown
          buttonClassName="dropdown_primary_email"
          buttonType="custom"
          placement="top"
          customButtonChildren={ (
            <Button
              className="dropdown_primary_email"
              label={ (
                <>
                  <span className="dropdown_primary_email">
                    { (primaryEmail !== "")
                      ? primaryEmail
                      : translate("Select Primary Email") }
                  </span>
                  <ArrowDropDown />
                </>
              ) }
              isPrimary={ false }
              variant="outlined"
            />
          ) }
        >
          <List disablePadding={ true }>
            { (modalData.emails.length === 0 && modalData.emailsFromTeam.length === 0) && (
              <ListItem>
                <ListItemText>{ translate("No Emails Available") }</ListItemText>
              </ListItem>
            ) }
            { modalData.emails.map((email) => (
              <ListItem disablePadding={ true } disableGutters={ true } key={ email.address }>
                <ListItemButton onClick={
                  (event) => setPrimaryEmail((event.currentTarget.textContent) ? event.currentTarget.textContent : "")
                }
                >
                  <ListItemText primary={ email.address } className={ (email.address === primaryEmail) ? "selected" : "" } />
                </ListItemButton>
              </ListItem>
            )) }
            { modalData.emailsFromTeam.map((email) => (
              <ListItem disablePadding={ true } disableGutters={ true } key={ email.address }>
                <ListItemButton onClick={
                  (event) => setPrimaryEmail((event.currentTarget.textContent) ? event.currentTarget.textContent : "")
                }
                >
                  <ListItemText primary={ email.address } className={ (email.address === primaryEmail) ? "selected" : "" } />
                </ListItemButton>
              </ListItem>
            )) }
          </List>
        </Dropdown>
      </div>
    </Modal>
  )
}
