/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from "react"
import dayjs from "dayjs"
import { useMemo } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import * as Constant from "../../../util/constant"

import Tabs, { TabLabelProps } from "../../Tabs"
import { isError } from "../../../util/apiClient"
import { useSelector, useDispatch } from "../../../state/hooks"
import { toggleShowAcountSearch } from "../../../state/listAddAccount"
import "./style.sass"
import ListManagement from "../../ListManagement"
import ListManagementControls from "../../ListOverview/ListManagementControls"
import Button from "../../Button"
import AddAccountToList from "../../ListManagement/AddAccountToList"

// eslint-disable-next-line no-shadow
enum TabPathsEnum {
  ACCOUNT_MANAGEMENT = "account-management",
  CONFIGURATION = "configuration",
}
interface ListTabLabelProps extends TabLabelProps {
  path: TabPathsEnum.ACCOUNT_MANAGEMENT
  | TabPathsEnum.CONFIGURATION
}

export default function ListTabs({ showAccountSearch }: { showAccountSearch: boolean }) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListTabs" })
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { tabPath, adID } = useParams()
  const {
    list: fetchedList,
  } = useSelector(({ list }) => list)
  const scopes = useSelector(({ user }) => user.scopes)

  const tabs: ListTabLabelProps[] = React.useMemo(() => {
    const t = [
      { label: translate("Account Management"), path: TabPathsEnum.ACCOUNT_MANAGEMENT },
      { label: translate("Configuration"), path: TabPathsEnum.CONFIGURATION },
    ]
    return t
  }, [ translate, scopes, fetchedList ])

  const setTab = (tabIndex: number) => {
    const newTabPath = tabs[tabIndex].path
    let pathPrefix = tabPath ? "../" : ""
    if (adID) pathPrefix += "../"
    navigate(`${ pathPrefix }${ newTabPath }`, { relative: "path" })
  }

  const tabContent = useMemo(() => {
    switch (tabPath) {
      case TabPathsEnum.ACCOUNT_MANAGEMENT:
      case undefined:
        return null
      case TabPathsEnum.CONFIGURATION:
        return null
      default:
        return <p>Error or this tab doesnt exist yet</p>
    }
  }, [ tabPath, scopes, tabs.length ])

  const tabPage = useMemo(() => {
    switch (tabPath) {
      case TabPathsEnum.ACCOUNT_MANAGEMENT:
      case undefined:
        return (!showAccountSearch ? (
          <ListManagement displayConfigureTab={ true } />
        )
          : (
            <div className="cp_list_page-list-add-account">
              <Button
                isPrimary={ true }
                variant="contained"
                label={ translate("BACK TO ACCOUNT MANAGEMENT") }
                isEnabled={ true }
                onClick={ () => dispatch(toggleShowAcountSearch()) }
              />
              <AddAccountToList />
            </div>
          ))
      case TabPathsEnum.CONFIGURATION:
        return <p>Configuration Tab</p>
      default:
        return <p>Error or this tab doesnt exist yet</p>
    }
  }, [ tabPath, scopes, tabs.length, showAccountSearch ])

  const tabIndex = useMemo(() => {
    const foundTab = tabs.find(({ path }) => path === tabPath)
    const index = foundTab ? tabs.indexOf(foundTab) : 0
    return index
  }, [ tabPath, tabs.length, scopes ])

  const createdDate = useMemo(() => {
    if (fetchedList === "init" || fetchedList === "loading" || isError(fetchedList)) return ""
    return dayjs(fetchedList.payload.suggestionListById.created * 1000).format(Constant.LONGFORM_DATE)
  }, [ fetchedList ])

  const createdBy = useMemo(() => {
    if (fetchedList === "init" || fetchedList === "loading" || isError(fetchedList)) return ""
    return fetchedList.payload.suggestionListById.creator.username ?? ""
  }, [ fetchedList ])

  return (
    <div className="cp_list_component-tabs-container">
      <Tabs
        handleChange={ setTab }
        tabs={ tabs }
        defaultValue={ tabIndex }
        controlledValue={ tabIndex }
        controls={ (
          <>
            { createdBy && createdDate && (
            <>
              <p className="cp_list-overview-table_component-info">
                { `${ translate("Created by") } ${ createdBy } ${ translate("on") } ${ createdDate }` }
              </p>
            </>
            ) }
            <ListManagementControls />
          </>
) }
      />
      <div className="cp_list_component-tabs-content">
        { tabContent }
      </div>
      { tabPage }
    </div>
  )
}
