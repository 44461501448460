import React, { JSX } from "react"
import { useTranslation } from "react-i18next"

import * as API from "../../util/apiClient"
import Button from "../../component/Button"
import PageShell from "../../component/PageShell"
import AISearchFilter from "../../component/AISearchFilter"
import SearchInitiator from "./SearchInitiator"
import SearchResultsAI from "../../component/SearchAIResults"
import ToastController from "../../controller/Toast"
import { fetchAccountSearchResults, fetchContentSearchResults } from "../../state/searchAISlice"
import { useDispatch, useSelector } from "../../state/hooks"

import "./style.sass"
import { SearchAIType } from "../../util/types"

export default function SearchAI(): JSX.Element {
  const { t: translate } = useTranslation([], { keyPrefix: "page.SearchAI" })
  const dispatch = useDispatch()
  const { user } = useSelector(({ user: userSlice }) => userSlice)
  const { searchAIType } = useSelector(({ searchAI }) => searchAI)

  // User state is loading... show minimal page frame without results...
  if (!API.isSuccess(user)) {
    return (
      <div className="cp_page_search-ai">
        <ToastController />
        <PageShell
          title={ translate("Search") }
          showTopNav={ false }
        />
      </div>
    )
  }

  return (
    <div className="cp_page_search-ai">
      <ToastController />
      <PageShell title={ translate("Search") } />
      <div className="cp_page_search-ai-container">
        <section className="cp_page_search-ai-form">
          <AISearchFilter />
          <aside className="cp_page_search-ai-button">
            <Button
              id="cp_page_search-ai-button"
              isEnabled={ true }
              isPrimary={ true }
              label={ translate("Search") }
              onClick={ () => {
                if (searchAIType === SearchAIType.Accounts) dispatch(fetchAccountSearchResults())
                if (searchAIType === SearchAIType.Content) dispatch(fetchContentSearchResults())
              } }
            />
          </aside>
        </section>
        <section className="cp_page_search-ai-results">
          <SearchResultsAI />

          { /* User data is loaded... initiate search! */ }
          <SearchInitiator />
        </section>
      </div>
    </div>
  )
}
