import React, {
  ChangeEvent,
  useMemo,
  useRef,
} from "react"
import { Editor } from "@tiptap/react"
import { IconButton, Select } from "@mui/material"
import { useTranslation } from "react-i18next"
import {
  AttachFile,
  Code,
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatSize,
  FormatUnderlined,
  Redo,
  Undo,
} from "@mui/icons-material"
import { useSelector } from "../../state/hooks"
import * as API from "../../util/apiClient"
import LinkInputPopover from "./LinkInputPopover"
import MenuItem from "../MenuItem"
import Tooltip from "../Tooltip"
import { ACCEPTED_FILE_TYPES } from "../../util/constant"

import "./menu-bar.sass"

// eslint-disable-next-line no-shadow
enum MergeTagValues {
  AUTHOR_NAME = "#{author.name}",
  CAMPAIGN_NAME = "#{campaign.name}",
  SOCIAL_ACCOUNT_HANDLE = "#{social_account.foreign_username}",
  SOCIAL_ACCOUNT_USERNAME = "#{social_account.name}",
  SOCIAL_ACCOUNT_NETWORK = "#{social_account.network}",
  SOCIAL_ACCOUNT_FIRST_NAME = "#{social_account_contact.first_name}",
  SOCIAL_ACCOUNT_FULL_NAME = "#{social_account_contact.full_name}"
}

export interface FileResponse {
  id: number | string,
  url: string
}

type Props = {
  editor: Editor
  handleFileAttachment?: (file: File) => Promise<void>
}

export default function MenuBar({ editor, handleFileAttachment }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.MenuBar" })
  const { communicationGroup } = useSelector(({ communicationsPage }) => communicationsPage)
  const fileInputRef = useRef(null)

  const handleMergeSelect = (value: MergeTagValues) => {
    editor.commands.insertContent(value)
    editor.commands.focus()
  }

  const handleSizeClick = () => {
    // Currently just toggle between paragraph and h1
    if (editor.isActive("paragraph")) {
      editor.chain().focus().toggleHeading({ level: 1 }).run()
    } else {
      editor.chain().focus().setParagraph().run()
    }
  }

  const handleFileInput = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null
    if (file && handleFileAttachment) {
      handleFileAttachment(file)
    }
  }

  const commHasCampaign = useMemo(() => {
    if (API.isSuccess(communicationGroup)) {
      const { campaign } = communicationGroup.payload.communicationGroup
      return campaign !== null
    }
    return false
  }, [ communicationGroup ])

  return (
    <div className="cp_component_rich-text-editor_menu-bar">
      <Select
        MenuProps={ { className: "cp_component_rich-text-editor-select-menu-dropdown" } }
        className="dropdown"
        displayEmpty={ true }
        value=""
        renderValue={ () => translate("Merge Tags") }
        onClick={ (event) => event.currentTarget.focus() }
      >
        <MenuItem
          className="cp_component_rich-text-editor-menu-bar-menu-item"
          onClick={ () => handleMergeSelect(MergeTagValues.AUTHOR_NAME) }
          value={ MergeTagValues.AUTHOR_NAME }
        >
          { translate("Author Name") }
        </MenuItem>
        { commHasCampaign && (
          <MenuItem
            className="cp_component_rich-text-editor-menu-bar-menu-item"
            onClick={ () => handleMergeSelect(MergeTagValues.CAMPAIGN_NAME) }
            value={ MergeTagValues.CAMPAIGN_NAME }
          >
            { translate("Campaign Name") }
          </MenuItem>
        ) }
        <MenuItem
          className="cp_component_rich-text-editor-menu-bar-menu-item"
          onClick={ () => handleMergeSelect(MergeTagValues.SOCIAL_ACCOUNT_HANDLE) }
          value={ MergeTagValues.SOCIAL_ACCOUNT_HANDLE }
        >
          { translate("Social Account Handle") }
        </MenuItem>
        <MenuItem
          className="cp_component_rich-text-editor-menu-bar-menu-item"
          onClick={ () => handleMergeSelect(MergeTagValues.SOCIAL_ACCOUNT_USERNAME) }
          value={ MergeTagValues.SOCIAL_ACCOUNT_USERNAME }
        >
          { translate("Social Account Username") }
        </MenuItem>
        <MenuItem
          className="cp_component_rich-text-editor-menu-bar-menu-item"
          onClick={ () => handleMergeSelect(MergeTagValues.SOCIAL_ACCOUNT_NETWORK) }
          value={ MergeTagValues.SOCIAL_ACCOUNT_NETWORK }
        >
          { translate("Social Account Network") }
        </MenuItem>
        <MenuItem
          className="cp_component_rich-text-editor-menu-bar-menu-item"
          onClick={ () => handleMergeSelect(MergeTagValues.SOCIAL_ACCOUNT_FIRST_NAME) }
          value={ MergeTagValues.SOCIAL_ACCOUNT_FIRST_NAME }
        >
          { translate("Social Account First Name") }
        </MenuItem>
        <MenuItem
          className="cp_component_rich-text-editor-menu-bar-menu-item"
          onClick={ () => handleMergeSelect(MergeTagValues.SOCIAL_ACCOUNT_FULL_NAME) }
          value={ MergeTagValues.SOCIAL_ACCOUNT_FULL_NAME }
        >
          { translate("Social Account Full Name") }
        </MenuItem>
      </Select>

      <div className="icon-section">
        <Tooltip title={ translate("Undo") }>
          <IconButton
            onClick={ () => editor.chain().focus().undo().run() }
            disabled={ !editor.can().chain().focus().undo()
              .run() }
          >
            <Undo />
          </IconButton>
        </Tooltip>
        <Tooltip title={ translate("Redo") }>
          <IconButton
            onClick={ () => editor.chain().focus().redo().run() }
            disabled={ !editor.can().chain().focus().redo()
              .run() }
          >
            <Redo />
          </IconButton>
        </Tooltip>
      </div>
      <div className="icon-section">
        <LinkInputPopover editor={ editor } />
      </div>
      { /* TODO: Implement text color and highlight picker */ }
      { /* <div className="icon-section">
          <IconButton>
            <InvertColors />
            <ArrowDropDown />
          </IconButton>
          <IconButton>
            <Brush />
            <ArrowDropDown />
          </IconButton>
        </div> */ }
      { handleFileAttachment && (
      <div className="icon-section">
        <div className="file-attachment">
          <Tooltip title={ translate("Attach file") }>
            <IconButton
              id="cp_attach-file-button"
              aria-label="Attach file"
              onClick={ () => {
                if (fileInputRef.current) {
                  (fileInputRef.current as HTMLInputElement).click()
                }
              } }
            >
              <AttachFile />
            </IconButton>
          </Tooltip>
          <input
            disabled={ false }
            accept={ ACCEPTED_FILE_TYPES }
            id="image-upload"
            ref={ fileInputRef }
            type="file"
            style={ { display: "none" } }
            onChange={ handleFileInput }
          />
        </div>
      </div>
      ) }
      <div className="icon-section">
        <Tooltip title={ translate("Bold") }>
          <IconButton
            onClick={ () => editor.chain().focus().toggleBold().run() }
            className={ editor.isActive("bold") ? "active" : "" }
          >
            <FormatBold />
          </IconButton>
        </Tooltip>
        <Tooltip title={ translate("Italic") }>
          <IconButton
            onClick={ () => editor.chain().focus().toggleItalic().run() }
            className={ editor.isActive("italic") ? "active" : "" }
          >
            <FormatItalic />
          </IconButton>
        </Tooltip>
        <Tooltip title={ translate("Underline") }>
          <IconButton
            onClick={ () => editor.chain().focus().toggleUnderline().run() }
            className={ editor.isActive("underline") ? "active" : "" }
          >
            <FormatUnderlined />
          </IconButton>
        </Tooltip>
        <Tooltip title={ translate("Size") }>
          <IconButton
            onClick={ handleSizeClick }
            className={ editor.isActive("heading", { level: 1 }) ? "active" : "" }
          >
            <FormatSize />
          </IconButton>
        </Tooltip>
      </div>
      <div className="icon-section">
        <Tooltip title={ translate("Align Left") }>
          <IconButton
            onClick={ () => editor.chain().focus().setTextAlign("left").run() }
            className={ editor.isActive({ textAlign: "left" }) ? "active" : "" }
          >
            <FormatAlignLeft />
          </IconButton>
        </Tooltip>
        <Tooltip title={ translate("Align Center") }>
          <IconButton
            onClick={ () => editor.chain().focus().setTextAlign("center").run() }
            className={ editor.isActive({ textAlign: "center" }) ? "active" : "" }
          >
            <FormatAlignCenter />
          </IconButton>
        </Tooltip>
        <Tooltip title={ translate("Align Right") }>
          <IconButton
            onClick={ () => editor.chain().focus().setTextAlign("right").run() }
            className={ editor.isActive({ textAlign: "right" }) ? "active" : "" }
          >
            <FormatAlignRight />
          </IconButton>
        </Tooltip>
        <Tooltip title={ translate("Bulleted List") }>
          <IconButton
            onClick={ () => editor.chain().focus().toggleBulletList().run() }
            className={ editor.isActive("bulletList") ? "active" : "" }
          >
            <FormatListBulleted />
          </IconButton>
        </Tooltip>
        <Tooltip title={ translate("Numbered List") }>
          <IconButton
            onClick={ () => editor.chain().focus().toggleOrderedList().run() }
            className={ editor.isActive("orderedList") ? "active" : "" }
          >
            <FormatListNumbered />
          </IconButton>
        </Tooltip>
      </div>
      { /* TODO: Implement image and  */ }
      { /* <div className="icon-section">
        <IconButton>
          <SentimentSatisfied />
        </IconButton>
        <IconButton>
          <Image />
        </IconButton>
      </div> */ }
      <div className="icon-section">
        <Tooltip title={ translate("Code Block") }>
          <IconButton
            onClick={ () => editor.chain().focus().toggleCodeBlock().run() }
            className={ editor.isActive("codeBlock") ? "active" : "" }
          >
            <Code />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  )
}
