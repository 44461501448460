import React, {
  useEffect,
  useMemo,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import "./style.sass"
import Modal from "../Modal"
import Input from "../Input"
import { useDispatch, useSelector } from "../../state/hooks"
import {
  closeCommsCreateTemplate,
  createCommunicationTemplate,
  updateCommunicationTemplate,
} from "../../state/commsCreateEditTemplateModal"
import RichTextEditor from "../RichTextEditor"
import { pushToast } from "../../state/toastSlice"
import LoadingIndicator from "../LoadingIndicator"
import { getTemplates } from "../../state/slidingPanelSlice/communications"
import { setTemplatesPage, setTemplatesSearchInput } from "../../state/slidingPanelSlice"

export default function ModalCommsCreateEditTemplate() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalCommsCreateEditTemplate" })
  const { t: tCommon } = useTranslation([], { keyPrefix: "common" })

  const { open, template } = useSelector(({ commsCreateEditTemplateModal }) => commsCreateEditTemplateModal)
  const [ editorContent, setEditorContent ] = useState("")
  const [ templateName, setTemplateName ] = useState("")
  const [ description, setDescription ] = useState("")
  const [ subject, setSubject ] = useState("")
  const [ submitLoading, setSubmitLoading ] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (template && open) {
      setEditorContent(template.body)
      setTemplateName(template.name)
      setSubject(template.subject)
      if (template.description) setDescription(template.description)
    }
  }, [ open, template ])

  const handleClose = () => {
    dispatch(closeCommsCreateTemplate())
    setEditorContent("")
    setTemplateName("")
    setDescription("")
    setSubject("")
  }

  const handleSubmit = async () => {
    setSubmitLoading(true)
    const variables = {
      body: editorContent,
      name: templateName,
      subject,
      description: description.length > 0 ? description : null,
    }
    if (!template) {
      await dispatch(createCommunicationTemplate({
        variables,
        onSuccess: () => dispatch(pushToast({
          message: translate("Success!"),
          additionalInfo: translate("Template is successfully created!"),
          type: "success",
        })),
        onError: () => dispatch(pushToast({
          message: tCommon("Error"),
          additionalInfo: tCommon("There was an error. Please try again!"),
          type: "error",
        })),
      }))
    } else {
      await dispatch(updateCommunicationTemplate({
        variables: {
          ...variables,
          id: template.id,
        },
        onSuccess: () => {
          dispatch(pushToast({
            message: translate("Success!"),
            additionalInfo: translate("Template is successfully updated!"),
            type: "success",
          }))
          dispatch(setTemplatesPage(1))
          dispatch(setTemplatesSearchInput(""))
          dispatch(getTemplates("", 1))
        },
        onError: () => dispatch(pushToast({
          message: tCommon("Error"),
          additionalInfo: tCommon("There was an error. Please try again!"),
          type: "error",
        })),
      }))
    }

    setSubmitLoading(false)
    handleClose()
  }

  const canSubmit = editorContent.length && templateName.length && subject.length

  const [ modalTitle, primaryLabel ] = useMemo(() => {
    if (template) return [ translate("Edit Communications Template"), translate("Update Template") ]
    return [ translate("Create New Communications Template"), translate("Create Template") ]
  }, [ open ])

  return (
    <Modal
      className="cp_component_modal-comms-create-template"
      title={ modalTitle }
      primaryLabel={ submitLoading ? <LoadingIndicator /> : primaryLabel }
      secondaryLabel={ tCommon("Cancel") }
      primaryAction={ handleSubmit }
      secondaryAction={ handleClose }
      closeAction={ handleClose }
      open={ open }
      disabled={ !canSubmit || submitLoading }
      secondaryDisabled={ submitLoading }
      maxWidth="lg"
    >
      <div className="content-container">
        <h2>{ translate("Message Template") }</h2>
        <Input
          id="cp_component_modal-comms-create-template-template-name-input"
          value={ templateName }
          onChange={ (e) => setTemplateName(e.target.value) }
          label={ `${ translate("Template Name") }*` }
          placeholder={ translate("Enter Template Name") }
        />
        <Input
          id="cp_component_modal-comms-create-template-description-input"
          value={ description }
          onChange={ (e) => setDescription(e.target.value) }
          label={ `${ translate("Description") }` }
          placeholder={ translate("Enter Description") }
        />
        <div className="input-counter-container">
          <div className="subject-label">
            <p className="label_small-caps-bold">{ `${ translate("Subject Line") }*` }</p>
            <p className="label_small-caps-bold counter">
              { subject.length }
              /255
            </p>
          </div>
          <Input
            id="cp_component_modal-comms-create-template-subject-input"
            value={ subject }
            onChange={ (e) => { if (e.target.value.length <= 255) setSubject(e.target.value) } }
            placeholder={ translate("Enter Subject") }
          />
        </div>
        <div>
          <RichTextEditor
            editorId="cp_component_modal-comms-create-template-editor-input"
            editorContent={ editorContent }
            setEditorContent={ setEditorContent }
          />
        </div>
      </div>
    </Modal>
  )
}
