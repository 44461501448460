import * as GraphQL from "../../graphql/search"
import * as DefaultGraphQL from "../../graphql"
import { Scope } from "../../util/types"

interface AIPrompt {
  id: number
  value: string
}

export type AgeRange
  = "13-19"
  | "20-29"
  | "30-39"
  | "40-49"
  | "50+"

export type AgeRangeValue = {
  minAge: number | null,
  maxAge: number | null,
}

export type LocationWithMatch = {
  location: DefaultGraphQL.AudienceLocation,
  match: number,
}

// TYPES
export type SearchInput = {
  limit: number,
  page: number,
  username?: string,
  selectedPostIds: string[],
  selectedAccountIds: string[],
  socialNetworks: GraphQL.Network[],
  prompts: AIPrompt[],
  profileParams: GraphQL.ProfileFiltersInput,
  contentParams: GraphQL.ContentFiltersInput,
}

// CONSTANTS
export const LIMIT_DEFAULT = 50

export const INCOME_BRACKETS: GraphQL.IncomeBrackets[] = [
  GraphQL.IncomeBrackets.Under_10000,
  GraphQL.IncomeBrackets["10000_19999"],
  GraphQL.IncomeBrackets["20000_29999"],
  GraphQL.IncomeBrackets["30000_39999"],
  GraphQL.IncomeBrackets["40000_49999"],
  GraphQL.IncomeBrackets["50000_74999"],
  GraphQL.IncomeBrackets["75000_100000"],
  GraphQL.IncomeBrackets["100000Above"],
]

export const NETWORKS_AVAILABLE = [
  GraphQL.Network.Facebook,
  GraphQL.Network.Instagram,
  GraphQL.Network.Snapchat,
  GraphQL.Network.Tiktok,
  GraphQL.Network.Youtube,
]

export const NETWORK_REQUIRED_SCOPES: {[key in GraphQL.Network]: Scope[]} = {
  [GraphQL.Network.Facebook]: [ Scope.FEATURE_ENABLE_FACEBOOK ],
  [GraphQL.Network.Instagram]: [ Scope.FEATURE_ENABLE_INSTAGRAM ],
  [GraphQL.Network.Snapchat]: [ Scope.FEATURE_ENABLE_SNAPCHAT ],
  [GraphQL.Network.Tiktok]: [ Scope.FEATURE_ENABLE_TIKTOK ],
  [GraphQL.Network.Youtube]: [ Scope.FEATURE_ENABLE_YOUTUBE ],
}

// eslint-disable-next-line no-shadow
export enum SearchFieldString {
  // These are strings that the backend uses to recognize search fields.
  // For instance, these strings are used as the `sortBy` value in the
  // search input.

  // NOTE: Being able to sort by Engagement Rate is currently not supported
  // on the backend. Once that becomes a possibility, Engagement Rate may be
  // re-added to the switch case below.

  AdCouncilScore = "ad-council-score", // Ad Council and IScore are the same value
  AudienceQualityScore = "aud-quality-score",
  DemographicScore = "demographic-score",
  EngagementScore = "engagement-rate-score",
  Followers = "followers",
  IScore = "i-score",
  InDemoPercentage = "in-demo",
  Matches = "matches",
  EngagementRate = "engagement-rate",
  OrganicEngagementRate = "organic-engagement-rate",
  PostDate = "date-posted",
  SponsoredEngagementRate = "sponsored-engagement-rate",
}

// eslint-disable-next-line no-shadow
export enum SearchColumn {
  Account = "account",
  AdCouncilScore = "adCouncilScore",
  AudienceQualityScore = "audienceQualityScore",
  Summary = "summary",
  Biography = "biography",
  DemographicScore = "demographicScore",
  EllipsisMenu = "ellipsisMenu",
  EngagementRate = "engagementRate",
  EngagementScore = "engagementScore",
  Followers = "followers",
  IScore = "iScore",
  InDemoPercentage = "inDemoPercentage",
  Matches = "matches",
  Media = "media",
  OrganicEngagementRate = "organicEngagementRate",
  PostDate = "postDate",
  PostDetails = "postDetails",
  PostType = "postType",
  SponsoredEngagementRate = "sponsoredEngagementRate",
}

export function initialContentInput(): GraphQL.ContentFiltersInput {
  return {
    keywords: [],
    minPostEngagement: null,
  }
}
// TODO: type this later
export const initialProfileInput = (): GraphQL.ProfileFiltersInput => ({
  bioKeywords: [],
  maxFollowers: null,
  minEngagement: null,
  minFollowers: 10000,
})

export const initialSearchState = (): SearchInput => ({
  username: "",
  limit: LIMIT_DEFAULT,
  page: 1,
  selectedAccountIds: [],
  selectedPostIds: [],
  socialNetworks: [
    GraphQL.Network.Instagram,
    GraphQL.Network.Youtube,
  ],
  prompts: [],
  contentParams: initialContentInput(),
  profileParams: initialProfileInput(),
})

// SEARCH HELPER MISCELLANEOUS FUNCTIONS

export function cloneSearchInput(input: SearchInput): SearchInput {
  return JSON.parse(JSON.stringify(input))
}
