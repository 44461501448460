/* eslint-disable max-len */
import React from "react"
import * as GraphQL from "../../../../graphql"

import "./native-posts.sass"
import { groupBy } from "./reportingHelper"
import { CAMPAIGN_REPORTING_VISUALS, NETWORK_REPORTING_ORDER } from "../../../../util/constant"
import RenderPaidMediaTable from "./RenderPaidMediaTable"

interface Props {
  deliverables: GraphQL.DeliverableOverviewFragment[],
  toggles: GraphQL.CampaignReportToggleSetting[]
}

export default function CampaignPaidMedia({ deliverables, toggles }: Props) {
  const filteredDeliverables = deliverables.filter((deliverable) => deliverable.adInsights.length > 0)

  const groupedByNetwork = (() => {
    const currGrouping: { [key: string]: GraphQL.DeliverableOverviewFragment[]} = {}
    filteredDeliverables.forEach((deliverable) => {
      const { network } = deliverable.campaignNetworkAccount.socialAccount
      const currNetwork = (
        network === GraphQL.Network.Facebook
        || network === GraphQL.Network.Instagram
      ) ? "meta" : network.toLowerCase()
      if (!currGrouping[currNetwork]) {
        currGrouping[currNetwork] = [ deliverable ]
      } else {
        currGrouping[currNetwork] = [ ...currGrouping[currNetwork], deliverable ]
      }
    })
    return currGrouping
  })()

  const togglesByNetwork = groupBy<GraphQL.CampaignReportToggleSetting, string>(
    toggles,
    (setting) => setting.platform,
  )

  // Order the networks for proper display order
  const networksAvailable = Object.keys(groupedByNetwork) || []
  const networksAvailableOrdered = NETWORK_REPORTING_ORDER
    .map((network) => networksAvailable.find((item) => item.toLowerCase() === network.toLowerCase()))
    .filter((nw): nw is string => nw != null)

  const filteredByShowPostEnabled = networksAvailableOrdered.filter((network) => {
    const currNetwork = network.toLowerCase()
    const currentNetworkToggles = togglesByNetwork[currNetwork] || []
    if (!currentNetworkToggles.length) return false
    const showPostToggle = currentNetworkToggles.filter((setting) => (
      setting.section === CAMPAIGN_REPORTING_VISUALS.MEDIA_POST_PAID
        && setting.toggle === CAMPAIGN_REPORTING_VISUALS.SHOW_POSTS
    ))
    return showPostToggle.length ? showPostToggle[0].enabled : false
  })

  return (
    <div className="cp_report-paid-media-section paid-media">
      { filteredByShowPostEnabled.length !== 0 && (
        filteredByShowPostEnabled.map((network) => {
          const uniqueId: string = `${ network }-${ Date.now() }`
          return (
            <div key={ `${ uniqueId } ` } className="section">
              <RenderPaidMediaTable deliverables={ groupedByNetwork[network] } toggles={ toggles } network={ network } />
            </div>
          )
        })
      ) }
    </div>
  )
}
