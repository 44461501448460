import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import Button from "../../Button"
import CommunicationsTable from "./CommunicationsTable"
import { useDispatch, useSelector } from "../../../state/hooks"
import LoadingIndicator from "../../LoadingIndicator"
import * as API from "../../../util/apiClient"
import { getCommsForProfile } from "../../../state/socialProfileSlice"

import "./communications-tab-content.sass"
import { ALL_NETWORKS, Scope } from "../../../util/types"

type Props = {
    onButtonClick: () => void
}

export default function CommunicationsTabContent({ onButtonClick }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ProfileTabs" })
  const { commsForProfile, profile } = useSelector(({ socialProfile }) => socialProfile)
  const { scopes } = useSelector(({ user }) => user)
  const dispatch = useDispatch()

  useEffect(() => {
    if (API.isSuccess(profile)) {
      const accountIds = profile.payload.socialAccount.personality?.socialAccounts.map((a) => a.id)
      || [ profile.payload.socialAccount.id ]
      dispatch(getCommsForProfile(accountIds, ALL_NETWORKS))
    }
  }, [])

  if (commsForProfile === "init" || commsForProfile === "loading") {
    return (<LoadingIndicator flexWrapperEnabled={ true } size={ 30 } />)
  }
  if (API.isError(commsForProfile)) {
    return (
      <div>
        <p>There was an error! Please try again</p>
      </div>
    )
  }

  return (
    <div className="cp_profile_component-comms-content">
      <div className="cp_profile_component-comms-content-header">
        <h5>Communications</h5>
        { scopes.includes(Scope.COMMUNICATIONS_MANAGEMENT) && (
        <Button
          label={ translate("Add to group") }
          isEnabled={ true }
          isPrimary={ true }
          onClick={ onButtonClick }
        />
        ) }
      </div>
      <CommunicationsTable comms={ commsForProfile.payload.searchCommunicationGroupsByNetworkAccountIds.rows } />
    </div>
  )
}
